import React, { useState } from 'react';
import { Paper } from '@mui/material';
import QRCodeModal from './QRCodeModal';
import { useEnvironment } from '../../store/hooks';
import './styles.scss';

const itemsPerPage = 24;
const PaginationControls = ({ currentPage, pageCount, onPageChange }) => {
  const pageGroupSize = 10; // 1ページ群に表示するページ数
  const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
  const endPage = Math.min(startPage + pageGroupSize - 1, pageCount);

  const generatePages = () => {
    let pages = [];
    if (startPage > 1) pages.push('<'); // 前のページ群へ移動するための '<' ページ
    for (let i = startPage; i <= endPage; i++) pages.push(i); // 現在のページ群のページ番号を追加
    if (endPage < pageCount) pages.push('>'); // 次のページ群へ移動するための '>' ページ
    return pages;
  };

  const handlePageChange = (page) => {
    if (page === '<') {
      onPageChange(Math.max(1, startPage - pageGroupSize)); // 前のページ群の最初のページへ
    } else if (page === '>') {
      onPageChange(Math.min(pageCount, startPage + pageGroupSize)); // 次のページ群の最初のページへ
    } else {
      onPageChange(page); // 指定されたページへ
    }
  };

  return (
    <ul className='paginationStyle'>
      {generatePages().map((page, index) => (
        <li
          key={index}
          className={`pageItemStyle ${page === currentPage ? 'isCurrentPage' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </li>
      ))}
    </ul>
  );
};

const FixedQRCodeList = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayQRId, setDisplayQRId] = useState(null);
  const pageCount = Math.ceil(user.tables.length / itemsPerPage);
  const currentTables = user.tables.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const env = useEnvironment();
  const baseUrl = env === 'development' ? 'https://staging-fixed-qr.herokuapp.com/' : 'https://fixed-qr.herokuapp.com/';

  const handleOpenModal = (id) => {
    setDisplayQRId(id);
  };

  return (
    <div>
      <div className='listStyle'>
        {currentTables.map((table) => (
          <Paper elevation={3} key={table.id} className='itemStyle' onClick={() => handleOpenModal(table.id)}>
            <h2>{table.name}</h2>
          </Paper>
        ))}
      </div>
      <PaginationControls currentPage={currentPage} pageCount={pageCount} onPageChange={setCurrentPage} />
      <QRCodeModal
        open={!!displayQRId}
        onClose={() => setDisplayQRId(null)}
        qrCodeValue={
          displayQRId
            ? `${baseUrl}?id=${user.id}&access_key=${user.tables.find((table) => table.id === displayQRId).access_key}`
            : ''
        }
        tableName={displayQRId ? user.tables.find((table) => table.id === displayQRId).name : ''}
      />
    </div>
  );
};

export default FixedQRCodeList;

import { Icon, IconButton, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';

export const HelpPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} color='primary' onClick={handleClick} size='large'>
        <Icon>help</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ maxWidth: 420, padding: 16 }}>
          <Typography paragraph>
            <strong>メニュー</strong>は複数の<strong>ページ</strong>をまとめる機能です。
          </Typography>
          <Typography>
            例えば「フード」と「ドリンク」<strong>メニュー</strong>を作り
          </Typography>
          <ul>
            <li>
              「フード」には「ピザ」と「パスタ」の<strong>ページ</strong>
            </li>
            <li>
              「ドリンク」には「ワイン」と「ビール」の<strong>ページ</strong>
            </li>
          </ul>
          <Typography paragraph>のように紐付けると、お客様が商品を探しやすくなります。</Typography>
          <div style={{ textAlign: 'center' }}>
            <img src='/tutorial-menu.svg' alt='' style={{ height: 120 }} />
          </div>
        </div>
      </Popover>
    </>
  );
};

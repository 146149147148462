import Paper from '@mui/material/Paper';
import { Schedule, Person2 } from '@mui/icons-material';
import moment from 'dayjs';
import { firebaseAuth } from '../../firebase';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader';
import { useFunctionsDomain } from '../../store/hooks';
import './styles.scss';

const syncAllTables = async ({ functionsDomain }) => {
  const idToken = await firebaseAuth().currentUser.getIdToken();
  const options = { headers: { Authorization: `Bearer ${idToken}` } };
  const response = await fetch(
    `${functionsDomain}/foProxy?url=${encodeURIComponent('/api/orders?kind=Table')}`,
    options,
  );
  const { tables } = await response.json();

  return tables
    ? tables
        .filter((table) => table.status === 'seated' || table.status === 'other')
        .sort((a, b) => parseInt(a.ubiregi_table_id - b.ubiregi_table_id))
    : [];
};

export const TableGrid = (props) => {
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const functionsDomain = useFunctionsDomain();

  const { selectTable, account } = props;

  useEffect(() => {
    const fetchAllTables = async () => {
      setTables(await syncAllTables({ functionsDomain }));
      setLoading(false);
    };
    if (account) fetchAllTables();
  }, [account, functionsDomain]);

  const click = (guid, tableNo) => {
    selectTable(guid, tableNo);
  };

  if (loading) return <Loader />;

  return (
    <div className='list stylizedList grid'>
      {tables.map(({ tableNo, guid, numGuest, date, status }, key) => (
        <Paper key={key} elevation={3} className='gridItem' onClick={() => click(guid, tableNo)}>
          <div className='wrapper'>
            <span className='wrapperTableNo'>
              {`${tableNo}`}
              <small>{` (${status})`}</small>
            </span>
            <span className='wrapperGuest'>
              <Person2 sx={{ fontSize: 14, marginRight: 0.25 }} />
              {`${numGuest}`}
            </span>
            <span className='wrapperDate'>
              <Schedule sx={{ fontSize: 14, marginRight: 0.25 }} />
              {`${moment(date * 1000).format('MM/DD HH:mm')}`}
            </span>
          </div>
        </Paper>
      ))}
      <div />
    </div>
  );
};

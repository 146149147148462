import { Box, Typography, Button, FormControlLabel, Checkbox, Divider } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { usePageMutator } from '../../store/hooks/firestore';

const useSubHeader = ({ page, selectedItems, selectItemIds, setSelectItemId }) => {
  const { update } = usePageMutator();
  const allSelected = useMemo(() => selectItemIds.length === selectedItems.length, [selectItemIds, selectedItems]);
  const itemChecked = useMemo(() => !!selectItemIds.length, [selectItemIds]);
  const sortDisabled = useMemo(() => selectedItems.length <= 1, [selectedItems]);
  const onChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectItemId(selectedItems.map(({ id }) => id));
      } else {
        setSelectItemId([]);
      }
    },
    [setSelectItemId, selectedItems],
  );

  const deleteItem = useCallback(async () => {
    if (window.confirm('選択した商品を、このページから削除しますか？')) {
      await update({
        id: page.id,
        payload: {
          itemIds: selectedItems.filter((item) => !selectItemIds.includes(item.id)).map((item) => item.id),
          updateDate: new Date(),
        },
        updateSnapshot: page.offerTypes,
      });
      setSelectItemId([]);
    }
  }, [update, page, selectedItems, selectItemIds, setSelectItemId]);

  return [allSelected, itemChecked, sortDisabled, onChange, deleteItem];
};

export const SubHeader = ({ page, selectedItems, selectItemIds, setSelectItemId, sort, sortCancel, sortSave }) => {
  const [allSelected, itemChecked, sortDisabled, onChange, deleteItem] = useSubHeader({
    page,
    selectedItems,
    selectItemIds,
    setSelectItemId,
  });

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: 50, marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          {!sort && (
            <FormControlLabel
              control={
                <Checkbox
                  data-test-id='all-selected-item'
                  color='primary'
                  checked={allSelected}
                  indeterminate={!allSelected && itemChecked}
                  onChange={onChange}
                  style={{ width: 50, marginRight: 10 }}
                />
              }
              label={<Typography>すべて選択</Typography>}
            />
          )}
        </Box>
        <Box>
          {!sort && (
            <Button
              data-test-id='delete-item-id'
              disabled={!itemChecked}
              variant='contained'
              color='secondary'
              onClick={deleteItem}
            >
              このページから外す
            </Button>
          )}
          {sort && (
            <Button variant='contained' style={{ marginLeft: 10 }} color='secondary' onClick={sortSave}>
              並び替え完了
            </Button>
          )}
          <Button
            disabled={sortDisabled}
            variant='outlined'
            style={{ marginLeft: 10 }}
            color='secondary'
            onClick={sortCancel}
          >
            {sort ? '並び替えキャンセル' : '並び替え'}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

import React, { useState, useCallback } from 'react';
import { ImageForm, imageUpload } from '../ImageForm';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Icon,
  CircularProgress,
  TextField,
  Box,
  FormHelperText,
} from '@mui/material';
import { useStyles } from './useStyles';
import { usePageMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { HelpPopover } from './HelpPopover';

const IMAGE_WIDTH = 2048;
const IMAGE_HEIGHT = 166;

const usePageLayout = (id, pageOfferTypes, initialImageUrl, initialFull, initialDesc, update) => {
  const { saved, sending, loading } = useSaveNotify();
  const [image, setImage] = useState(initialImageUrl);
  const [rawImage, setRawImage] = useState(null);
  const [desc, setDesc] = useState(initialDesc);
  const [full, setFull] = useState(initialFull);

  const updatePageLayout = useCallback(async () => {
    if (!image) {
      await update({ id, payload: { image: '', desc, full, updateDate: new Date() }, updateSnapshot: pageOfferTypes });
      if (initialImageUrl) {
        sending();
      }
      saved();
      return;
    }
    if (image !== initialImageUrl) {
      sending();
      const url = await imageUpload({ image, imageWidth: IMAGE_WIDTH, imageHeight: IMAGE_HEIGHT });
      setImage(url);
      await update({ id, payload: { image: url, desc, full, updateDate: new Date() }, updateSnapshot: pageOfferTypes });
    } else {
      await update({ id, payload: { desc, full, updateDate: new Date() }, updateSnapshot: pageOfferTypes });
    }
    saved();
  }, [id, pageOfferTypes, update, image, desc, full, initialImageUrl, sending, saved]);

  return [image, setImage, rawImage, setRawImage, desc, setDesc, full, setFull, updatePageLayout, loading];
};

export const LayoutSetting = ({
  pageId,
  pageOfferTypes,
  image: initialImageUrl,
  full: initialFull,
  desc: initialDesc,
}) => {
  const { update } = usePageMutator();
  const [image, setImage, rawImage, setRawImage, desc, setDesc, full, setFull, updatePageLayout, loading] =
    usePageLayout(pageId, pageOfferTypes, initialImageUrl, initialFull, initialDesc, update);

  const { title, textfieldLabel, textfieldBox, focusedTextFieldBox, outlinedInput } = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const handleDescChange = (e) => {
    const { value } = e.target;
    if (value.split('\n').length <= 3) setDesc(value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={title} variant='h6'>
          商品の表示方式
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant='standard'>
          <Select
            variant='standard'
            style={{ display: 'block' }}
            value={full}
            defaultValue={true}
            data-testid='select-image-display-format'
            onChange={(e) => setFull(e.target.value)}
          >
            <MenuItem value={false}>
              <Typography style={{ display: 'flex' }}>
                <Icon style={{ color: '#757575', display: 'inline-block', marginRight: '8px', alignSelf: 'center' }}>
                  view_list
                </Icon>
                一覧表示
              </Typography>
            </MenuItem>
            <MenuItem value={true}>
              <Typography style={{ display: 'flex' }}>
                <Icon style={{ color: '#757575', display: 'inline-block', marginRight: '8px', alignSelf: 'center' }}>
                  view_module
                </Icon>
                画像表示
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography className={title} variant='h6' style={{ marginTop: '40px' }}>
          ヘッダ画像設定
          <HelpPopover />
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '10px', marginLeft: '16px' }}>
        <Typography>
          <Icon color='primary' style={{ fontSize: '16px' }}>
            square
          </Icon>
          スマートフォンで表示される範囲
        </Typography>
        <Typography>
          <Icon color='primary' style={{ fontSize: '16px', opacity: '0.2' }}>
            square
          </Icon>
          iPad横向きで表示される範囲
        </Typography>
        <Typography style={{ maxWidth: '788px', padding: '10px' }}>
          <img
            src='/page-header-layout.png'
            alt='ページのヘッダ画像表示目安'
            style={{ width: '100%', marginTop: '10px' }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ImageForm
          imageWidth={IMAGE_WIDTH}
          imageHeight={IMAGE_HEIGHT}
          setImage={setImage}
          image={image}
          rawImage={rawImage}
          setRawImage={setRawImage}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' style={{ marginTop: '10px', marginBottom: '20px' }}>
          ページの説明設定
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={`${textfieldBox} ${isFocused ? focusedTextFieldBox : ''}`}>
          {isFocused && <Typography className={textfieldLabel}>ヘッダ画像下メッセージ</Typography>}
          <TextField
            variant='outlined'
            multiline
            rows={3}
            value={desc}
            onChange={handleDescChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder='ヘッダ画像下メッセージ'
            fullWidth
            InputProps={{ className: outlinedInput }}
          />
        </Box>
        <FormHelperText style={{ marginBottom: 5 }}>
          1行あたり24文字程度で折り返されます。3行以上は入力できません。
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={loading}
          data-testid='save-image'
          variant='contained'
          color='secondary'
          size='large'
          onClick={updatePageLayout}
          style={{ marginTop: '40px' }}
        >
          {loading && <CircularProgress color='inherit' size={20} style={{ marginRight: 6 }} />}
          <Typography variant='body1'>保存</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

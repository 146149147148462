import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  Checkbox,
  ListItemIcon,
  Link,
  Icon,
  Tooltip,
  Popover,
} from '@mui/material';
import HandleIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { openPreview } from '@order/admin/store/modules/preview';
import { Link as RouterLink } from 'react-router-dom';
import { createPreviewUrl } from '@order/admin/store/modules/preview';
import { QRCodeIcon } from '../Icon';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    height: '75px',
    marginRight: theme.spacing(1),
  },
  listSubText: {
    color: '#757575',
    fontSize: '0.9rem',
    display: 'inline',
  },
  defaultLabel: {
    display: 'inline-block',
    fontSize: '0.7rem',
    color: '#fff',
    borderRadius: 3,
    padding: '2px 4px 0px 4px',
    marginLeft: 10,
    background: '#888',
    fontWeight: 600,
  },
}));

export const OfferTypeRow = ({ checked, onChange, offerType, isFirst, requestingCopy, disabled, handle, sort }) => {
  const sessionId = useSelector((state) => state.session.user?.uid);
  const { root, listSubText, defaultLabel } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const id = !!anchorEl ? 'menu-popover' : undefined;
  const dispatch = useDispatch();
  const updateDate = offerType.updateDate?.toDate().toLocaleString('ja-JP') || '';
  const openWindow = (offerTypeId) => {
    const url = createPreviewUrl(sessionId, offerTypeId);
    window.open(url, '', 'width=360,height=640');
  };
  const handleStyle = handle
    ? {
        marginLeft: 20,
        cursor: 'grab',
      }
    : {
        marginLeft: 20,
        color: 'rgba(0,0,0,.26)',
        cursor: 'not-allowed',
      };

  return (
    <>
      <Link color='textPrimary' underline='none' component={RouterLink} to={`/c/offertype/${offerType.id}`}>
        <ListItem button>
          {!sort && (
            <Checkbox
              color='primary'
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ width: 50, height: 50, marginRight: 10 }}
            />
          )}
          <Avatar className={root} variant='square' src={offerType.logo} alt={offerType.name}>
            {offerType.name.substr(0, 5)}
          </Avatar>
          <ListItemText>
            <Typography variant='body1' style={{ display: 'flex', alignItems: 'center' }}>
              {offerType.name}
              {isFirst && <span className={defaultLabel}>デフォルト表示</span>}
            </Typography>
            <div>
              <Typography variant='body1' className={listSubText}>
                <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
                  library_books
                </Icon>{' '}
                {offerType.menuIds?.length || 0}メニュー登録
              </Typography>
              <Typography variant='body1' className={listSubText} style={{ marginLeft: 15 }}>
                <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
                  access_time
                </Icon>{' '}
                {updateDate} 更新
              </Typography>
            </div>
          </ListItemText>
          <ListItemIcon
            style={handleStyle}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {sort && (
              <Tooltip title='ドラッグして並び替え'>
                <HandleIcon />
              </Tooltip>
            )}
          </ListItemIcon>
          {!sort && (
            <ListItemIcon
              aria-describedby={id}
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(e.currentTarget);
              }}
            >
              <Tooltip title='メニューを開く'>
                <MoreVertIcon />
              </Tooltip>
            </ListItemIcon>
          )}
        </ListItem>
        <Popover
          id={id}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={(e) => {
            e.preventDefault();
            setAnchorEl(null);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <List>
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(null);
                requestingCopy(offerType.id);
              }}
            >
              <ListItemIcon>
                <Icon style={{ fontSize: 30 }}>content_copy</Icon>
              </ListItemIcon>
              <ListItemText primary='メニューブックをコピー' />
            </ListItem>
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(null);
                dispatch(openPreview(offerType.id));
              }}
            >
              <ListItemIcon>
                <QRCodeIcon style={{ fontSize: 30 }} />
              </ListItemIcon>
              <ListItemText primary='実機確認用のQRコード表示' />
            </ListItem>
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(null);
                openWindow(offerType.id);
              }}
            >
              <ListItemIcon>
                <Icon style={{ fontSize: 30 }}>open_in_new</Icon>
              </ListItemIcon>
              <ListItemText primary='新規ウィンドウで表示確認' />
            </ListItem>
          </List>
        </Popover>
      </Link>
      <Divider />
    </>
  );
};

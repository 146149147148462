import { Button, FormGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import { usePageMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';

export const Internationalization = ({ page }) => {
  const [english, setEnglish] = useState(page.i18n?.english || '');
  const [korean, setKorean] = useState(page.i18n?.korean || '');
  const [chinese, setChinese] = useState(page.i18n?.chinese || '');
  const { update } = usePageMutator();
  const { saved } = useSaveNotify();

  const save = async () => {
    const i18n = { ...page.i18n, english, korean, chinese };
    await update({ id: page.id, payload: { i18n, updateDate: new Date() }, updateSnapshot: page.offerTypes });
    saved();
  };

  return (
    <>
      <FormGroup style={{ margin: 20, width: 500 }}>
        <TextField
          variant='standard'
          label='英語でのページ名'
          value={english}
          onChange={(e) => setEnglish(e.target.value)}
          style={{ marginBottom: 20 }}
        />
      </FormGroup>

      <FormGroup style={{ margin: 20, width: 500 }}>
        <TextField
          variant='standard'
          label='韓国語でのページ名'
          value={korean}
          onChange={(e) => setKorean(e.target.value)}
          style={{ marginBottom: 20 }}
        />
      </FormGroup>

      <FormGroup style={{ margin: 20, width: 500 }}>
        <TextField
          variant='standard'
          label='中国語でのページ名'
          value={chinese}
          onChange={(e) => setChinese(e.target.value)}
          style={{ marginBottom: 20 }}
        />
      </FormGroup>

      <div style={{ margin: 20 }}>
        <Button color='secondary' variant='contained' onClick={save}>
          保存
        </Button>
      </div>
    </>
  );
};

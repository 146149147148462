import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles({
  body: {
    backgroundColor: '#fcf9f7',
    flex: '1',
    zIndex: 1,
    paddingBottom: '60px',
    minHeight: (props: { height: number }) => `${props.height}px`,
  },
});

export function ResizeBody({ children }) {
  const [height, setHeight] = useState(window.innerHeight);
  const widthRef = useRef(window.innerWidth);
  const classes = useStyles({ height });
  useEffect(() => {
    window.addEventListener('resize', () => {
      // ipadだとスクロール時にwindowの高さが変わるので幅もチェックする。
      if (widthRef.current !== window.innerWidth) {
        setHeight(window.innerHeight);
      } else {
        widthRef.current = window.innerWidth;
      }
    });
  }, []);

  return <div className={classes.body}>{children}</div>;
}

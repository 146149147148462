import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PageDetailDialogContentRow } from './PageDetailDialogContentRow';
import { useMenuItems } from '../../store/hooks';

const useSelectItems = (page, menuItems) => {
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if (page && page.itemIds && menuItems.length) {
      // menuItemsからidに基づいて必要なアイテムを抽出し、非表示商品を除外する
      const newSelectedItems = page.itemIds.reduce((items, id) => {
        const { item, image } = menuItems.find(({ item }) => id === item.id);
        if (item && item.category_id !== null && item.position !== null) {
          items.push({ ...item, image: image });
        }
        return items;
      }, []);
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems([]);
    }
  }, [page, menuItems, setSelectedItems]);
  return selectedItems;
};

export const PageDetailDialog = ({ page, open, onClose }) => {
  const menuItems = useMenuItems();
  const selectedItems = useSelectItems(page, menuItems);

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>
        <strong>{page?.name}</strong> ページに表示中の商品
      </DialogTitle>
      <DialogContent dividers>
        {selectedItems.length ? (
          <List>
            {selectedItems.map((item, i) => (
              <PageDetailDialogContentRow key={item.id} item={item} isNotLast={i + 1 < selectedItems.length} />
            ))}
          </List>
        ) : (
          <Typography gutterBottom style={{ padding: 10 }}>
            ページ「<strong>{page?.name}</strong>」には、まだ商品が登録されていません。
            <br />
            ページの編集画面で表示する商品を選択してください。
          </Typography>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '20px' }}>
        <Button component={RouterLink} to={`/c/page/${page?.id}`} variant='outlined' color='secondary'>
          <strong>{page?.name}</strong> の編集画面へ
        </Button>
        <Button style={{ marginLeft: 8 }} variant='contained' color='secondary' autoFocus onClick={onClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

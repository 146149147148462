import { useRootRef } from './index';

type Props = {
  id: number | string;
  payload: object;
  updateSnapshot?: object[];
};

const updateOfferTypesSnapshot = async (rootRef, offerTypes) => {
  const offerTypesRef = rootRef?.collection('offerTypes');
  await Promise.all(
    offerTypes.map((offerType) =>
      offerTypesRef.doc(String(offerType.id)).update({ snapshotUpdateRequestedDate: new Date() }),
    ),
  );
};

const useFirestoreObjectMutator = (collection: string) => {
  const removeUndefined = (payload: object) => {
    const result = {};
    Object.keys(payload).forEach((k) => {
      if (payload[k] !== undefined) result[k] = payload[k];
    });
    return result;
  };
  const rootRef = useRootRef();
  const collectionRef = rootRef?.collection(collection);
  const update = async ({ id, payload, updateSnapshot }: Props) => {
    await collectionRef.doc(String(id)).update(removeUndefined(payload));
    if (updateSnapshot) await updateOfferTypesSnapshot(rootRef, updateSnapshot);
  };
  const destroy = async ({ id }) => await collectionRef.doc(String(id)).delete();
  const create = async (payload) => await collectionRef.add(payload);
  const upsert = async ({ id, payload }) => {
    const ref = collectionRef.doc(String(id));
    const doc = await ref.get();
    if (doc.exists) {
      await ref.update(payload);
    } else {
      await ref.set(payload);
    }
  };
  const updateAllOfferTypeSnapshot = async () => {
    const offerTypesRef = await rootRef.collection('offerTypes').get();
    const offerTypes = offerTypesRef.docs.map((offerType) => {
      return { id: offerType.id };
    });
    await updateOfferTypesSnapshot(rootRef, offerTypes);
  };
  return { update, destroy, create, upsert, updateAllOfferTypeSnapshot };
};

export const useMenuItemMutator = () => useFirestoreObjectMutator('menu_items');
export const useOfferTypeMutator = () => useFirestoreObjectMutator('offerTypes');
export const usePageMutator = () => useFirestoreObjectMutator('pages');
export const useMenuListMutator = () => useFirestoreObjectMutator('menuList');

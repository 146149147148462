import React from 'react';
import { ListItem, Avatar, Typography, ListItemText, Divider } from '@mui/material';
import { usePages } from '@order/admin/store/hooks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fallback: {
    width: 150,
    height: 25,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
  avatar: {
    width: 150,
    height: 'auto',
    marginRight: theme.spacing(1),
  },
  listItem: {
    minHeight: 65,
  },
}));

export const MenuDetailDialogContentRow = ({ id, isNotLast }) => {
  const pages = usePages() || [];
  const page = pages.find((page) => page.id === id);
  const { fallback, avatar, listItem } = useStyles();

  return (
    <div>
      <ListItem className={listItem}>
        <Avatar className={page.image ? avatar : fallback} variant='square' src={page.image} alt={page.name}>
          {page.name.substr(0, 5)}
        </Avatar>
        <ListItemText>
          <Typography variant='body1'>{page.name}</Typography>
        </ListItemText>
      </ListItem>
      {isNotLast && <Divider />}
    </div>
  );
};

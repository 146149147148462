import React from 'react';
import { Loader } from '../Loader';
import { AccountNotFound } from './AccountNotFound';
import { Expired } from './Expired';
import { HandyRequired } from './HandyRequired';
import { useFoChecker } from './useFoChecker';
import { useValidSubscriptions } from './useValidSubscriptions';

// FOChecker って名前だけどアカウントの有無やアカウントが利用の前提になる契約や OAuth 認証とかを済ませてるか確認してそのうえでいろいろメッセージだしてあげるところ
export const FOChecker = ({ children }) => {
  const { user, loading, foEnabled } = useFoChecker();
  const valid = useValidSubscriptions(user?.uid);
  if (loading) return <Loader drawer={false} />;
  if (!user) return <AccountNotFound />;
  if (!valid) return <Expired />;
  if (!foEnabled) return <HandyRequired />;
  return children;
};

import { Breadcrumbs, Divider, Link, Paper, Tabs, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMenuList, useMergedPages, useOfferTypes } from '../../store/hooks';
import { Loader } from '../Loader';
import { Internationalization } from './Internationalization';
import { LayoutSetting } from './LayoutSetting';
import { MenuNameForm } from './MenuNameForm';
import { SelectPage } from './SelectPage';
import { SearchByNumber } from './SearchByNumber';
import { withStyles } from '@mui/styles';
import { Tab } from './Tab';

const MenuSetting = withStyles({ fullWidth: { width: '50%' } })(({ classes, match }) => {
  const menuId = match.params.id;
  const [tabNumber, setTabNumber] = useState(0);
  const menuList = useMenuList() || [];
  const pages = useMergedPages();
  const offerTypes = useOfferTypes() || [];

  const menu = menuList.find((p) => p.id === menuId);

  if (!pages || !menu) {
    return <Loader />;
  }

  return (
    <Paper style={{ padding: '30px 20px', margin: '20px', minHeight: 300 }}>
      <Breadcrumbs style={{ marginBottom: 16 }} separator={<NavigateNext fontSize='small' />}>
        <Link component={RouterLink} to='/c/menu'>
          メニュー
        </Link>
        <Typography>
          <strong>{menu.name}</strong> の編集
        </Typography>
      </Breadcrumbs>
      <MenuNameForm {...{ menu, menuId, classes, menuOfferTypes: offerTypes }} />
      <Tabs
        value={tabNumber}
        onChange={(_e, value) => {
          setTabNumber(value);
        }}
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab value={0} label={<Typography style={{ fontWeight: 'bold' }}>ページ一覧</Typography>} />
        <Tab
          value={1}
          data-testid='menu-image'
          label={<Typography style={{ fontWeight: 'bold' }}>画像設定</Typography>}
        />
        <Tab
          value={2}
          id='menu-internalization'
          label={<Typography style={{ fontWeight: 'bold' }}>多言語対応</Typography>}
        />
        <Tab value={3} label={<Typography style={{ fontWeight: 'bold' }}>番号検索対応</Typography>} />
      </Tabs>
      <Divider style={{ marginBottom: 20 }} />
      {!tabNumber && <SelectPage {...{ menu, pages }} />}
      {tabNumber === 1 && menu && (
        <LayoutSetting {...{ menuId: menu.id, image: menu.image, menuOfferTypes: offerTypes }} />
      )}
      {tabNumber === 2 && menu && <Internationalization {...{ menu, offerTypes }} />}
      {tabNumber === 3 && menu && <SearchByNumber menu={menu} offerTypes={offerTypes} />}
    </Paper>
  );
});

export default MenuSetting;

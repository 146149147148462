import { useDispatch } from 'react-redux';
import { setMessageToSnackbar, openSnackbar } from '../modules/snackbar';
import { useState } from 'react';

export const useSaveNotify = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const sending = () => {
    setLoading(true);
    dispatch(setMessageToSnackbar('送信中です'));
    dispatch(openSnackbar());
  };

  const saved = () => {
    dispatch(setMessageToSnackbar('保存しました'));
    dispatch(openSnackbar());
    setLoading(false);
  };

  return { sending, saved, loading };
};

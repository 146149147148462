import React from 'react';
import { Paper, Typography, CircularProgress } from '@mui/material';

export const LockPage = () => {
  return (
    <Paper style={{ padding: 20, margin: 20, minHeight: 300 }}>
      <Typography variant='h4' style={{ marginTop: 40, textAlign: 'center' }}>
        メニューブックをコピーしています。
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 40 }}>
        <CircularProgress />
      </div>
      <Typography style={{ textAlign: 'center' }}>他の操作はできません。しばらくお待ちください。</Typography>
    </Paper>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  List as MuiList,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  Box,
  Paper,
  Icon,
  Button,
} from '@mui/material';
import { Row } from './Row';
import { useMergedPages } from '@order/admin/store/hooks';
import { usePageMutator } from '@order/admin/store/hooks/firestore';
import { PageNameForm } from './PageNameForm';
import { openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { HelpPopover } from './HelpPopover';
import { CustomButton } from '../Button';
import { withStyles } from '@mui/styles';
import { SaveAlt } from '@mui/icons-material';
import { LoadCategoryDialog } from './LoadCategoryDialog';
import { AlertInUnavailability } from '../AlertInUnavailability';

const SubHeader = ({ onChange, deletePage, deleting, allSelected, itemChecked }) => {
  return (
    <>
      <Typography variant='h6'>作成済みのページ</Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={allSelected}
                indeterminate={!allSelected && itemChecked}
                onChange={onChange}
                disabled={deleting}
                style={{ width: 50, height: 50, marginRight: 10 }}
                data-test-id='all-check'
              />
            }
            label={<Typography>すべて選択</Typography>}
          />
        </Box>
        <Box>
          <CustomButton disabled={deleting || !itemChecked} variant='outlined' color='error' onClick={deletePage}>
            ページを削除
          </CustomButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const PageList = withStyles({ fullWidth: { width: '50%' } })(({ classes }) => {
  const pages = useMergedPages().sort((a, b) => (a.updateDate?.toDate() < b.updateDate?.toDate() ? 1 : -1));
  const dispatch = useDispatch();
  const [openLoadCategoryDialog, setOpenLoadCategoryDialog] = useState(false);
  const close = useCallback(() => {
    setOpenLoadCategoryDialog(false);
  }, []);

  useEffect(() => {
    document.title = 'ページ - ユビレジQRオーダー&決済管理画面';
  }, []);

  const savedAction = (name) => {
    dispatch(setMessageToSnackbar(`「${name}」ページを作成しました。この画面からページの情報を編集してください。`));
    dispatch(openSnackbar());
  };
  const deletedAction = () => {
    dispatch(setMessageToSnackbar('削除しました。'));
    dispatch(openSnackbar());
  };
  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '30px 20px', margin: '20px', minHeight: 300 }}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h4' gutterBottom>
            <Icon style={{ marginRight: 5 }}>description</Icon>ページ
            <HelpPopover />
          </Typography>
          <Button
            style={{ height: 36.5, marginRight: 20 }}
            size='small'
            onClick={() => setOpenLoadCategoryDialog(true)}
            variant='contained'
            color='secondary'
          >
            <SaveAlt fontSize='small' />
            ユビレジのカテゴリを読みこむ
          </Button>
        </Box>
        <Typography paragraph>ページを作成して、表示する商品を選択してください。</Typography>
        <PageNameForm classes={classes} savedAction={savedAction} />
        <List pages={pages} deletedAction={deletedAction} />
      </Paper>
      <LoadCategoryDialog close={close} open={openLoadCategoryDialog} />
    </>
  );
});

const List = ({ pages, deletedAction }) => {
  const [selectPageIds, setSelectPageId] = useState([]);
  const { destroy, updateAllOfferTypeSnapshot } = usePageMutator();
  const [deleting, setDeleting] = useState(false);
  const deletePage = async () => {
    if (window.confirm('選択したページを削除しますか')) {
      setDeleting(true);
      await Promise.all(
        selectPageIds.map(async (id) => {
          await destroy({ id });
        }),
      );
      await updateAllOfferTypeSnapshot();
      deletedAction();
      setDeleting(false);
      setSelectPageId([]);
    }
  };

  if (!pages.length)
    return (
      <Paper elevation={0} style={{ textAlign: 'center', padding: 40 }}>
        <Typography variant='h5' gutterBottom style={{ fontWeight: 600, color: '#757575' }}>
          ページの作成をしましょう
        </Typography>
        <Typography variant='subtitle2'>
          ページを作り、その中に商品を登録します。
          <br />
          ページに短くわかりやすい名前をつけると、お客様が商品を探しやすくなります。
          <br />
        </Typography>
        <img src='/tutorial-page.svg' alt='' style={{ marginTop: 40, width: 150 }} />
      </Paper>
    );

  return (
    <MuiList
      subheader={
        <SubHeader
          deleting={deleting}
          deletePage={deletePage}
          allSelected={selectPageIds.length === pages.length}
          itemChecked={!!selectPageIds.length}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectPageId(pages.map(({ id }) => id));
            } else {
              setSelectPageId([]);
            }
          }}
        />
      }
    >
      {pages.map((row, i) => (
        <React.Fragment key={i}>
          <Row
            page={row}
            key={row.id}
            checked={selectPageIds.includes(row.id)}
            disabled={deleting}
            onChange={(value) => {
              if (value) {
                setSelectPageId(selectPageIds.concat(row.id));
              } else {
                setSelectPageId(selectPageIds.filter((id) => id !== row.id));
              }
            }}
          />
          {i + 1 < pages.length && <Divider />}
        </React.Fragment>
      ))}
    </MuiList>
  );
};

export default PageList;

import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import { useOfferTypeMutator } from '@order/admin/store/hooks/firestore';
import { useSaveNotify } from '@order/admin/store/hooks/useSaveNotify';

export const Soldout = ({ offerType }) => {
  const { saved } = useSaveNotify();
  const { update } = useOfferTypeMutator();
  const [soldOutLabel, setSoldOutLabel] = useState(offerType.sold_out_label || '');

  const save = async () => {
    await update({ id: offerType.id, payload: { sold_out_label: soldOutLabel, updateDate: new Date() } });
    saved();
  };

  const handleChange = (e) => setSoldOutLabel(e.target.value ? e.target.value.slice(0, 12) : '');

  return (
    <>
      <Typography variant='h5' style={{ marginTop: 40 }}>
        品切れ時の表示(最大12文字以内)
      </Typography>
      <div className='account-text-field__container'>
        <TextField
          variant='standard'
          className='account-text-field'
          name='sold_out_label'
          value={soldOutLabel}
          onChange={handleChange}
          placeholder='指定が無い場合は、Soldoutと表示されます'
          style={{ width: '500px' }}
        />
      </div>

      <div className='account-text-field__container' style={{ marginTop: 30 }}>
        <Button size='large' type='submit' variant='contained' color='secondary' onClick={save}>
          保存
        </Button>
      </div>
    </>
  );
};

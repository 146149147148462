import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControlLabel,
  DialogActions,
  Button,
  Checkbox,
  Box,
  CircularProgress,
  Link,
} from '@mui/material';
import { CategoryAndItemContent, useCategoryMappedItem } from '../PageSetting/AddItemDialog';
import { styled, useTheme } from '@mui/styles';
import { groupBy } from 'lodash';
import { useCategories, useRootRef, useUbiregiHostName } from '../../store/hooks';
import { firebaseFirestore } from '../../firebase';
import { useSync } from '../SyncButton';
import { ArrowRightAlt } from '@mui/icons-material';

const DialogContentLayout = styled(DialogContent)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const AllCheck = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const Loading = ({ onClose, message }: { onClose?: () => void; message: string }) => {
  const theme = useTheme();
  return (
    <DialogContentLayout
      style={{ height: 900, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <Box display='flex' flexDirection='row' justifyContent='center'>
        <CircularProgress size={30} />
        <Typography ml={theme.spacing(1)}>{message}</Typography>
      </Box>
      {onClose && (
        <Box display='flex' flexDirection='column' mt={theme.spacing(4)}>
          <Button onClick={onClose} color='secondary' variant='outlined'>
            キャンセル
          </Button>
        </Box>
      )}
    </DialogContentLayout>
  );
};

const EmptyItemsMessage = ({ onClose }) => {
  const theme = useTheme();
  const host = useUbiregiHostName();
  return (
    <DialogContentLayout
      style={{ height: 900, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Typography>カテゴリ情報が見つかりませんでした。</Typography>
        <Typography>ユビレジの商品設定にてカテゴリと商品を設定してください。</Typography>
        <Link
          mt={theme.spacing(4)}
          style={{ cursor: 'pointer' }}
          rel='noreferrer noopener'
          target='_blank'
          href={`https://${host}/a/setting/menus/`}
        >
          <Box display='flex' flexDirection='row' justifyContent='start' alignContent='center'>
            <ArrowRightAlt /> ユビレジの商品設定へ移動する
          </Box>
        </Link>
      </Box>

      <Box display='flex' flexDirection='column' mt={theme.spacing(5)}>
        <Button onClick={onClose} color='secondary' variant='outlined'>
          閉じる
        </Button>
      </Box>
    </DialogContentLayout>
  );
};

export const LoadCategoryDialog = ({ open, close }) => {
  const [selectedItems, setItems] = useState([]);
  const pagesRef = useRootRef().collection('pages');
  const categories = useCategories();
  const allItems = useCategoryMappedItem().flatMap((c) => c.items);
  const isExistMenuItems = allItems.length > 0;
  const [sync, doing] = useSync() as [() => Promise<void>, boolean];
  const [isSynced, setIsSynced] = useState(false);
  const [loading, setLoading] = useState(false);
  const ok = useCallback(async () => {
    setLoading(true);
    const batch = firebaseFirestore().batch();
    await Promise.all(
      Object.entries(groupBy(selectedItems, 'category_id')).map(([categoryId, items]) => {
        const pageRef = pagesRef.doc();
        const category = categories.find((c) => c.category.id === Number(categoryId));
        return batch.set(pageRef, {
          name: category.category.name,
          updateDate: new Date(),
          itemIds: items.map((i) => i.id),
        });
      }),
    );
    await batch.commit();
    setLoading(false);
  }, [selectedItems, categories, pagesRef]);

  useEffect(() => {
    if (!open) {
      setItems([]);
      setIsSynced(false);
    }
  }, [open]);

  useEffect(() => {
    if (!isExistMenuItems && open && !isSynced) {
      sync().then(() => setIsSynced(true));
    }
  }, [isExistMenuItems, open, isSynced, sync]);

  if (loading) {
    return (
      <Dialog onClose={close} fullWidth maxWidth='md' PaperProps={{ style: { padding: '10px 0 20px' } }} open={open}>
        <Loading message='選択した内容を読み込んでいます。しばらくお待ちください。' />
      </Dialog>
    );
  }

  return (
    <Dialog onClose={close} fullWidth maxWidth='md' PaperProps={{ style: { padding: '10px 0 20px' } }} open={open}>
      {doing && <Loading message='カテゴリ情報を取得しています。しばらくお待ちください。' onClose={close} />}
      {!doing && !allItems.length && <EmptyItemsMessage onClose={close} />}
      {allItems.length > 0 && (
        <>
          <DialogTitle>ユビレジカテゴリと商品を読み込む</DialogTitle>
          <Typography ml={3} variant='subtitle1'>
            ページとして設定するカテゴリと表示する商品を選択してください。
          </Typography>
          <DialogContentLayout dividers>
            <AllCheck
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setItems(allItems);
                      return;
                    }
                    setItems([]);
                  }}
                />
              }
              checked={selectedItems.length === allItems.length}
              label='すべて選択'
            />
            <Button variant='outlined' color='secondary' onClick={() => setItems([])}>
              すべて外す
            </Button>
            <CategoryAndItemContent selectItem={setItems} selectedItems={selectedItems} nameOnly />
          </DialogContentLayout>
          <DialogActions style={{ padding: '20px 20px 0 0' }}>
            <Button variant='outlined' color='secondary' onClick={close}>
              キャンセル
            </Button>
            <Button
              disabled={!selectedItems.length}
              variant='contained'
              color='secondary'
              autoFocus
              onClick={async () => {
                await ok();
                close();
              }}
            >
              保存
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Typography } from '@mui/material';
import { AddPhotoAlternate } from '@mui/icons-material';

export const Dropzone = ({ setRawImage, image, imageWidth = 200, imageHeight = 200 }) => {
  const paddingSize = 10;
  const paperWidth = imageHeight >= 300 ? 300 : imageWidth >= 768 ? 768 : imageWidth;
  const cropHeight = (paperWidth / imageWidth) * imageHeight;
  const paperHeight = cropHeight >= 400 ? 400 : cropHeight;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const reader = new FileReader(file);
      reader.readAsDataURL(file);
      reader.onload = async ({ target }) => {
        setRawImage(target.result);
      };
    },
    [setRawImage],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const styleBackground = image
    ? {
        backgroundImage: `url(${image})`,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
      }
    : {};

  const styleDisplay =
    paperHeight < 100
      ? {
          color: '#666',
          display: 'flex',
          alignItems: 'center',
        }
      : {
          color: '#666',
          textAlign: 'center',
        };

  return (
    <Paper
      elevation={3}
      {...getRootProps()}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0',
        padding: paddingSize,
        width: paperWidth + paddingSize * 2,
        height: paperHeight + paddingSize * 2,
      }}
    >
      <div data-testid='image' style={styleBackground}>
        <input data-testid={'dropzone'} {...getInputProps()} accept='image/png,image/jpeg' />
        {!image && (
          <div style={styleDisplay}>
            <AddPhotoAlternate style={{ fontSize: 60, color: '#ccc' }} />
            <div style={{ margin: '8px 10px' }}>
              <Typography data-testid='empty-image' id='empty-image' style={{ fontWeight: 'bold' }}>
                画像をアップロード
              </Typography>
              <Typography variant='caption'>対応ファイル形式：JPG, PNG</Typography>
              <br />
              <Typography variant='caption'>
                推奨サイズ：幅{imageWidth}px × 高さ{imageHeight}px
              </Typography>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
};

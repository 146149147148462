import React from 'react';
import { ListItem, ListItemText, Typography, Avatar, Checkbox, Icon, Chip } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useStyles as useStylesForLabel } from '../OfferTypeList/OfferTypeRow';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 75,
    height: 75,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
  listSubText: {
    color: '#757575',
    fontSize: '0.9rem',
    display: 'inline',
  },
}));

export const Row = ({ menu, onChange, checked, pageTotal, disabled }) => {
  const { root, listSubText } = useStyles();
  const updateDate = menu.updateDate?.toDate().toLocaleString('ja-JP') || '';
  const history = useHistory();
  const { defaultLabel } = useStylesForLabel();
  return (
    <ListItem button component={RouterLink} to={`/c/menu/${menu.id}`}>
      <Checkbox
        color='primary'
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ width: 50, height: 50, marginRight: 10 }}
      />
      <Avatar className={root} variant='square' src={menu.image} alt={menu.name}>
        {menu.name.substr(0, 4)}
      </Avatar>
      <ListItemText>
        <Typography variant='body1'>{menu.name}</Typography>
        {menu.offerTypes?.map((o) => (
          <Chip
            key={o.name}
            size='small'
            style={{ marginRight: 5 }}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/c/offerType/${o.id}`);
            }}
            label={
              <>
                <Icon
                  style={{
                    fontSize: '1.2em',
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    marginTop: -3,
                    marginRight: 3,
                    color: '#656565',
                  }}
                >
                  menu_book
                </Icon>
                {o.name}
              </>
            }
          />
        ))}
        <div>
          <Typography variant='body1' className={listSubText}>
            <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
              description
            </Icon>{' '}
            {pageTotal}ページ登録
          </Typography>
          <Typography variant='body1' className={listSubText} style={{ marginLeft: 15 }}>
            <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
              access_time
            </Icon>
            {updateDate} 更新
            {menu.enabledSearchByNumber && <span className={defaultLabel}>番号検索有効</span>}
          </Typography>
        </div>
      </ListItemText>
    </ListItem>
  );
};

import React from 'react';
import { useStyles } from './useStyles';
import {
  ListItem,
  Avatar,
  Typography,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Button,
  ListItemIcon,
  Checkbox,
  Tooltip,
} from '@mui/material';
import HandleIcon from '@mui/icons-material/Menu';
import { FormattedPrice } from '../Price';
import { setEditorsItem } from '../../store/modules/editorDialog';
import { useDispatch } from 'react-redux';

export const SortableRow = ({ checked, onChange, item, isNotLast, handle, sort }) => {
  const dispatch = useDispatch();
  const { root } = useStyles();

  return (
    <>
      <ListItem>
        {!sort && (
          <Checkbox
            color='primary'
            checked={checked}
            onChange={onChange}
            style={{ width: 50, height: 50, marginRight: 10 }}
          />
        )}
        <Avatar className={root} variant='square' src={item.image} alt={item.name || item.item.name}>
          {(item.name || item.item.name).substr(0, 5)}
        </Avatar>
        <ListItemText
          primary={<Typography style={{ fontSize: '1rem' }}>{item.name || item.item.name}</Typography>}
          secondary={
            <>
              <Typography variant='subtitle2' style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '.75rem' }}>
                {item.item.name}
              </Typography>
              <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                SKU: {item.item.sku}
              </Typography>
              <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                カテゴリ: {item.item.category_name}
              </Typography>
              <Typography variant='caption' style={{ fontSize: '.875rem' }}>
                <FormattedPrice price={item.item.price} />
              </Typography>
            </>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
        {handle && (
          <ListItemIcon
            style={{ cursor: 'grab' }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {sort && (
              <Tooltip title='ドラッグして並び替え'>
                <HandleIcon />
              </Tooltip>
            )}
          </ListItemIcon>
        )}
        <ListItemSecondaryAction>
          <Button
            onClick={() => {
              dispatch(setEditorsItem(item));
            }}
          >
            編集
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      {isNotLast && <Divider />}
    </>
  );
};

import { Divider } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { useFOSetting } from '../../store/hooks/index';
import { useUser } from '@order/admin/store/hooks';
import { Loader } from '../../components/Loader';
import { QRCodePrintContentSetting } from './QRCodePrintContentSetting';
import { FixedQRCodeSetting } from './FixedQRCodeSetting';
import { useFunctionsDomain } from '../../store/hooks';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { submitToFo } from '@order/admin/store/modules/foSetting';
import { useAccount } from '../../store/hooks';
import { AlertInUnavailability } from '../../components/AlertInUnavailability';

export const QRCodeScreen = () => {
  const functionsDomain = useFunctionsDomain();
  const { saved, sending } = useSaveNotify();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const foSettings = useFOSetting();
  const user = useUser();
  const account = useAccount();

  useEffect(() => {
    document.title = 'QRコード設定 - ユビレジQRオーダー&決済管理画面';
  }, []);

  if (!user) return <Loader />;
  if (!account) return <Loader />;
  if (!foSettings) return <Loader />;

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '30px 20px', margin: '20px' }}>
        <Typography variant='h4' gutterBottom>
          <Icon style={{ marginRight: 5 }}>receipt</Icon>QRコード設定
        </Typography>

        <Typography style={{ marginBottom: 20 }}>
          ユビレジ ハンディから印刷されるQRコードに関する内容を設定します。
        </Typography>

        <Tabs
          value={activeTabIndex}
          indicatorColor='primary'
          textColor='primary'
          onChange={(_e, value) => setActiveTabIndex(value)}
        >
          <Tab label={<Typography fontWeight='bold'>QRコード</Typography>} />
          <Tab label={<Typography fontWeight='bold'>テーブル固定QRコード</Typography>} />
        </Tabs>
        <Divider style={{ marginBottom: 20 }} />

        {activeTabIndex === 0 && (
          <QRCodePrintContentSetting
            user={user}
            foSettings={foSettings}
            functionsDomain={functionsDomain}
            saved={saved}
            sending={sending}
            submitToFo={submitToFo}
          />
        )}
        {activeTabIndex === 1 && (
          <FixedQRCodeSetting
            user={user}
            foSettings={foSettings}
            functionsDomain={functionsDomain}
            saved={saved}
            sending={sending}
            submitToFo={submitToFo}
          />
        )}
      </Paper>
    </>
  );
};

import React from 'react';
import { useAccountInfo } from '@order/admin/store/hooks';

const formatPrice = (price, currency) => {
  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currency }).format(price);
    case 'Unknown 2':
      return Number(price).toFixed(2);
    case 'Unknown 3':
      return Number(price).toFixed(3);
    case 'JPY':
    default:
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
      }).format(price);
  }
};

export const FormattedPrice = ({ price }) => {
  const account = useAccountInfo();
  const currency = account?.currency;

  return <>{formatPrice(price, currency)}</>;
};

import React, { useCallback, useState } from 'react';
import { Box, Typography, FormControlLabel, Button, Checkbox, TextField } from '@mui/material';
import { useAccountInfo, useRootRef } from '../../store/hooks';
import { openSnackbar, setMessageToSnackbar } from '../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';

export const RedirectSetting = () => {
  const accountInfo = useAccountInfo();
  const [isRedirectNeeded, setHasRedirect] = useState(!!accountInfo?.isRedirectNeeded);
  const [url, setUrl] = useState(accountInfo?.redirectURLAfterCheckout || '');
  const [error, setError] = useState(null);
  const rootRef = useRootRef();
  const dispatch = useDispatch();

  const save = useCallback(async () => {
    await rootRef.update({ isRedirectNeeded, redirectURLAfterCheckout: url });
    dispatch(setMessageToSnackbar('保存しました'));
    dispatch(openSnackbar());
  }, [dispatch, isRedirectNeeded, rootRef, url]);

  const toggleRedirect: SwitchBaseProps['onChange'] = (_e, checked) => {
    setHasRedirect(checked);
    if (error) {
      setUrl('');
    }
    if (!checked) {
      setError(null);
    }
  };

  const onChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const url = new URL(value);
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        setUrl(value);
        setError(null);
        return;
      }
      throw new Error('invalid Url');
    } catch (error) {
      setUrl(value);
      setError(
        '入力された文字列がurlの形式(http://...またはhttps://...)ではありません。または使用できない文字が含まれています。',
      );
    }
  }, []);

  return (
    <Box justifyContent='space-between' ml={1} mt={2}>
      <Typography variant='subtitle1'>
        設定を有効にすると、お客様が操作する注文画面を指定したページへ自動転送します。アンケートフォームや自社サイトに誘導することが可能です。
      </Typography>
      <Box border={1} borderColor='grey.300' padding={2} mt={3}>
        <Typography>以下の操作でページ転送されます。</Typography>
        <ul>
          <li>お会計呼び出し有効時、「店員呼び出し」操作後</li>
          <li>
            決済機能有効時、「クレジットカードでお支払い」「その他の方法でお支払い」操作後（※決済操作をした端末はページ転送されません）
          </li>
          <li>ユビレジ ハンディアプリで「ユビレジへ送る」操作後</li>
        </ul>
      </Box>

      <Box mt={3}>
        <FormControlLabel
          control={<Checkbox color='primary' checked={isRedirectNeeded} onChange={toggleRedirect} />}
          label={<Typography>ページ転送を有効にする</Typography>}
        />
        <Box ml={4} height={30}>
          <TextField
            error={!!error}
            disabled={!isRedirectNeeded}
            value={url}
            onChange={onChange}
            fullWidth
            placeholder='転送先のURLを入力'
            variant='standard'
            helperText={error}
          />
        </Box>
      </Box>
      <Box pt={5}>
        <Button
          disabled={!!error || (isRedirectNeeded && !url)}
          onClick={save}
          size='large'
          variant='contained'
          color='secondary'
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

import { useUser } from '@order/admin/store/hooks';
import { loadAccountInfo, subscribeAccountInfo } from '@order/admin/store/modules/accountInfo';
import { loadMenuList, subscribeMenuList } from '../../store/modules/menuList';
import {
  loadCategories,
  loadMenuItems,
  loadPages,
  loadTopCategories,
  subscribeCategories,
  subscribeMenuItems,
  subscribePages,
  subscribeTopCategories,
} from '@order/admin/store/modules/menu';
import { loadOfferTypes, subscribeOfferTypes } from '@order/admin/store/modules/offerType';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadSubscriptions, subscribeAccountSubscription } from '../../store/modules/subscription';

export const DataProvider = (props) => {
  const dispatch = useDispatch();
  const { children } = props;
  const user = useUser();

  useEffect(() => {
    if (user) {
      subscribeMenuItems(user.uid, (items) => dispatch(loadMenuItems(items)));
      subscribeCategories(user.uid, (cats) => dispatch(loadCategories(cats)));
      subscribeTopCategories(user.uid, (p) => dispatch(loadTopCategories(p)));
      subscribeAccountInfo(user.uid, (a) => dispatch(loadAccountInfo(a)));
      subscribeOfferTypes(user.uid, (offerTypes) => dispatch(loadOfferTypes(offerTypes)));
      subscribePages(user.uid, (pages) => dispatch(loadPages(pages)));
      subscribeMenuList(user.uid, (l) => dispatch(loadMenuList(l)));
      subscribeAccountSubscription(user.uid, (data) => dispatch(loadSubscriptions(data)));
    }
  }, [user, dispatch]);

  return children;
};

import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Typography, FormControlLabel, Checkbox, Grid, AppBar, Fab, Toolbar, Button } from '@mui/material';
import { Add, Send } from '@mui/icons-material';
import { useOfferTypeMutator } from '@order/admin/store/hooks/firestore';
import { useSaveNotify } from '@order/admin/store/hooks/useSaveNotify';
import { colors, darker, getFontColor } from './colors';

export const CustomColor = ({ offerType }) => {
  const { saved } = useSaveNotify();
  const { update } = useOfferTypeMutator();
  const [customTheme, setCustomTheme] = useState(!!offerType.customTheme);
  const [darkMode, setDarkMode] = useState(!!offerType.darkMode);
  const [customColors, setCustomColors] = useState({ ...colors, ...(offerType.customColors || {}) });

  const save = async () => {
    const payload = { customTheme, customColors, darkMode, updateDate: new Date() };
    if (!payload.customTheme) {
      payload.customColors = null;
      payload.darkMode = null;
    }
    await update({ id: offerType.id, payload });
    saved();
  };

  return (
    <>
      <Typography variant='h5' style={{ marginTop: 40 }}>
        カスタムカラー
      </Typography>
      <div className='account-text-field__container' style={{ marginTop: 10, marginBottom: 20 }}>
        注文画面全体で表示される背景色やボタンの色を自由に設定できます。
      </div>
      <div className='account-text-field__container'>
        <FormControlLabel
          control={
            <Checkbox
              checked={customTheme}
              onChange={() => setCustomTheme(!customTheme)}
              name='customTheme'
              value={customTheme}
              color='primary'
            />
          }
          label='カスタムカラーを使用する'
        />
      </div>

      {customTheme && (
        <>
          <div className='account-text-field__container'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={darkMode}
                  onChange={() => setDarkMode(!darkMode)}
                  name='darkMode'
                  color='primary'
                  value={customTheme}
                />
              }
              label='ダークモード'
            />
          </div>
          <Grid container>
            <Grid item>
              <div className='account-text-field__container'>
                <Typography style={{ marginTop: 10, marginBottom: 10 }}>メインカラー</Typography>
                <ChromePicker
                  color={customColors.primary}
                  onChangeComplete={(val) =>
                    setCustomColors({ ...customColors, primary: val.hex, primaryDark: darker(val.hex) })
                  }
                />
              </div>
            </Grid>
            <Grid
              item
              style={{
                marginLeft: 30,
                marginTop: 42,
                padding: 20,
                border: '1px solid #CCC',
                backgroundColor: darkMode ? '#222' : '#F6F6F6',
              }}
            >
              <Typography variant='caption' style={{ color: darkMode ? 'white' : 'black' }}>
                メインカラーのサンプル
              </Typography>
              <AppBar
                position='static'
                style={{
                  marginTop: 10,
                  width: 400,
                  backgroundColor: customColors.primary,
                  color: getFontColor(customColors.primary),
                }}
              >
                <Toolbar>
                  <Typography style={{ flexGrow: 1 }}>注文履歴</Typography>
                  <Typography>合計 8 点</Typography>
                </Toolbar>
              </AppBar>
              <div>
                <Fab
                  style={{
                    padding: 0,
                    height: 36,
                    width: 36,
                    margin: 20,
                    boxShadow: 'none',
                    backgroundColor: customColors.primaryDark,
                    color: getFontColor(customColors.primaryDark),
                  }}
                >
                  <Add />
                </Fab>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div className='account-text-field__container'>
                <Typography style={{ marginTop: 20, marginBottom: 10 }}>サブカラー（注文送信ボタンなど）</Typography>
                <ChromePicker
                  color={customColors.secondary}
                  onChangeComplete={(val) => setCustomColors({ ...customColors, secondary: val.hex })}
                />
              </div>
            </Grid>
            <Grid
              item
              style={{
                marginTop: 56,
                padding: 20,
                border: '1px solid #CCC',
                backgroundColor: darkMode ? '#222' : '#F6F6F6',
              }}
            >
              <Typography variant='caption' style={{ color: darkMode ? 'white' : 'black' }}>
                サブカラーのサンプル
              </Typography>
              <div>
                <Fab
                  variant='extended'
                  style={{
                    marginTop: 20,
                    color: getFontColor(customColors.secondary),
                    backgroundColor: customColors.secondary,
                  }}
                >
                  <Send style={{ paddingRight: 5 }} />
                  送信する
                </Fab>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <div className='account-text-field__container' style={{ marginTop: 30 }}>
        <Button size='large' type='submit' variant='contained' color='secondary' onClick={save}>
          保存
        </Button>
      </div>
    </>
  );
};

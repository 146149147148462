import { loadAccount, loadUser } from '@order/admin/store/modules/session';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFunctionsDomain } from '../../store/hooks';
import { firebaseAuth, firebaseFirestore } from '../../firebase';
import { getEnvironment } from '../../environment';

const getToken = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('customToken');
};

// 認証関連の状態に応じてリダイレクトする
// - firebase でログインしてない + 認証用 Token が無い場合:
//   - ログインしてないのに認証用 URL にいると見なして / にリダイレクト
// - firebase にログインしていて、認証用 Token がある場合:
//   - もう認証用 Token をアドレスバーで見せなくていいので、 / にリダイレクト
//
// リダイレクトと言いましたが、ソフトリダイレクトでやってます
const Redirector = (props) => {
  useEffect(() => {
    firebaseAuth().onAuthStateChanged((u) => {
      if (!u && !getToken() && window.location.pathname !== '/') window.history.pushState(null, null, '/');
      if (u && getToken()) window.history.pushState(null, null, '/');
    });
  }, []);

  return props.children;
};

const saveAuthUserInfo = async (data) => {
  const db = firebaseFirestore();
  const currentUser = firebaseAuth().currentUser;
  const refAccount = db.collection('accounts').doc(currentUser.uid);
  refAccount.set(data, { merge: true });
  const env = getEnvironment();
  const domain = data.email.split('@')[1];
  if (domain === 'ubiregi.com' || domain === 'home-search.co.jp' || env === 'test') {
    refAccount
      .collection('subscriptions')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          refAccount.collection('subscriptions').add({ start_at: new Date() });
        }
      });
  }
};

const syncAuthUserInfo = async (functionsDomain) => {
  const idToken = await firebaseAuth().currentUser.getIdToken();
  const options = { headers: { Authorization: idToken } };
  await fetch(`${functionsDomain}/refresh`, options);
  const response = await fetch(`${functionsDomain}/apiUbiregiAccountInfo`, options);
  const { account, credential } = await response.json();
  account &&
    (await saveAuthUserInfo({
      shop_name: account.name,
      currency: account.currency,
      email: account.email || '',
      login: account.login,
    }));
  return { account, credential };
};

const Authenticate = (props) => {
  const functionsDomain = useFunctionsDomain();
  const dispatch = useDispatch();

  // URL に token があったらそれをつかってログイン
  useEffect(() => {
    const token = getToken();
    if (token) {
      firebaseAuth().signInWithCustomToken(token);
    }
  }, []);

  // firebase の認証状況を監視して、 store にユーザーつっこんだりする
  const fetchUser = React.useCallback(async () => {
    firebaseAuth().onAuthStateChanged(async (u) => {
      if (u) {
        const uid = u.uid;
        const idToken = await u.getIdToken();
        dispatch(loadUser({ uid, idToken }));
        const result = await syncAuthUserInfo(functionsDomain);
        const account = result.account;
        account.credential = result.credential;
        dispatch(loadAccount(account));
      } else {
        dispatch(loadUser(null));
      }
    });
  }, [dispatch, functionsDomain]);
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const { children } = props;
  return <Redirector>{children}</Redirector>;
};

export default Authenticate;

import React, { useState } from 'react';
import HandleIcon from '@mui/icons-material/Menu';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Checkbox,
  Avatar,
  Divider,
  Tooltip,
  Icon,
  Chip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PageDetailDialog } from './PageDetailDialog';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 150,
    height: 'auto',
    marginRight: theme.spacing(1),
  },
  fallback: {
    width: 150,
    height: 25,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
}));

export const SortablePageRow = ({ checked, onChange, page: p, isNotLast, handle, sort }) => {
  const [open, toggle] = useState(false);
  const { avatar, fallback } = useStyles();
  const history = useHistory();

  return (
    <>
      {open && <PageDetailDialog page={p} open={open} onClose={() => toggle(false)} />}
      <div>
        <ListItem button onClick={() => toggle(true)}>
          {!sort && (
            <Checkbox
              color='primary'
              checked={checked}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()}
              style={{ width: 50, height: 50, marginRight: 10 }}
            />
          )}
          <ListItemAvatar>
            <Avatar variant='square' className={p.image ? avatar : fallback} src={p.image} alt={p.name}>
              {p.name.substr(0, 5)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={p.name}
            secondaryTypographyProps={{ component: 'div' }}
            secondary={p.offerTypes?.map((o) => (
              <Chip
                key={o.name}
                style={{ marginRight: 5 }}
                size='small'
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/c/offerType/${o.id}`);
                }}
                label={
                  <>
                    <Icon
                      style={{
                        fontSize: '1.2em',
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        marginTop: -3,
                        marginRight: 3,
                        color: '#656565',
                      }}
                    >
                      menu_book
                    </Icon>
                    {o.name}
                  </>
                }
              />
            ))}
          />
          {handle && (
            <ListItemIcon
              style={{ cursor: 'grab' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {sort && (
                <Tooltip title='ドラッグして並び替え'>
                  <HandleIcon />
                </Tooltip>
              )}
            </ListItemIcon>
          )}
        </ListItem>
        {isNotLast && <Divider />}
      </div>
    </>
  );
};

import { Drawer } from '../../types';

type Action = {
  type: string;
  isHideMenu?: boolean;
};

const SWITCH = 'mo-admin/drawer/switch';

export const switchDrawer = (): Action => ({ type: SWITCH });

const defaultState = { open: true, isHideMenu: false };

const Reducer = (state: Drawer = defaultState, action: Action): Drawer => {
  if (action.type === SWITCH) {
    return { ...state, open: !state.open };
  }
  return state;
};

export default Reducer;

import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, List, Button } from '@mui/material';
import { orderBy } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { usePages } from '@order/admin/store/hooks';
import { MenuDetailDialogContentRow } from './MenuDetailDialogContentRow';

const useSelectPages = (menu, pages) => {
  const [selectedPages, setSelectedPages] = useState([]);
  useEffect(() => {
    if (menu && menu.pageIds) {
      const selectedPages = [];
      menu.pageIds.forEach((id) => {
        const page = pages.find((page) => id === page.id);
        if (page) {
          selectedPages.push(page);
        }
      });
      setSelectedPages(orderBy(selectedPages, ['order'], 'asc'));
    } else {
      setSelectedPages([]);
    }
  }, [menu, pages, setSelectedPages]);
  return selectedPages;
};

export const MenuDetailDialog = ({ menu, open, onClose }) => {
  const pages = usePages() || [];
  const selectedPages = useSelectPages(menu, pages);

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>
        <strong>{menu?.name}</strong> メニューに表示中のページ
      </DialogTitle>
      <DialogContent dividers>
        {selectedPages.length > 0 ? (
          <List>
            {selectedPages.map((page, i) => (
              <MenuDetailDialogContentRow key={page.id} id={page.id} isNotLast={i + 1 < selectedPages.length} />
            ))}
          </List>
        ) : (
          <Typography gutterBottom style={{ padding: 10 }}>
            メニュー「<strong>{menu?.name}</strong>」には、まだページが登録されていません。
            <br />
            メニューの編集画面で表示するページを選択してください。
          </Typography>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '20px' }}>
        <Button
          component={RouterLink}
          to={`/c/menu/${menu?.id}`}
          variant='outlined'
          color='secondary'
          style={{ textTransform: 'none' }}
        >
          <strong>{menu?.name}</strong> の編集画面へ
        </Button>
        <Button style={{ marginLeft: 8 }} variant='contained' color='secondary' autoFocus onClick={onClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { closeSnackbar, openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';
import { firebaseFirestore } from '../../firebase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useUser,
  useAccountInfo,
  useCategories,
  useMenuItems,
  useRootRef,
  useTopCategories,
  useSaveOfferTypeToFlickOrder,
  useUbiregiHostName,
} from '../../store/hooks';

export const useMigration = () => {
  const db = firebaseFirestore();
  const ubiregiDomain = useUbiregiHostName();
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const categories = useCategories();
  const topCategories = useTopCategories().sort((a, b) => a.order - b.order);
  const menuItems = useMenuItems();
  const account = useAccountInfo();
  const rootRef = useRootRef();
  const saveToFO = useSaveOfferTypeToFlickOrder();

  const migrateCategories = async () => {
    for (const c of categories) {
      const topCategory = topCategories.find((t) => t.id === c.top_category_id);
      if (topCategory) {
        const items = menuItems.filter((i) => i.item.category_id === c.category.id);

        const payload = {
          full: !!topCategory.full,
          itemIds: items.map((i) => i.item.id),
          name: c.category.name,
          category_id: c.category.id,
        };
        if (c.image) payload.image = c.image;
        await rootRef.collection('pages').doc(String(c.category.id)).set(payload);
      }
    }
  };

  const migrateTopCategories = async () => {
    for (const t of topCategories) {
      const pageIds = categories.filter((c) => c.top_category_id === t.id).map((c) => String(c.category.id));
      const payload = {
        pageIds,
        name: t.name,
      };
      if (t.image) payload.image = t.image;
      await rootRef.collection('menuList').doc(t.id).set(payload);
    }
  };

  const createDefaultOfferType = async () => {
    const payload = { name: 'デフォルトメニュー', order: 0, enable: true };
    for (const k of ['shop_name', 'desc', 'layout', 'sold_out_label', 'logo']) {
      if (account[k]) payload[k] = account[k];
    }
    payload.menuIds = topCategories.map((t) => t.id);
    await rootRef.collection('offerTypes').add(payload);
  };

  const updateMenuItems = async (items) => {
    const batch = db.batch();

    const updater = async (item) => {
      const menuItem = menuItems.find((i) => i.item.id === item.id);
      if (menuItem && menuItem.item.name !== item.name) {
        const ref = rootRef.collection('menu_items').doc(String(menuItem.item.id));
        batch.update(ref, { name: menuItem.item.name, item });
      }
    };

    await Promise.all(items.map(updater));
    await batch.commit();
  };

  const migrateMenuItems = async ({ menuId, url, options }) => {
    if (!url) url = `https://${ubiregiDomain}/api/3/menus/${menuId}/items`;

    const response = await fetch(url, options);
    if (response.status === 200) {
      const data = await response.json();
      const items = [...data.items];
      items.length > 0 && (await updateMenuItems(items));

      let nextUrl = data['next-url'];
      if (nextUrl) await migrateMenuItems({ menuId, url: nextUrl, options });
    }
  };

  const requestSyncMenuItems = async () => {
    await rootRef.update({ sync: 'request' });
  };

  const getUbiregiAccessTokenHeaders = async (uid) => {
    const credential = (await db.collection('credentials').doc(uid).get()).data();

    if (credential.api3_token) {
      return {
        'Content-Type': 'application/json',
        'X-Ubiregi-Auth-Token': credential.api3_token,
      };
    } else if (credential.access_token) {
      return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${credential.access_token}`,
      };
    } else {
      return {};
    }
  };

  return async () => {
    try {
      dispatch(setMessageToSnackbar('移行を開始します'));
      dispatch(openSnackbar());
      const headers = await getUbiregiAccessTokenHeaders(user.uid);
      const options = { headers };
      const ubiUrl = `https://${ubiregiDomain}/api/3/accounts/${user.uid}`;
      const account = await fetch(ubiUrl, options).then((res) => res.json());
      const menuId = account.account.menus[0];

      await migrateMenuItems({ menuId, options });
      await migrateCategories();
      await migrateTopCategories();
      await createDefaultOfferType();
      await requestSyncMenuItems();

      await saveToFO();

      dispatch(closeSnackbar());
      dispatch(setMessageToSnackbar('移行が完了しました'));
      dispatch(openSnackbar());

      history.push('/c/offertype');
    } catch (error) {
      dispatch(closeSnackbar());
      dispatch(
        setMessageToSnackbar(
          '移行失敗しました。再度お試し頂いても同じエラーが出続ける場合は、弊社にお問い合わせください。',
        ),
      );
      dispatch(openSnackbar());
      throw error;
    }
  };
};

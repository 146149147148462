import { Button, Input, Typography, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { useOfferTypeMutator } from '../../store/hooks/firestore';
import { useStyles } from './useStyles';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { useSaveOfferTypeToFlickOrder } from '../../store/hooks';

export const OfferTypeNameForm = ({ offerType, offerTypeId, offerTypes, classes }) => {
  const { update } = useOfferTypeMutator();
  const [onEdit, setOnEdit] = useState(false);
  const [name, setName] = useState('');
  const { saveButton } = useStyles();
  const { sending, saved } = useSaveNotify();
  const saveToFO = useSaveOfferTypeToFlickOrder();

  const handleNameChanges = useCallback(
    (name) => {
      setName(name);
    },
    [setName],
  );

  useEffect(() => {
    setName(offerType.name);
  }, [offerType]);

  const handleCancel = () => {
    setName(offerType.name);
    setOnEdit(false);
  };

  const saveName = async () => {
    const names = offerTypes.map((o) => o.name);
    if (offerType.name !== name && names.find((n) => name === n)) {
      window.alert('入力したメニューブック名は既に存在します。異なる名前を入力してください');
      return;
    }

    await update({ id: offerTypeId, payload: { name, updateDate: new Date() } });
    if (offerType.enable) {
      // flickorder-serverに送信する処理が長いので、「送信中です」のトーストを出す
      sending();
      await saveToFO();
    }
    saved();
    setOnEdit(false);
  };

  if (!onEdit) {
    return (
      <Typography variant='h4' gutterBottom>
        {name}
        <IconButton data-testid='edit-button' color='primary' onClick={() => setOnEdit(true)} size='large'>
          <Edit />
        </IconButton>
      </Typography>
    );
  }

  return (
    <>
      <Input
        classes={classes}
        value={name}
        fullWidth
        placeholder={'メニューブック名'}
        onChange={(e) => handleNameChanges(e.target.value)}
        style={{ fontSize: '2.1rem' }}
      />
      <Button
        data-testid='save-button'
        className={saveButton}
        onClick={saveName}
        variant='contained'
        color='secondary'
        size='large'
        disabled={!name}
      >
        <Typography variant='body1'>保存</Typography>
      </Button>
      <Button color='secondary' onClick={handleCancel}>
        キャンセル
      </Button>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Checkbox, FormControlLabel, Box, Button } from '@mui/material';
import { useRootRef, useAccountInfo } from '../../store/hooks';
import { openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';

const OtherSettings = ({ updating, setUpdating }) => {
  const rootRef = useRootRef();
  const accountInfo = useAccountInfo();
  const dispatch = useDispatch();
  const [skipSingleMenuList, setSkipSingleMenuList] = useState(false);

  useEffect(() => {
    if (!accountInfo) return;
    setSkipSingleMenuList(accountInfo.skip_single_menuList || false);
  }, [accountInfo]);

  const toggleSkipSingleMenuList = async () => {
    setUpdating(true);
    await rootRef.update({ skip_single_menuList: skipSingleMenuList });
    dispatch(setMessageToSnackbar('保存しました'));
    dispatch(openSnackbar());
    setUpdating(false);
  };

  return (
    <Box
      flexDirection='row'
      display='flex'
      justifyContent='space-between'
      alignItems='right'
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Box marginLeft={6}>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormControlLabel
            control={
              <Checkbox
                checked={skipSingleMenuList}
                onChange={() => setSkipSingleMenuList(!skipSingleMenuList)}
                color='primary'
              />
            }
            label='メニューが１種類の場合、トップ画面を省略してメニューを表示する'
          />
          <Box marginLeft={3}>
            <Typography variant='body1'>
              ※タイトル・ロゴ・メッセージ・メニューの一覧を表示するトップ画面を省略し、すぐにメニューが選択できるようになります。
            </Typography>
          </Box>

          <Box marginTop={5}>
            <Button
              size='large'
              type='submit'
              variant='contained'
              color='secondary'
              disabled={updating}
              onClick={toggleSkipSingleMenuList}
            >
              保存
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default OtherSettings;

import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import { useUser, useDrawerIsOpen, useTemplate, useTopCategories, useOfferTypes } from '@order/admin/store/hooks';
import { switchDrawer } from '@order/admin/store/modules/drawer';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText, Icon, Divider, IconButton, Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const drawerWidth = 210;
const toggleMargin = 8;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    ...theme.mixins.toolbar,
  },
  title: {
    paddingTop: 4,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9),
    },
  },
  toggle: {
    margin: toggleMargin,
    flex: 1,
    alignItems: 'flex-end',
  },
  toggleOpen: {
    marginLeft: drawerWidth - toggleMargin - 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toggleClose: {
    marginLeft: toggleMargin,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  logo: {
    maxHeight: 30,
    [theme.breakpoints.up('md')]: {
      marginLeft: -10,
    },
  },
}));

const ListItemButtonLink = ({ to, children }) => {
  if (to.startsWith('https')) {
    return (
      <ListItemButton component='a' href={to} rel='noopener' target='_blank' style={{ minHeight: 48 }}>
        {children}
      </ListItemButton>
    );
  }
  return (
    <ListItemButton
      component={React.forwardRef((props, ref) => (
        <NavLink to={to} {...props} ref={ref} activeClassName='Mui-selected' style={{ minHeight: 48 }} />
      ))}
    >
      {children}
    </ListItemButton>
  );
};

const LinkItem = ({ to, icon, text, open }) => {
  return (
    <ListItemButtonLink to={to}>
      <ListItemIcon style={{ minWidth: 40 }}>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={text} style={{ display: !open ? 'none' : '' }} />
    </ListItemButtonLink>
  );
};

export const AppDrawer = () => {
  const user = useUser();
  const open = useDrawerIsOpen();
  const dispatch = useDispatch();
  const template = useTemplate(user);
  const topCategories = useTopCategories();
  const offerTypes = useOfferTypes() || [];
  const classes = useStyles();
  const zIndex = 1;

  const MigratorLinkItem = () => {
    if (topCategories && topCategories.length && !offerTypes.length) {
      return <LinkItem open={open} to='/c/migrator' icon='double_arrow' text='データ移行' />;
    } else {
      return (
        <>
          <LinkItem open={open} to='/c/items' icon='restaurant' text='商品' />
          <LinkItem open={open} to='/c/page' icon='description' text='ページ' />
          <LinkItem open={open} to='/c/menu' icon='library_books' text='メニュー' />
          <LinkItem open={open} to='/c/offertype' icon='menu_book' text='メニューブック' />
        </>
      );
    }
  };

  const TemplateLinkItem = ({ template }) => {
    return template && !template.empty && <LinkItem open={open} to='/c/template' icon='list' text='テンプレート' />;
  };

  const DrawerContent = () => {
    return (
      <>
        <div className={classes.toolbar}>
          <NavLink to='/' style={{ width: '100%' }}>
            <span
              className={clsx(classes.title, {
                [classes.hide]: open,
              })}
              style={{ width: 30 }}
            >
              <img src='/logo-small.svg' alt='logo' style={{ width: 24 }} />
            </span>
            <span
              className={clsx(classes.title, {
                [classes.hide]: !open,
              })}
              style={{ width: '100%' }}
            >
              <img src='/logo.svg' alt='logo' className={classes.logo} />
            </span>
          </NavLink>
        </div>
        <Divider />
        <List style={{ width: '100%' }}>
          <MigratorLinkItem />
          <Divider />
          <LinkItem open={open} to='/c/qr' icon='receipt' text='QRコード設定' />
          <TemplateLinkItem template={template} />
          <LinkItem open={open} to='/c/check' icon='money' text='会計・決済設定' />
          <Divider />
          <LinkItem open={open} to='/c/backup' icon='import_export' text='データ管理' />
          <LinkItem open={open} to='/c/download' icon='book' text='マニュアル・POP' />
          <LinkItem
            open={open}
            to='https://support.ubiregi.jp/archives/category/qrorder-payment'
            icon='help'
            text='ヘルプページ'
          />
        </List>
        <div
          className={clsx(classes.toggle, {
            [classes.toggleOpen]: open,
            [classes.toggleClose]: !open,
          })}
        >
          <Hidden mdDown>
            <IconButton onClick={() => dispatch(switchDrawer())} size='large'>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Hidden>
        </div>
      </>
    );
  };

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant='permanent'
          open={open}
          onClose={() => dispatch(switchDrawer())}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
        >
          <div role='presentation' onClick={() => dispatch(switchDrawer())} onKeyDown={() => dispatch(switchDrawer())}>
            <DrawerContent />
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          style={{ zIndex }}
          open={open}
        >
          <DrawerContent />
        </Drawer>
      </Hidden>
    </>
  );
};

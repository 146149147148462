// ページの名前を保存するフォーム
// ページが選択されてれば保存するし、されてなければ作成しちゃう
import { Button, Input, Typography, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { useStyles } from './useStyles';
import { usePageMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';

export const PageNameForm = ({ page, pageId, classes }) => {
  const [name, setName] = useState(page.name);
  const [onEdit, setOnEdit] = useState(false);
  const { saveButton } = useStyles();
  const { update } = usePageMutator();
  const { saved } = useSaveNotify();

  const handleNameChanges = useCallback(
    (name) => {
      setName(name);
    },
    [setName],
  );

  const handleCancel = () => {
    setName(page.name);
    setOnEdit(false);
  };

  const saveName = async () => {
    await update({ id: pageId, payload: { name, updateDate: new Date() }, updateSnapshot: page.offerTypes });
    saved();
    setOnEdit(false);
  };

  if (!onEdit) {
    return (
      <Typography variant='h4' gutterBottom>
        {name}
        <IconButton data-testid='edit-page-name' color='primary' onClick={() => setOnEdit(true)} size='large'>
          <Edit />
        </IconButton>
      </Typography>
    );
  }

  return (
    <>
      <Input
        classes={classes}
        value={name}
        fullWidth
        placeholder={'ページ名'}
        onChange={(e) => handleNameChanges(e.target.value)}
        style={{ fontSize: '2.1rem' }}
      />
      <Button
        className={saveButton}
        onClick={saveName}
        variant='contained'
        color='primary'
        size='large'
        disabled={!name}
      >
        <Typography variant='body1'>保存</Typography>
      </Button>
      <Button color='primary' onClick={handleCancel}>
        キャンセル
      </Button>
    </>
  );
};

// 商品エディ//タ
import { Paper, Typography, TextField, Icon, Grid, ListItem, ListItemText, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCategories, useMenuItems, useUser, useUbiregiHostName } from '../../store/hooks';
import { Loader } from '../Loader';
import { SyncButton } from '../SyncButton';
import { ItemList } from './ItemList';
import { CategoryList } from './CategoryList';
import { HelpPopover } from './HelpPopover';
import { AlertInUnavailability } from '../AlertInUnavailability';

export const MenuItemEditor = () => {
  const user = useUser();
  const items = useMenuItems();
  const [search, setSearch] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const categories = useCategories();
  const ubiregi_end_point = useUbiregiHostName();

  useEffect(() => {
    document.title = '商品 - ユビレジQRオーダー&決済管理画面';
  }, []);

  if (!user) return <Loader />;
  if (!items) return <Loader />;
  if (!categories) return <Loader />;

  const filteredItems = items
    .filter((i) => {
      if (!i.item.category_id) return false;
      if (categoryId && categoryId !== i.item.category_id) return false;

      if (search) {
        const target = `${i.item.name}${i.name}${i.item.category_name}${i.item.sku}`;
        for (const q of search.split(' ')) {
          if (q[0] === '-' && q.length > 1) {
            if (target.match(q.slice(1))) return false;
          } else {
            if (!target.match(q)) return false;
          }
        }
      }

      return true;
    })
    .sort((a, b) => a.item.category_id - b.item.category_id);

  const filteredCategories = categories
    .filter((c) => items.find((i) => String(i.item.category_id) === String(c.category.id)))
    .sort((a, b) => parseInt(a.category.position) - parseInt(b.category.position));

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '20px', margin: '20px' }}>
        <div style={{ marginTop: 20, maxWidth: 1080 }}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 6 }}>
              <Typography variant='h4'>
                <Icon style={{ marginRight: 5 }}>restaurant</Icon>商品
                <HelpPopover />
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <SyncButton />
            </Grid>
          </Grid>

          <Typography variant='subtitle1'>商品の名前や画像を設定します</Typography>
          {filteredCategories.length > 0 ? (
            <Grid container>
              <Grid item xs={3} style={{ paddingRight: 6 }}>
                <ListItem
                  button
                  ContainerComponent='div'
                  key={null}
                  onClick={() => setCategoryId(null)}
                  selected={categoryId === null}
                  style={{ marginTop: 16 }}
                >
                  <ListItemText primary='全ての商品' />
                </ListItem>
                <CategoryList categories={filteredCategories} categoryId={categoryId} setCategoryId={setCategoryId} />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginTop: 5, marginLeft: 15 }}>
                  <TextField
                    variant='standard'
                    style={{ width: '50%' }}
                    label='検索'
                    placeholder='商品名・カテゴリ名・SKUで商品を検索できます'
                    type='search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <ItemList items={filteredItems} />
              </Grid>
            </Grid>
          ) : (
            <Typography variant='subtitle2' style={{ marginTop: 20 }}>
              商品データがありません。「ユビレジの商品設定と同期」を押してデータを取得してください。
              <br />
              同期しても商品が更新されない場合は
              <Link href={`https://${ubiregi_end_point}/a/setting/menus`} target='_blank' rel='noopener'>
                ユビレジの商品設定
              </Link>
              を確認してください。
            </Typography>
          )}
        </div>
      </Paper>
    </>
  );
};

import { Tabs, Typography } from '@mui/material';
import { default as React } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tab } from '../MenuSetting/Tab';

export const OfferTypeSettingTab: React.FC<{ number: number }> = ({ number }) => {
  const offerTypeId = useParams<{ id: string }>().id;
  const history = useHistory();

  const setTabNumber = (i) => {
    const base = `/c/offertype/${offerTypeId}`;
    if (i === 0) history.push(base);
    if (i === 1) history.push(`${base}/layout`);
    if (i === 2) history.push(`${base}/soldout`);
    if (i === 3) history.push(`${base}/color`);
    if (i === 4) history.push(`${base}/showAmount`);
  };

  return (
    <Tabs value={number} onChange={(_e, value) => setTabNumber(value)} indicatorColor='primary' textColor='primary'>
      <Tab value={0} label={<Typography style={{ fontWeight: 'bold' }}>掲載メニュー</Typography>} />
      <Tab value={1} label={<Typography style={{ fontWeight: 'bold' }}>表示設定</Typography>} />
      <Tab value={2} label={<Typography style={{ fontWeight: 'bold' }}>品切れ</Typography>} />
      <Tab value={3} label={<Typography style={{ fontWeight: 'bold' }}>カスタムカラー</Typography>} />
      <Tab value={4} label={<Typography style={{ fontWeight: 'bold' }}>金額表示設定</Typography>} />
    </Tabs>
  );
};

import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export const OfferTypeSettingBreadcrumbs = ({ offerType }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(offerType.name);
  }, [offerType]);

  return (
    <>
      <Breadcrumbs style={{ marginBottom: 16 }} separator={<NavigateNext fontSize='small' />}>
        <Link component={RouterLink} to='/c/offertype'>
          メニューブック
        </Link>
        <Typography>
          <strong>{name}</strong> の編集
        </Typography>
      </Breadcrumbs>
    </>
  );
};

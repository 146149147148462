import React from 'react';
import { ListItem, ListItemText, Typography, Avatar, Checkbox, Icon, Chip } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 150,
    height: 'auto',
    marginRight: theme.spacing(1),
  },
  fallback: {
    width: 150,
    height: 25,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
  listSubText: {
    color: '#757575',
    fontSize: '0.9rem',
    display: 'inline',
  },
}));

export const Row = ({ page, onChange, checked, disabled }) => {
  const { avatar, fallback, listSubText } = useStyles();
  const updateDate = page.updateDate?.toDate().toLocaleString('ja-JP') || '';
  const history = useHistory();
  return (
    <ListItem button component={RouterLink} to={`/c/page/${page.id}`}>
      <Checkbox
        color='primary'
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ width: 50, height: 50, marginRight: 10 }}
      />
      <Avatar className={page.image ? avatar : fallback} variant='square' src={page.image} alt={page.name}>
        {page.name.substr(0, 5)}
      </Avatar>
      <ListItemText>
        <Typography variant='body1'>{page.name}</Typography>
        {page.offerTypes?.map((o) => (
          <Chip
            key={o.name}
            style={{ marginRight: 5 }}
            size='small'
            onClick={(e) => {
              e.preventDefault();
              history.push(`/c/offerType/${o.id}`);
            }}
            label={
              <>
                <Icon
                  style={{
                    fontSize: '1.2em',
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    marginTop: -3,
                    marginRight: 3,
                    color: '#656565',
                  }}
                >
                  menu_book
                </Icon>
                {o.name}
              </>
            }
          />
        ))}
        <div>
          <Typography variant='body1' className={listSubText}>
            <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
              restaurant
            </Icon>{' '}
            {page.itemIds?.length || 0}商品登録
          </Typography>
          <Typography variant='body1' className={listSubText} style={{ marginLeft: 15 }}>
            <Icon style={{ fontSize: '1em', display: 'inline-flex', verticalAlign: 'middle', marginTop: -3 }}>
              access_time
            </Icon>{' '}
            {updateDate} 更新
          </Typography>
        </div>
      </ListItemText>
    </ListItem>
  );
};

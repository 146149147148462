// Preview を出し入れをやる
// その他全ての機能をここの children にしているのは、左右に並べて幅とかをよろしく調整したいから。
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { closePreview, createPreviewUrl } from '@order/admin/store/modules/preview';
import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useOfferTypes } from '../../store/hooks';
import FileCopy from '@mui/icons-material/FileCopy';
import { LockPage } from '../LockPage';
import { setMessageToSnackbar, openSnackbar } from '@order/admin/store/modules/snackbar';
import { useSyncStatusReporter } from '../../store/hooks/useSyncStatusReporter';
import { useCopyOfferTypeStatusReporter } from '../../store/hooks/useCopyOfferTypeStatusReporter';

const PreviewDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.preview.open);
  const offerTypeId = useSelector((state) => state.preview.id);
  const offerTypes = useOfferTypes();
  const id = useSelector((state) => state.session.user?.uid);
  const offerType = offerTypes.find((o) => o.id === offerTypeId);
  const [url, setUrl] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    const run = () => {
      const result = createPreviewUrl(id, offerTypeId);
      setUrl(result);
    };
    if (offerTypeId) {
      setUrl('');
      run();
    }
    // eslint-disable-next-line
  }, [offerTypeId]);

  if (!open || !offerType) return null;

  const close = () => dispatch(closePreview());

  const copyToClipboard = () => {
    if (ref.current) {
      ref.current.select();
      document.execCommand('copy');
      dispatch(setMessageToSnackbar('クリップボードにコピーしました'));
      dispatch(openSnackbar());
    }
  };

  return (
    <Dialog onClose={close} open={open}>
      <DialogTitle align='center'>実機表示確認</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: '20px' }}>
        <Typography gutterBottom style={{ marginBottom: '40px' }}>
          このURLをモバイル端末で開くと、実際にどのようにメニューが表示されるのか確認ができます。
        </Typography>
        <Typography variant='h6' gutterBottom align='center' style={{ margin: '20px auto' }}>
          「{offerType.name}」確認用QRコード
        </Typography>
        <Typography align='center' style={{ margin: '20px' }}>
          {url && <QRCode value={url} size={128} />}
        </Typography>
        <Typography variant='h6' gutterBottom>
          「{offerType.name}」確認用URL
        </Typography>
        <TextField
          value={url}
          multiline
          inputRef={ref}
          rows='2'
          variant='outlined'
          style={{ width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='url copy' color='primary' size='small' edge='end' onClick={copyToClipboard}>
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export const PreviewContainer = ({ children }) => {
  useSyncStatusReporter();
  const copying = useCopyOfferTypeStatusReporter();
  if (copying) {
    return <LockPage />;
  }

  return (
    <>
      <PreviewDialog />
      {children}
    </>
  );
};

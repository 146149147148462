import { Icon, IconButton, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';

export const HelpPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} color='primary' onClick={handleClick} size='large'>
        <Icon>help</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ maxWidth: 400, padding: 16 }}>
          <Typography>
            <strong>商品</strong>設定ではユビレジの管理画面で作成した内容に加えて、以下の情報が編集できます。
          </Typography>
          <ul>
            <li>QRオーダー&決済上での商品名</li>
            <li>商品画像</li>
            <li>商品説明</li>
          </ul>
          <Typography paragraph>わかりやすい情報を追加すると、お客様の目に留まりやすくなります。</Typography>
          <div style={{ textAlign: 'center' }}>
            <img src='/tutorial-items.svg' alt='' style={{ height: 120 }} />
          </div>
        </div>
      </Popover>
    </>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { setEditorsItem } from '@order/admin/store/modules/editorDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMenuItemMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { ImageForm, imageUpload } from '../ImageForm';
import { Submitems } from './Subitems';

const IMAGE_WIDTH = 1600;
const IMAGE_HEIGHT = 1200;

export const MenuItemEditDialog = () => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.editorDialog.item);
  const { update, updateAllOfferTypeSnapshot } = useMenuItemMutator();
  const { saved } = useSaveNotify();

  const [sku, setSku] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [rawImage, setRawImage] = useState(null);
  const [description, setDescription] = useState('');

  const close = () => dispatch(setEditorsItem(null));

  const save = async () => {
    close();
    dispatch(setEditorsItem(null));
    let url = image || null;
    if (image && rawImage) {
      url = await imageUpload({ image, imageWidth: IMAGE_WIDTH, imageHeight: IMAGE_HEIGHT });
      setImage(url);
    }

    const payload = { name, image: url, description };
    await update({ id: item.item.id, payload });
    await updateAllOfferTypeSnapshot();
    saved();
  };
  useEffect(() => {
    if (!item) return undefined;
    setSku(item.item.sku);
    setName(item.name);
    setImage(item.image);
    setDescription(item.description || item.item.description || '');
    setRawImage(null);
  }, [item]);

  const open = !!item;
  return (
    <Dialog onClose={close} open={open}>
      <DialogContent dividers style={{ width: '500px' }}>
        <List>
          <ListItem>
            <FormControl variant='standard' fullWidth>
              <InputLabel htmlFor='name'>QRオーダー&決済上の表示名</InputLabel>
              <Input
                inputProps={{
                  'data-testid': 'name',
                }}
                value={name ?? item?.item?.name}
                style={{ width: '100%', fontSize: '1.2rem' }}
                onChange={(e) => setName(e.target.value === item?.item?.name ? null : e.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    {name != null && name !== item?.item?.name && (
                      <Tooltip title='商品名に戻す' data-testid='undo'>
                        <IconButton aria-label='undo' onClick={() => setName(null)} size='large'>
                          <Icon>undo</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </ListItem>
          <ListItem style={{ paddingBottom: 0 }}>
            <Typography style={{ color: '#757575', fontSize: '0.85rem' }}>商品名: {item?.item?.name}</Typography>
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Typography style={{ color: '#757575', fontSize: '0.85rem' }}>SKU: {sku}</Typography>
          </ListItem>
          <ListItem style={{ paddingTop: 0 }}>
            <Typography style={{ color: '#757575', fontSize: '0.85rem' }}>
              カテゴリ: {item?.item?.category_name}
            </Typography>
          </ListItem>
          <ImageForm
            imageWidth={IMAGE_WIDTH}
            imageHeight={IMAGE_HEIGHT}
            setImage={setImage}
            image={image}
            setRawImage={setRawImage}
            rawImage={rawImage}
          />
          <ListItem style={{ display: 'block' }}>
            <TextField
              id='outlined-multiline-static'
              label='商品説明(120文字以内)'
              multiline
              rows='5'
              variant='outlined'
              value={description}
              onChange={(e) => setDescription(e.target.value.slice(0, 120))}
              style={{ width: '100%' }}
              inputProps={{ style: { fontSize: '0.9rem' } }}
            />
          </ListItem>
          <Submitems />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={close}>
          キャンセル
        </Button>
        <Button data-testid='save' variant='contained' color='secondary' onClick={() => save()} disabled={name === ''}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// メニューの名前を保存するフォーム
// ページが選択されてれば保存するし、されてなければ作成しちゃう
import { Button, Input, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useStyles } from './useStyles';
import { useMenuListMutator } from '../../store/hooks/firestore';
import { useHistory } from 'react-router-dom';

export const MenuNameForm = ({ classes, savedAction }) => {
  const [name, setName] = useState('');
  const { saveButton } = useStyles();
  const { create } = useMenuListMutator();
  const history = useHistory();

  const handleNameChanges = useCallback(
    (name) => {
      setName(name);
    },
    [setName],
  );

  const saveName = async () => {
    const result = await create({ name, updateDate: new Date() });
    savedAction(name);
    history.push(`/c/menu/${result.id}`);
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <Typography variant='h6'>新規作成</Typography>
      <Input
        classes={classes}
        value={name}
        fullWidth
        placeholder={'メニュー名'}
        onChange={(e) => handleNameChanges(e.target.value)}
      />
      <Button
        className={saveButton}
        onClick={saveName}
        variant='contained'
        color='secondary'
        size='large'
        disabled={!name}
      >
        <Typography variant='body1'>メニューを作成</Typography>
      </Button>
    </div>
  );
};

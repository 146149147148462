import { Divider, Paper } from '@mui/material';
import { useOfferTypes } from '@order/admin/store/hooks';
import React from 'react';
import { useMergedMenuList } from '../../store/hooks';
import { Loader } from '../Loader';
import { OfferTypeSettingTab } from '../OfferTypeSettingTab';
import { CustomColor } from './CustomColor';
import { OfferTypeNameForm } from '../OfferTypeNameForm';
import { OfferTypeSettingBreadcrumbs } from '../OfferTypeSettingBreadcrumbs';
import { withStyles } from '@mui/styles';

const OfferTypeSettingColor = withStyles({ fullWidth: { width: '50%' } })(({ classes, match }) => {
  const offerTypeId = match.params.id;
  const offerTypes = useOfferTypes() || [];
  const menuList = useMergedMenuList();
  const offerType = offerTypes.find((p) => p.id === offerTypeId);

  if (!menuList || !offerType) {
    return <Loader />;
  }

  return (
    <Paper style={{ padding: '20px', margin: '20px', minHeight: 300 }}>
      <OfferTypeSettingBreadcrumbs {...{ offerType }} />
      <OfferTypeNameForm {...{ offerType, offerTypeId, offerTypes, classes }} />
      <OfferTypeSettingTab number={3} />
      <Divider style={{ marginBottom: 20 }} />
      <CustomColor {...{ offerType }} />
    </Paper>
  );
});

export default OfferTypeSettingColor;

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    height: '50px',
    marginRight: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  itemList: {
    height: '550px',
    overflow: 'scroll',
  },
  saveButton: {
    marginLeft: theme.spacing(6),
  },
}));

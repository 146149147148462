import session from './modules/session';
import foSetting from './modules/foSetting';
import snackbar from './modules/snackbar';
import preview from './modules/preview';
import menu from './modules/menu';
import offerType from './modules/offerType';
import menuList from './modules/menuList';
import editorDialog from './modules/editorDialog';
import drawer from './modules/drawer';
import accountInfo from './modules/accountInfo';
import subscription from './modules/subscription';

export const reducer = {
  session,
  foSetting,
  snackbar,
  preview,
  menu,
  offerType,
  menuList,
  editorDialog,
  drawer,
  accountInfo,
  subscription,
};

import { Button, Checkbox, Divider, FormControlLabel, Box, Typography } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { useOfferTypeMutator } from '../../store/hooks/firestore';

const useSubHeader = ({ offerType, selectedMenus, selectMenuIds, setSelectMenuId }) => {
  const { update } = useOfferTypeMutator();
  const allSelected = useMemo(() => selectMenuIds.length === selectedMenus.length, [selectMenuIds, selectedMenus]);
  const itemChecked = useMemo(() => !!selectMenuIds.length, [selectMenuIds]);
  const sortDisabled = useMemo(() => selectedMenus.length <= 1, [selectedMenus]);
  const onChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectMenuId(selectedMenus.map(({ id }) => id));
      } else {
        setSelectMenuId([]);
      }
    },
    [setSelectMenuId, selectedMenus],
  );
  const onClick = useCallback(async () => {
    if (window.confirm('選択したメニューを削除しますか')) {
      await update({
        id: offerType.id,
        payload: {
          menuIds: selectedMenus.filter((m) => !selectMenuIds.includes(m.id)).map((m) => m.id),
          updateDate: new Date(),
        },
        updateSnapshot: [{ id: offerType.id }],
      });
      setSelectMenuId([]);
    }
  }, [update, offerType.id, selectedMenus, setSelectMenuId, selectMenuIds]);

  return [allSelected, itemChecked, sortDisabled, onChange, onClick];
};

export const SubHeader = ({ offerType, selectedMenus, selectMenuIds, setSelectMenuId, sort, sortCancel, sortSave }) => {
  const [allSelected, itemChecked, sortDisabled, onChange, onClick] = useSubHeader({
    offerType,
    selectedMenus,
    selectMenuIds,
    setSelectMenuId,
  });

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: 50, marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          {!sort && (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={allSelected}
                  indeterminate={!allSelected && itemChecked}
                  onChange={onChange}
                  style={{ width: 50, height: 50, marginRight: 10 }}
                />
              }
              label={<Typography>すべて選択</Typography>}
            />
          )}
        </Box>
        <Box>
          {!sort && (
            <Button disabled={!itemChecked} variant='contained' color='secondary' onClick={onClick}>
              このメニューブックから外す
            </Button>
          )}
          {sort && (
            <Button variant='contained' style={{ marginLeft: 10 }} color='secondary' onClick={sortSave}>
              並び替え完了
            </Button>
          )}
          <Button
            disabled={sortDisabled}
            variant='outlined'
            style={{ marginLeft: 10 }}
            color='secondary'
            onClick={sortCancel}
          >
            {sort ? '並び替えキャンセル' : '並び替え'}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

import { firebaseFirestore } from '../../firebase';

import { AccountInfo } from '../../types';

const LOAD_ACCOUNT_INFO = 'mo-admin/account_info/load';

type Action = {
  type: string;
  accountInfo: AccountInfo;
};

export const loadAccountInfo = (accountInfo: AccountInfo): Action => {
  return { type: LOAD_ACCOUNT_INFO, accountInfo };
};

type State = { accountInfo?: AccountInfo };

const Reducer = (state: State = {}, action: Action): State => {
  if (action.type === LOAD_ACCOUNT_INFO) {
    return { accountInfo: action.accountInfo };
  }
  return state;
};

export default Reducer;

export const subscribeAccountInfo = (id, callback) => {
  firebaseFirestore()
    .collection('accounts')
    .doc(id)
    .onSnapshot((snapshot) => callback(snapshot.data()));
};

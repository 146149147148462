import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar, setMessageToSnackbar } from '../modules/snackbar';
import { useAccountInfo, useRootRef } from './index';
import dayjs from 'dayjs';

export const useSyncStatusReporter = () => {
  const account = useAccountInfo();
  const rootRef = useRootRef();

  const [doing, setDoing] = useState(false);
  const dispatch = useDispatch();

  const { sync, last_sync_at } = account || {};
  //最後に同期を実行した時刻(last_sync_at)から10分以上経っていた場合、syncがrequestでも同期は完了しているとする。
  const isCompletedSync = dayjs().isAfter(dayjs.unix(last_sync_at).add(10, 'm'));
  useEffect(() => {
    if (sync === 'request' && isCompletedSync) {
      setDoing(false);
      rootRef.update({ sync: 'done' });
    }
  }, [isCompletedSync, sync, rootRef]);

  useEffect(() => {
    setDoing(sync === 'request');

    if (doing && sync === 'done') {
      setDoing(false);
      dispatch(setMessageToSnackbar('ユビレジの商品設定と同期が完了しました。'));
      dispatch(openSnackbar());
    }
  }, [sync, doing, dispatch]);

  return doing;
};

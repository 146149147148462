import React from 'react';
import { SvgIcon } from '@mui/material';

export const QRCodeIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -4 36 36'>
        <path d='M28,13.73H18.33a2.06,2.06,0,0,1-2.06-2.05V2.05a2.06,2.06,0,0,1,2.06-2H28A2.05,2.05,0,0,1,30,2.05v9.63A2.05,2.05,0,0,1,28,13.73Zm-8.84-2.84h8.05v-8h-8Z' />
        <path d='M21.3,4.65H25a.39.39,0,0,1,.38.39V8.69a.38.38,0,0,1-.38.39H21.3a.38.38,0,0,1-.38-.39V5A.39.39,0,0,1,21.3,4.65Z' />
        <path d='M21.86,21.5h2.81a.38.38,0,0,1,.38.38v2.64a.37.37,0,0,1-.37.37H21.87a.38.38,0,0,1-.38-.38V21.88A.37.37,0,0,1,21.86,21.5Z' />
        <path d='M18.69,24.63H21.5a.38.38,0,0,1,.38.38v2.64a.37.37,0,0,1-.37.37H18.69a.38.38,0,0,1-.38-.38V25A.37.37,0,0,1,18.69,24.63Z' />
        <path d='M25,18.34h2.81a.38.38,0,0,1,.38.38v2.64a.37.37,0,0,1-.37.37H25a.38.38,0,0,1-.38-.38V18.72A.37.37,0,0,1,25,18.34Z' />
        <path d='M25.07,24.68h2.81a.38.38,0,0,1,.38.38V27.7a.37.37,0,0,1-.37.37H25.08a.38.38,0,0,1-.38-.38V25.05A.37.37,0,0,1,25.07,24.68Z' />
        <path d='M18.7,18.37h2.81a.37.37,0,0,1,.37.37v2.64a.38.38,0,0,1-.38.38H18.7a.38.38,0,0,1-.38-.38V18.74A.37.37,0,0,1,18.7,18.37Z' />
        <path d='M11.76,13.73H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0h9.79a2,2,0,0,1,2,2v9.79A2,2,0,0,1,11.76,13.73ZM2.84,10.89h8.05v-8h-8Z' />
        <path d='M5,4.65H8.7A.39.39,0,0,1,9.08,5V8.69a.38.38,0,0,1-.38.39H5a.38.38,0,0,1-.38-.39V5A.39.39,0,0,1,5,4.65Z' />
        <path d='M11.78,30H2a2,2,0,0,1-2-1.9V18.22a2,2,0,0,1,2-1.95h9.82a2,2,0,0,1,2,1.95V28.1A2,2,0,0,1,11.78,30ZM2.84,27.15h8.05V19.09h-8Z' />
        <path d='M5.1,20.92H8.8a.38.38,0,0,1,.37.38V25a.38.38,0,0,1-.37.38H5.1A.38.38,0,0,1,4.73,25V21.3A.38.38,0,0,1,5.1,20.92Z' />
      </svg>
    </SvgIcon>
  );
};

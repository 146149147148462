import { Breadcrumbs, Divider, Link, Paper, Tabs, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMergedPages } from '@order/admin/store/hooks';
import { Loader } from '../Loader';
import { Internationalization } from './Internationalization';
import { LayoutSetting } from './LayoutSetting';
import { PageNameForm } from './PageNameForm';
import { SelectItem } from './SelectItem';
import { withStyles } from '@mui/styles';
import { Tab } from '../MenuSetting/Tab';

const PageSetting = withStyles({ fullWidth: { width: '50%' } })(({ classes, match }) => {
  const pageId = match.params.id;
  const [tabNumber, setTabNumber] = useState(0);
  const pages = useMergedPages();
  const page = pages.find((p) => p.id === pageId);

  if (!page) {
    return <Loader />;
  }

  return (
    <Paper style={{ padding: '30px 20px', margin: '20px', minHeight: 300 }}>
      <Breadcrumbs style={{ marginBottom: 16 }} separator={<NavigateNext fontSize='small' />}>
        <Link component={RouterLink} to='/c/page'>
          ページ
        </Link>
        <Typography>
          <strong>{page.name}</strong> の編集
        </Typography>
      </Breadcrumbs>
      <PageNameForm {...{ page, pageId, classes }} />
      <Tabs
        value={tabNumber}
        onChange={(_e, value) => {
          setTabNumber(value);
        }}
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab value={0} label={<Typography style={{ fontWeight: 'bold' }}>表示商品一覧</Typography>} />
        <Tab
          value={1}
          label={
            <Typography data-testid='layout-in-page-setting' style={{ fontWeight: 'bold' }}>
              レイアウト設定
            </Typography>
          }
        />
        <Tab
          value={2}
          label={
            <Typography data-testid='internationalization-in-page-setting' style={{ fontWeight: 'bold' }}>
              多言語対応
            </Typography>
          }
        />
      </Tabs>
      <Divider style={{ marginBottom: 20 }} />
      {!tabNumber && <SelectItem {...{ page }} />}
      {tabNumber === 1 && (
        <LayoutSetting
          pageId={pageId}
          pageOfferTypes={page.offerTypes}
          image={page.image}
          full={!!page.full}
          desc={page.desc}
        />
      )}
      {tabNumber === 2 && <Internationalization {...{ page }} />}
    </Paper>
  );
});

export default PageSetting;

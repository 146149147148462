import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
  Box,
  Checkbox,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ImageForm } from '../../ImageForm';
import { HelpPopover } from './HelpPopover';

export const Content = (props) => {
  const { values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, errors } = props;
  const [rawImage, setRawImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(640);
  const [imageHeight, setImageHeight] = useState(360);
  const [image, setImage] = useState(values.logo);
  const [isImageEdited, setIsImageEdited] = useState(false);
  const [tempImage, setTempImage] = useState(null);

  useEffect(() => {
    setFieldValue('logo', image);
  }, [image, setFieldValue]);

  useEffect(() => {
    setFieldValue('isImageEdited', isImageEdited);
  }, [isImageEdited, setFieldValue]);

  useEffect(() => {
    setFieldValue('tempImage', tempImage);
  }, [tempImage, setFieldValue]);

  useEffect(() => {
    if (isSubmitting) {
      setRawImage(null);
      setTempImage(null);
    }
    setImage(values.logo);
  }, [values, isSubmitting]);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        setImageWidth(img.width);
        setImageHeight(img.height);
      };
      img.src = image;
    } else {
      setImageWidth(640);
      setImageHeight(360);
    }
  }, [image]);

  return (
    <>
      <Typography variant='h5' style={{ marginTop: '40px' }}>
        トップ画面の表示設定
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className='account-text-field__container'>
          <TextField
            variant='standard'
            className='account-text-field'
            name='shop_name'
            label='タイトル（テキスト）'
            value={values.shop_name || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ width: '500px' }}
          />
        </div>

        <div className='account-text-field__container' style={{ maxWidth: '400px' }}>
          <Typography variant='h6' style={{ marginTop: 20 }}>
            <Box fontWeight='bold'>タイトル画像設定・レイアウト</Box>
          </Typography>
          <Box fontWeight='bold'>
            タイトル画像
            <HelpPopover />
          </Box>
          <Typography style={{ fontSize: '0.9rem' }}>（画像を設定すると、テキストは表示されなくなります）</Typography>
          <ImageForm
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            setImage={setImage}
            image={image}
            rawImage={rawImage}
            setRawImage={setRawImage}
            fixAspect={false}
            setIsImageEdited={setIsImageEdited}
            setTempImage={setTempImage}
          />
        </div>

        <div className='account-text-field__container' style={{ marginTop: 20 }}>
          <Box fontWeight='bold'>レイアウト</Box>
          <RadioGroup
            name='layout'
            className='account-layout__container'
            value={values.layout || 'center'}
            onChange={handleChange}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value='left' control={<Radio color='primary' />} label='左揃え' />
            <FormControlLabel value='center' control={<Radio color='primary' />} label='中央揃え' />
            <FormControlLabel value='right' control={<Radio color='primary' />} label='右揃え' />
          </RadioGroup>
        </div>

        <div className='account-text-field__container' style={{ marginTop: 20 }}>
          <TextField
            error={!!errors.desc?.length}
            multiline
            rows={3}
            variant='outlined'
            className='account-text-field'
            name='desc'
            label='トップ下メッセージ'
            value={values.desc || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ width: '500px' }}
          />
          <FormHelperText style={{ marginBottom: 5 }}>
            {'1行あたり15文字で折り返されます。4行以上は入力できません。'}
          </FormHelperText>
          {errors.desc && <FormHelperText error={true}>{errors.desc}</FormHelperText>}
        </div>
        <div className='account-text-field__container'>
          <Typography variant='h6' style={{ marginTop: 20 }}>
            <Box fontWeight='bold'>メニュー表示設定</Box>
          </Typography>
          <FormControlLabel
            label='画面内のメニューボタンを小さく表示する'
            control={
              <Checkbox
                name='is_small_menu_image'
                checked={values.is_small_menu_image}
                onChange={() => {
                  setFieldValue('is_small_menu_image', !values.is_small_menu_image);
                }}
                color='primary'
              />
            }
          />
          <FormHelperText>画面内のメニューボタンの高さが通常の半分になり、より多く表示されます。</FormHelperText>
        </div>
        <div className='account-text-field__container'>
          <Typography variant='h5' style={{ marginTop: '40px' }}>
            ページ・商品画面の表示設定
          </Typography>
          <Typography variant='h6' style={{ marginTop: 20 }}>
            <Box fontWeight='bold'>表示フォント</Box>
            <RadioGroup
              value={values.itemDescFont || 'sansSerif'}
              name='itemDescFont'
              className='account-layout__container'
              onChange={handleChange}
            >
              <Box marginTop={2}>
                <Typography> 商品名や各種説明文などの書体を変更できます。</Typography>
              </Box>
              <FormControlLabel value={'sansSerif'} control={<Radio color='primary' />} label='ゴシック体' />
              <FormControlLabel
                value='serif'
                control={<Radio color='primary' />}
                label={
                  <Typography style={{ fontFamily: 'serif' }}>明朝体 (標準表示対応されている機種のみ反映)</Typography>
                }
              />
            </RadioGroup>
          </Typography>
          <Typography variant='h6' style={{ marginTop: 20 }}>
            <Box fontWeight='bold'>商品名の文字の太さ</Box>
            <RadioGroup
              value={values.itemNameFontWeight || 'bold'}
              name='itemNameFontWeight'
              className='account-layout__container'
              onChange={handleChange}
            >
              <Box marginTop={2}>
                <Typography> 商品名の文字の太さを変更できます。</Typography>
              </Box>
              <FormControlLabel
                value={'bold'}
                control={<Radio color='primary' />}
                label={<Typography style={{ fontWeight: 'bold' }}>ボールド(太字)</Typography>}
              />
              <FormControlLabel value={'normal'} control={<Radio color='primary' />} label='ノーマル(細字)' />
            </RadioGroup>
          </Typography>
        </div>
        <div className='account-text-field__container' style={{ marginTop: 30 }}>
          <Button
            size='large'
            type='submit'
            variant='contained'
            color='secondary'
            disabled={isSubmitting || !!errors.desc}
          >
            保存
          </Button>
        </div>
      </form>
    </>
  );
};

import { Icon, Button, Paper, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import React from 'react';
import { useStyles } from './useStyles';
import { useUbiregiHostName } from '../../store/hooks';
import { AdminAppBar } from '../admin-appbar';

export const Expired = () => {
  const classes = useStyles();
  const host = useUbiregiHostName();
  const plansURL = `https://${host}/a/account/billing/plans`;
  const supportFormURL = `https://${host}/ja/forms/support`;
  return (
    <>
      <AdminAppBar fullWidth={true} />
      <div className={classes.toolbar} />
      <div className={classes.paperWrapper}>
        <Paper className={classes.paper} style={{ width: '100%', maxWidth: '1000px', padding: 40 }}>
          <img src='/logo.svg' alt='ユビレジ QRオーダー' style={{ maxHeight: 50, margin: '20px auto 60px' }} />
          <Typography variant='h5' style={{ marginBottom: 20 }}>
            ユビレジ QRオーダー&決済のご利用状況をご確認ください
          </Typography>
          <Typography style={{ marginBottom: 40 }}>
            ユビレジ QRオーダー&決済・ユビレジ プレミアムプラン・ユビレジ
            ハンディいずれかのご利用期間が満了しているため、この画面はご利用いただけません。ご利用状況をご確認ください。
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            style={{ maxWidth: 480, margin: '0 auto 40px' }}
            size='large'
            endIcon={<Icon>arrow_forward_ios</Icon>}
            href={plansURL}
            target='_blank'
            rel='noopener'
          >
            ご利用プランの確認・お申し込みはこちら
          </Button>
          <Alert variant='outlined' severity='info'>
            ご利用期間内のお客様で、この画面が表示されている場合
            <br />
            お手数おかけしますが、こちらのフォームよりご連絡ください。
            <br />
            <Typography color='inherit' variant='body2' component='a' href={supportFormURL}>
              サポート用問い合わせフォーム
            </Typography>
          </Alert>
        </Paper>
      </div>
    </>
  );
};

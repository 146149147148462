import React from 'react';

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    // if (this.state.hasError) {
    //   window.location.replace('/');
    //   return false;
    // }

    return this.props.children;
  }
}

import React, { ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { orange, brown } from '@mui/material/colors';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: orange[600] },
      secondary: { main: brown[500] },
      error: { main: '#e53935' },
      warning: { main: '#fdd835' },
      info: { main: '#2196f3' },
      action: { hover: 'rgba(251, 140, 0, 0.08)' },
    },
  }),
);

export const ThemeProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <>{children}</>
    </MuiThemeProvider>
  );
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    height: '75px',
    marginRight: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  itemList: {
    height: '550px',
    overflow: 'scroll',
  },
  saveButton: {
    marginLeft: theme.spacing(6),
  },
  listSubText: {
    color: theme.palette.grey[600],
    fontSize: '0.9rem',
  },
  textfieldLabel: {
    position: 'absolute',
    top: '-10px',
    left: '10px',
    background: theme.palette.common.white,
    padding: '0 5px',
    color: theme.palette.primary.main,
    fontSize: '0.85rem',
  },
  textfieldBox: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    position: 'relative',
    width: '26.5em',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[600]}`,
    },
  },
  focusedTextFieldBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  outlinedInput: {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none', // ホバー時にボーダーを表示しない
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none', // フォーカス時にボーダーを表示しない
    },
  },
}));

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { reducer } from './store/reducer';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Authenticate from './components/Authenticate';
import { AdminAppBar } from './components/admin-appbar';
import { SnackBar } from './components/SnackBar';
import { PreviewContainer } from './components/PreviewContainer';
import './App.scss';
import { TableScreen, QRCodeScreen, TemplateScreen } from './screens';

import { Home } from './components/Home';
import { MenuItemEditor } from './components/MenuItemEditor';
import { AppDrawer } from './components/AppDrawer';
import { DataProvider } from './components/DataProvider';
import { FOChecker } from './components/FOChecker';
import { CheckEditor } from './components/CheckEditor';
import { MenuItemEditDialog } from './components/MenuItemEditDialog';
import { useFirebaseConfig } from './store/hooks';
import { Loader } from './components/Loader';
import { NotFoundOnDev } from './components/NotFoundOnDev';
import { NotFound } from './components/NotFound';
import { ErrorBoundary } from './components/ErrorBoundary/index';
import { DownloadPdf } from './components/DownloadPdf/index';
import { ResizeBody } from './components/ResizeBody';
import { ThemeProvider } from './theme';
import { configureStore } from '@reduxjs/toolkit';

const OfferTypeList = lazy(() => import('./components/OfferTypeList'));
const OfferTypeSetting = lazy(() => import('./components/OfferTypeSetting'));
const OfferTypeSettingLayout = lazy(() => import('./components/OfferTypeSettingLayout'));
const OfferTypeSettingSoldOut = lazy(() => import('./components/OfferTypeSettingSoldOut'));
const OfferTypeSettingColor = lazy(() => import('./components/OfferTypeSettingColor'));
const OfferTypeSettingShowAmount = lazy(() => import('./components/OfferTypeSettingShowAmount'));

const MenuList = lazy(() => import('./components/MenuList'));
const MenuSetting = lazy(() => import('./components/MenuSetting'));
const PageList = lazy(() => import('./components/PageList'));
const PageSetting = lazy(() => import('./components/PageSetting'));
const Migrator = lazy(() => import('./components/Migrator'));
const BackupRestore = lazy(() => import('./components/BackupRestore'));

const store = configureStore({ reducer });

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: '#fcf9f7',
    flex: '1',
    zIndex: '2',
    paddingBottom: '60px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins?.toolbar,
  },
  previewButtonWrapper: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
  },
}));

const ToolbarMargin = () => {
  const classes = useStyles();

  return <div className={classes.toolbar} />;
};

const App = () => {
  const [started, start] = useState(false);
  const firebaseConfig = useFirebaseConfig();
  const classes = useStyles();

  const loadFirebase = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      start(true);
    }
  };

  useEffect(() => {
    loadFirebase();
    // eslint-disable-next-line
  }, []);

  if (!started) return null;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline />
        <Provider store={store}>
          <Router>
            <Authenticate>
              <DataProvider>
                <SnackBar />
                <div className='App' style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <FOChecker>
                    <AppDrawer />
                    <ResizeBody>
                      <AdminAppBar />
                      <ToolbarMargin />
                      <PreviewContainer>
                        <MenuItemEditDialog />
                        <div className='App-content'>
                          <ErrorBoundary>
                            <Suspense fallback={<Loader />}>
                              <Switch>
                                <Route path='/' component={Home} exact={true} />
                                <Route path='/c' component={() => <Redirect to='/' />} exact={true} />
                                <Route path='/c/qr' component={QRCodeScreen} />
                                <Route path='/c/items' component={MenuItemEditor} />
                                <Route path='/c/template' component={TemplateScreen} />
                                <Route path='/c/tables' component={TableScreen} />
                                <Route path='/c/check' component={CheckEditor} />
                                <Route path='/c/menu' component={MenuList} exact={true} />
                                <Route path='/c/menu/:id' component={MenuSetting} />
                                <Route path='/c/offertype' component={OfferTypeList} exact={true} />
                                <Route path='/c/offertype/:id' component={OfferTypeSetting} exact={true} />
                                <Route path='/c/offertype/:id/layout' component={OfferTypeSettingLayout} />
                                <Route path='/c/offertype/:id/soldout' component={OfferTypeSettingSoldOut} />
                                <Route path='/c/offertype/:id/color' component={OfferTypeSettingColor} />
                                <Route path='/c/offertype/:id/showAmount' component={OfferTypeSettingShowAmount} />

                                <Route path='/c/page' component={PageList} exact={true} />
                                <Route path='/c/page/:id' component={PageSetting} />
                                <Route path='/c/migrator' component={Migrator} />
                                <Route path='/c/backup' component={BackupRestore} />
                                <Route path='/c/download' component={DownloadPdf} />
                                <Route component={NotFound} />
                              </Switch>
                              <NotFoundOnDev />
                            </Suspense>
                          </ErrorBoundary>
                        </div>
                      </PreviewContainer>
                      <div className={classes.previewButtonWrapper}></div>
                    </ResizeBody>
                  </FOChecker>
                </div>
              </DataProvider>
            </Authenticate>
          </Router>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

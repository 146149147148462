import React from 'react';
import { Paper, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  paper: {
    minWidth: '30%',
    padding: 20,
    margin: 20,
    [theme.breakpoints.down('lg')]: {
      margin: '20px 0 0',
      minWidth: '100%',
    },
  },
  paperWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px',
      flexDirection: 'column',
    },
  },
}));

export const Loader = ({ drawer }) => {
  const classes = useStyles();

  return (
    <>
      {!drawer && <div className={classes.toolbar} />}
      <div data-testid='loading' className={classes.paperWrapper}>
        <Paper className={classes.paper} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Paper>
      </div>
    </>
  );
};

Loader.defaultProps = {
  drawer: true,
};

import axios from 'axios';
import crypto from 'crypto';
import { useFileDownloadUpload } from './file';

type Props = {
  convertCSVToXlsx: (arg0: string, arg1: string) => Promise<any>;
  parseXlsx: (arg0: File) => Promise<any>;
};

export const useXlsx = (): Props => {
  const { parseCSV } = useFileDownloadUpload();
  const convertCSVToXlsx = async (csv: string, filename: string) => {
    const api = 'https://csv2xlsx-r3ri552coq-an.a.run.app/api/v0/csv_to_xlsx';
    const token = crypto.createHash('md5').update(`${csv}${filename}dsakld543fdskjldlkgjdlk`, 'utf8').digest('hex');
    const payload = { csv, filename, token };
    const res = await axios.post(api, payload, {
      responseType: 'blob',
    });
    return res.data;
  };

  const parseXlsx = async (file: File) => {
    const token = crypto
      .createHash('md5')
      .update(`${file.name}dsakld543fdskjdx8z90cz5klvblxfv9lj`, 'utf8')
      .digest('hex');
    const params = new FormData();
    params.append('file', file);
    params.append('token', token);
    params.append('filename', file.name);
    const api = `https://csv2xlsx-r3ri552coq-an.a.run.app/api/v0/xlsx_to_csv`;
    const res = await axios.post(api, params);
    return await parseCSV(res.data, 'UTF-8');
  };
  return { convertCSVToXlsx, parseXlsx };
};

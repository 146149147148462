import { firebaseFirestore } from '../../firebase';
import { Menu, MenuItem, Category, TopCategory, Page } from '../../types';

type Action = {
  type: string;
  topCategories?: TopCategory[];
  categories?: Category[];
  menuItems?: MenuItem[];
  pages?: Page[];
};

const LOAD_TOP_CATEGORIES = 'mo-admin/menus/load_top_categories';
const LOAD_CATEGORIES = 'mo-admin/menus/load_categories';
const LOAD_MENU_ITEMS = 'mo-admin/menus/load_menu_items';
const LOAD_PAGES = 'mo-admin/menus/load_pages';

export const loadTopCategories = (topCategories: TopCategory[]): Action => ({
  type: LOAD_TOP_CATEGORIES,
  topCategories,
});
export const loadCategories = (categories: Category[]): Action => ({ type: LOAD_CATEGORIES, categories });
export const loadMenuItems = (menuItems: MenuItem[]): Action => ({ type: LOAD_MENU_ITEMS, menuItems });
export const loadPages = (pages: Page[]): Action => ({ type: LOAD_PAGES, pages });

const initialState = {};

const Reducer = (state: Menu = initialState, action: Action) => {
  if (action.type === LOAD_TOP_CATEGORIES) {
    return Object.assign({}, state, { topCategories: action.topCategories });
  }
  if (action.type === LOAD_CATEGORIES) {
    return Object.assign({}, state, { categories: action.categories });
  }
  if (action.type === LOAD_MENU_ITEMS) {
    return Object.assign({}, state, { menuItems: action.menuItems });
  }
  if (action.type === LOAD_PAGES) {
    return Object.assign({}, state, { pages: action.pages });
  }

  return state;
};

export default Reducer;

const subscriptions = [];

const rootRef = (accountId) => {
  return firebaseFirestore().collection('accounts').doc(accountId);
};

export const subscribeTopCategories = (accountId, callback) => {
  subscriptions.push(
    rootRef(accountId)
      .collection('top_categories')
      .orderBy('order')
      .onSnapshot((snapshot) => callback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))),
  );
};
export const subscribeCategories = (accountId, callback) => {
  subscriptions.push(
    rootRef(accountId)
      .collection('categories')
      .where('category.position', '>', 0)
      .orderBy('category.position')
      .onSnapshot((snapshot) =>
        callback(snapshot.docs.map((doc) => doc.data()).sort((one, two) => one.order - two.order)),
      ),
  );
};

export const subscribeMenuItems = (accountId, callback) => {
  subscriptions.push(
    rootRef(accountId)
      .collection('menu_items')
      .orderBy('item.position')
      .onSnapshot((snapshot) => callback(snapshot.docs.map((doc) => doc.data()))),
  );
};

export const subscribePages = (accountId, callback) => {
  subscriptions.push(
    rootRef(accountId)
      .collection('pages')
      .onSnapshot((snapshot) => {
        callback(
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          }),
        );
      }),
  );
};

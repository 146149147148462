//会計呼び出しとかの設定をかえるエディタ
import React, { useEffect, useState } from 'react';
import { useAccount, useUser, useCheckoutItem, useAccountInfo, useQuery, useMenuItems } from '@order/admin/store/hooks';
import { Paper, Typography, Icon, Tabs, Divider } from '@mui/material';
import { Loader } from '../Loader';
import { SelfPayment } from './SelfPayment';
import { Checkout } from './Checkout';
import { CheckoutList } from './CheckoutList';
import { Tab } from '../MenuSetting/Tab';
import { AlertInUnavailability } from '../AlertInUnavailability';
import { RedirectSetting } from './RedirectSetting';

export const CheckEditor = (props) => {
  const account = useAccount();
  const user = useUser();
  const accountInfo = useAccountInfo();
  const [search, handleChange] = useQuery('');
  const items = useMenuItems();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const filteredItems = items.filter(
    ({ item }) => Number(item.price) === 0 && !item.composite_item && item.category_id,
  );
  const searchItems = filteredItems.filter((i) => {
    if (search) {
      const target = `${i.item.name}${i.name}${i.item.category_name}${i.item.barcode}${i.item.sku}`;
      for (const q of search.split(' ')) {
        if (q[0] === '-' && q.length > 1) {
          if (target.match(q.slice(1))) return false;
        } else {
          if (!target.match(q)) return false;
        }
      }
    }

    return true;
  });

  const [add, remove] = useCheckoutItem(user);

  useEffect(() => {
    document.title = '会計・決済設定 - ユビレジQRオーダー&決済管理画面';
  }, []);

  if (!account) return <Loader />;

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexWrap: 'wrap' }}>
        <Typography variant='h4' gutterBottom style={{ width: '100%', marginBottom: '20px' }}>
          <Icon style={{ marginRight: 5 }}>money</Icon>会計・決済設定
        </Typography>
        <Tabs
          value={activeTabIndex}
          onChange={(_e, value) => setActiveTabIndex(value)}
          indicatorColor='primary'
          textColor='primary'
          style={{ width: '100%' }}
        >
          <Tab label={<Typography fontWeight='bold'>お会計呼び出し</Typography>} />
          <Tab label={<Typography fontWeight='bold'>決済機能</Typography>} />
          <Tab label={<Typography fontWeight='bold'>ページ転送設定</Typography>} />
        </Tabs>
        <Divider style={{ width: '100%', marginBottom: 20 }} />
        {activeTabIndex === 0 && <Checkout accountInfo={accountInfo} remove={remove} />}
        {activeTabIndex === 1 && <SelfPayment />}
        {activeTabIndex === 2 && <RedirectSetting />}
      </Paper>
      {activeTabIndex === 0 && (
        <CheckoutList
          filteredItems={filteredItems}
          search={search}
          handleChange={handleChange}
          searchItems={searchItems}
          add={add}
        />
      )}
    </>
  );
};

import React from 'react';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';

const ItemsTable = (props) => {
  const body = props.body;
  const style = props.style;
  return (
    <Table size='small' style={style}>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>カテゴリ</TableCell>
          <TableCell>バーコード</TableCell>
          <TableCell align='center'>選択 / 削除</TableCell>
        </TableRow>
      </TableHead>
      {body}
    </Table>
  );
};

const Item = (props) => {
  const item = props.item;
  const remove = props.remove;
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{item.category_name}</TableCell>
      <TableCell>{item.barcode}</TableCell>
      <TableCell align='center'>
        <Button aria-label='削除' onClick={remove} color='primary'>
          削除
        </Button>
      </TableCell>
    </TableRow>
  );
};

const Items = (props) => {
  const { items, remove } = props;
  return (
    <TableBody>
      {items.map((item) => (
        <Item key={item.id} item={item} remove={remove} />
      ))}
    </TableBody>
  );
};

export const Checkout = ({ accountInfo, remove }) => {
  return (
    <>
      <Typography variant='subtitle1' style={{ width: '70%', marginBottom: 20 }}>
        お会計時、お客様の端末から店員を呼び出すことができる機能です。
        <br />
        この機能を有効にするには、0円で登録した商品をお会計呼び出し用に1つ選択してください。
      </Typography>
      <Typography variant='overline' style={{ width: '100%' }}>
        選択されているお会計呼び出し用商品
      </Typography>
      {accountInfo.checkItem ? (
        <ItemsTable body={<Items items={[accountInfo.checkItem]} remove={remove} />} />
      ) : (
        <Typography variant='subtitle2'>設定されていません</Typography>
      )}
    </>
  );
};

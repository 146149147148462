import React, { useState } from 'react';
import { ImageForm, imageUpload } from '../ImageForm';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import { useMenuListMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { HelpPopover } from './HelpPopover';

const IMAGE_WIDTH = 2048;
const IMAGE_HEIGHT = 2048;

export const LayoutSetting = ({ menuOfferTypes, menuId, image: initialImageUrl }) => {
  const [rawImage, setRawImage] = useState(null);
  const [image, setImage] = useState(initialImageUrl);
  const { update } = useMenuListMutator();
  const { saved, sending, loading } = useSaveNotify();

  const saveImageUrl = async () => {
    if (initialImageUrl && !image && !rawImage) {
      sending();
      await update({
        id: menuId,
        payload: {
          image: '',
        },
        updateSnapshot: menuOfferTypes,
      });
      saved();
      return;
    }

    const needUpload = image && image.startsWith('data:image/');
    if (needUpload) {
      setRawImage(null);
      sending();
      const url = await imageUpload({ image, imageWidth: IMAGE_WIDTH, imageHeight: IMAGE_HEIGHT });
      setImage(url);
      await update({ id: menuId, payload: { image: url }, updateSnapshot: menuOfferTypes });
    }
    saved();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6'>
          画像設定
          <HelpPopover />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul>
          <li>設定される画像は、メニューブックの「メニュー表示設定」や端末によって表示範囲が変わります。</li>
          <li>見せたい内容は中心に配置してください。</li>
          <li>画像の表示状態は表示確認用の画面（もしくは、実機表示確認）などで確認を行なってください。</li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <ImageForm
          imageWidth={IMAGE_WIDTH}
          imageHeight={IMAGE_HEIGHT}
          setImage={setImage}
          image={image}
          rawImage={rawImage}
          setRawImage={setRawImage}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          data-testid={'save-menu-image'}
          disabled={loading}
          variant='contained'
          color='secondary'
          size='large'
          onClick={saveImageUrl}
          style={{ marginTop: '40px' }}
        >
          {loading && <CircularProgress color='inherit' size={20} style={{ marginRight: 6 }} />}
          <Typography variant='body1'>画像を保存</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

import { Icon, Paper, Typography, Tabs, Divider } from '@mui/material';
import { useOfferTypes, useSaveOfferTypeToFlickOrder } from '@order/admin/store/hooks';
import { openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';
import { partition } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { List } from './List';
import OtherSettings from './OtherSettings';
import { OfferTypeNameForm } from './OfferTypeNameForm';
import { HelpPopover } from './HelpPopover';
import { withStyles } from '@mui/styles';
import { Tab } from '../MenuSetting/Tab';
import { AlertInUnavailability } from '../AlertInUnavailability';

const OfferTypeList = withStyles({ fullWidth: { width: '50%' } })(({ classes }) => {
  const offerTypes = useOfferTypes();
  const dispatch = useDispatch();
  const [disabledOfferTypes, setDisabledOfferTypes] = useState([]);
  const [enabledOfferTypes, setEnabledOfferTypes] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const saveToFO = useSaveOfferTypeToFlickOrder();

  useEffect(() => {
    if (offerTypes) {
      const [enable, disable] = partition(offerTypes, 'enable');
      setEnabledOfferTypes(enable);
      setDisabledOfferTypes(disable);
    }
  }, [offerTypes]);

  useEffect(() => {
    document.title = 'メニューブック - ユビレジQRオーダー&決済管理画面';
  }, []);

  const savedAction = async (name) => {
    dispatch(
      setMessageToSnackbar(
        `「${name}」メニューブックを作成しました。この画面からメニューブックの情報を編集してください。`,
      ),
    );
    dispatch(openSnackbar());
  };

  const deletedAction = async () => {
    dispatch(setMessageToSnackbar('削除しました。'));
    dispatch(openSnackbar());
  };

  const updateFOAction = async (message) => {
    dispatch(setMessageToSnackbar('変更をハンディに反映中です'));
    dispatch(openSnackbar());
    await saveToFO();
    dispatch(setMessageToSnackbar(message));
    dispatch(openSnackbar());
  };

  const lastOrder = offerTypes?.slice(-1)[0]?.order || 0;

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '30px 20px', margin: '20px', minHeight: 300 }}>
        <Typography variant='h4' gutterBottom>
          <Icon style={{ marginRight: 5 }}>menu_book</Icon>メニューブック
          <HelpPopover name='top' />
        </Typography>
        <Typography paragraph>
          複数のメニューブックを設定すると、お客様の来店時にユビレジ
          ハンディでメニューの切り替えができるようになります。
        </Typography>
        <Tabs
          value={activeTabIndex}
          onChange={(_e, value) => setActiveTabIndex(value)}
          indicatorColor='primary'
          textColor='primary'
        >
          <Tab label={<Typography style={{ fontWeight: 'bold' }}>公開設定</Typography>} />
          <Tab label={<Typography style={{ fontWeight: 'bold' }}>その他の設定</Typography>} />
        </Tabs>

        <Divider style={{ marginBottom: 20 }} />
        {activeTabIndex === 0 && (
          <>
            <OfferTypeNameForm {...{ classes, savedAction, offerTypes, lastOrder }} />
            <List
              enabledOfferTypes={enabledOfferTypes}
              disabledOfferTypes={disabledOfferTypes}
              deletedAction={deletedAction}
              updateFOAction={updateFOAction}
              updating={updating}
              setUpdating={setUpdating}
            />
          </>
        )}
        {activeTabIndex === 1 && <OtherSettings updating={updating} setUpdating={setUpdating} />}
      </Paper>
    </>
  );
});

export default OfferTypeList;

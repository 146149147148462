import { firebaseFirestore } from '../../firebase';
import { MenuList } from '../../types';

type Action = {
  type: string;
  menuList: MenuList[];
};

const LOAD_MENU_LIST = 'mo-admin/menuList/load_menu_list';

export const loadMenuList = (menuList: MenuList[]): Action => ({ type: LOAD_MENU_LIST, menuList });

const initialState = {};

const Reducer = (state: { menuList?: MenuList } = initialState, action: Action): { menuList?: MenuList } => {
  if (action.type === LOAD_MENU_LIST) {
    return Object.assign({}, state, { menuList: action.menuList });
  }

  return state;
};

export default Reducer;

const subscriptions = [];

export const subscribeMenuList = (accountId: any, callback: (arg0: any) => {}) => {
  subscriptions.push(
    firebaseFirestore()
      .collection('accounts')
      .doc(accountId)
      .collection('menuList')
      .onSnapshot((snapshot) =>
        callback(
          snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          }),
        ),
      ),
  );
};

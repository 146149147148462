import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import React from 'react';

export const CheckoutItemList = ({ items, add }) => {
  const Row = ({ index, style }) => {
    const item = items[index];
    const { sku, name, category_name, barcode } = item.item;
    const image = item.image;
    const barcodeText = barcode ? ` - ${barcode}` : '';

    return (
      // see https://github.com/mui-org/material-ui/issues/16026
      <ListItem ContainerProps={{ style: style }} ContainerComponent='div' key={sku} divider dense>
        <ListItemAvatar>
          <Avatar variant='square' src={image}>
            {(item.name || name).substr(0, 1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name || name} secondary={`${sku} - ${category_name} - ${name}${barcodeText}`} />

        <ListItemSecondaryAction>
          <Button aria-label='選択' onClick={() => add(item.item)} color='primary'>
            選択
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  const height = window.innerHeight - 330;

  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      {items.length > 0 ? (
        <FixedSizeList height={height} itemCount={items.length} itemSize={61}>
          {Row}
        </FixedSizeList>
      ) : (
        <Typography align='center' variant='subtitle1'>
          商品がありません
        </Typography>
      )}
    </div>
  );
};

import { Checkbox, Button, Paper, Typography, Icon, ButtonGroup, FormControlLabel } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useAccount } from '../../store/hooks';
import { Loader } from '../Loader';
import { useFileDownloadUpload } from './file';
import { useItemsBackupRestore } from './items';
import { useJSONBackup } from './jsonBackup';
import { GetApp, Publish } from '@mui/icons-material';
import { useXlsx } from './xlsx';
import { AlertInUnavailability } from '../AlertInUnavailability';

const BackupRestore: React.FC = () => {
  const [isAllItemsDownload, setIsAllItemsDownload] = useState(false);
  const { itemsToCSV, importItems } = useItemsBackupRestore(isAllItemsDownload);
  const { download } = useFileDownloadUpload();
  const { toJSON, importJSON } = useJSONBackup();
  const { convertCSVToXlsx } = useXlsx();
  const account = useAccount();

  useEffect(() => {
    document.title = 'データ管理 - ユビレジQRオーダー&決済管理画面';
  }, []);

  const downloadMenuItemsXLSX = async () => {
    const filename = `qrorder-menuitems-${account.login}.xlsx`;
    const content = await itemsToCSV();
    const xlsx = await convertCSVToXlsx(content, filename);
    download({
      filename,
      content: xlsx,
      mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };

  const downloadMenuItems = async () => {
    const filename = `qrorder-menuitems-${account.login}.csv`;
    const content = await itemsToCSV();
    download({ filename, content, mimetype: 'text/csv' });
  };

  const downloadJSON = async () => {
    const filename = `qrorder-page-menu-menubook-${account.login}.json`;
    const content = toJSON();
    download({ filename, content, mimetype: 'application/json' });
  };

  if (!account) return <Loader />;

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: 20, margin: 20 }}>
        <Typography variant='h4' gutterBottom>
          <Icon color='secondary' style={{ marginRight: 4 }}>
            import_export
          </Icon>
          データ管理(ダウンロード/アップロード)
        </Typography>
        <Typography paragraph>
          データのダウンロードで、ユビレジ QRオーダー&決済に登録されているデータをお使いの端末に保存できます。
          <br />
          またデータをアップロードすることで、そのデータの内容で設定を上書きすることができます。
          <br />
          この機能はバックアップや他のアカウントへのデータ移行用にお使いいただけます。
        </Typography>

        <Typography variant='h5' gutterBottom style={{ marginTop: 40, display: 'flex' }}>
          <Icon color='secondary' style={{ marginRight: 4, marginTop: 4 }}>
            restaurant
          </Icon>
          商品データ
        </Typography>
        <Typography paragraph>
          QRオーダー&決済管理画面で設定した商品名・説明・画像などのデータを管理します。
          <br />
          （ファイル形式：CSV, Excel）
        </Typography>
        <div style={{ paddingLeft: 20 }}>
          <FormControlLabel
            label='全商品データをダウンロード'
            control={
              <Checkbox
                checked={isAllItemsDownload}
                color='primary'
                onChange={() => setIsAllItemsDownload(!isAllItemsDownload)}
              />
            }
          />
          <div>
            ※QRオーダー&決済管理画面で設定した商品を含む、すべてのユビレジ登録商品データをダウンロードしたい場合はチェックしてください。
          </div>
        </div>
        <div style={{ padding: 20, display: 'flex', alignItems: 'center' }}>
          <ButtonGroup disableElevation variant='contained' color='secondary'>
            <Button
              variant='contained'
              color='secondary'
              style={{ textTransform: 'none' }}
              onClick={downloadMenuItems}
              startIcon={<GetApp />}
            >
              CSV形式でダウンロード
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={downloadMenuItemsXLSX}
              style={{ marginRight: 20, textTransform: 'none' }}
              startIcon={<GetApp />}
            >
              Excel形式でダウンロード
            </Button>
          </ButtonGroup>
          <Button variant='outlined' color='secondary' onClick={importItems} startIcon={<Publish />}>
            アップロード
          </Button>
        </div>

        <Typography variant='h5' gutterBottom style={{ marginTop: 40, display: 'flex' }}>
          <Icon color='secondary' style={{ marginRight: 4, marginTop: 4 }}>
            menu_book
          </Icon>
          ページ・メニュー・メニューブックのデータ
        </Typography>
        <Typography paragraph>
          ページ・メニュー・メニューブックの各種設定データを管理します。
          <br />
          （ファイル形式：json）
        </Typography>
        <div style={{ padding: 20 }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={downloadJSON}
            style={{ marginRight: 20 }}
            startIcon={<GetApp />}
          >
            ダウンロード
          </Button>
          <Button variant='outlined' color='secondary' onClick={importJSON} startIcon={<Publish />}>
            アップロード
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default BackupRestore;

import React, { useCallback } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { closeSnackbar } from '@order/admin/store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';

export const SnackBar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.snackbar.open);
  const message = useSelector((state) => state.snackbar.message);
  const close = useCallback(() => {
    dispatch(closeSnackbar());
  }, [dispatch]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={close}
    >
      <SnackbarContent
        style={{ backgroundColor: 'rgba(0,0,0,.8)' }}
        message={<span style={{ display: 'flex', alignItems: 'center' }}>{message}</span>}
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' onClick={() => close()} size='large'>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

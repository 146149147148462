import axios from 'axios';
import { FOSetting } from '../../types';

type Action = { type: String; setting: FOSetting };

const LOAD_FO_SETTING = 'mo-admin/session/load_fo_setting';

export const loadFoSetting = (setting: FOSetting): Action => {
  return {
    type: LOAD_FO_SETTING,
    setting,
  };
};

type State = { setting?: FOSetting };

export default (state: State = {}, action: Action): State => {
  if (action.type === LOAD_FO_SETTING) {
    return { setting: action.setting };
  }
  return state;
};

export const fetchFromFo = async ({ user, functionsDomain }) => {
  const idToken = user.idToken;
  const headers = {
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.get(`${functionsDomain}/foProxy?url=${encodeURIComponent('/api/settings')}`, {
      headers,
    });
    return response.data;
  } catch {
    console.error('データの取得に失敗しました');
  }
};

export const submitToFo = async ({ image, topMessage, bottomMessage, printQR, user, functionsDomain, fixedQR }) => {
  const body = {
    mo_image: image,
    mo_top_message: topMessage,
    mo_bottom_message: bottomMessage,
    mo_print_qr: printQR,
    mo_fixed_qr: fixedQR,
  };

  const idToken = user.idToken;
  const headers = {
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'application/json',
  };
  await axios.post(`${functionsDomain}/foPostProxy?url=${encodeURIComponent('/api/settings?_method=PATCH')}`, body, {
    headers,
  });
};

import React from 'react';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const setCropDimension = (cropperRef, setTempImage) => {
  if (!cropperRef?.current || !setTempImage) return;
  setTempImage(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
};

const ensureAspectRatio = (cropperRef) => {
  if (!cropperRef?.current) return;
  const cropBoxData = cropperRef.current.cropper.getCropBoxData();
  const cropBoxWidth = cropBoxData.width;
  const aspectRatio = cropBoxWidth / cropBoxData.height;
  const ratioLimit = 3.303;
  if (aspectRatio < 1) {
    // 横幅より縦幅の方が大きくなるようにクロップできないようにしている
    cropperRef.current.cropper.setCropBoxData({ height: cropBoxWidth / 1 });
  } else if (aspectRatio > ratioLimit) {
    // 1:3.303（青銅比）の比率以上に横を広げられないようにしている
    cropperRef.current.cropper.setCropBoxData({ height: cropBoxWidth / ratioLimit });
  }
};

export const Cropper = (props) => {
  const { rawImage, cropperRef, imageWidth, imageHeight, fixAspect, setTempImage } = props;

  const height = 400;
  const width = 400;

  return (
    <ReactCropper
      ref={cropperRef}
      src={rawImage}
      style={{ width, height }}
      aspectRatio={fixAspect ? imageWidth / imageHeight : null}
      ready={() => {
        ensureAspectRatio(cropperRef);
        setCropDimension(cropperRef, setTempImage);
      }}
      cropmove={() => ensureAspectRatio(cropperRef)}
      cropend={() => setCropDimension(cropperRef, setTempImage)}
      guides={true}
      scalable={false}
      rotatable={false}
      zoomable={false}
      viewMode={2}
    />
  );
};

import { Modal, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useClientHostName } from '../../store/hooks';

export const QRCoder = (props) => {
  const [sUrl, setsUrl] = useState(null);
  const [devUrl, setDevUrl] = useState(null);
  const { guid, user, reviewAppUrl, open, onClose } = props;
  const mo_domain = useClientHostName();

  const getUrl = React.useCallback(async () => {
    const { guid } = props;
    const urlOfHerokuEnv = `https://${mo_domain}/?guid=${guid}&account=${user.login}&id=${user.id}`;
    setDevUrl(`http://localhost:3000/?guid=${guid}&account=${user.login}&id=${user.id}`);

    const res = await fetch('https://s.ubiregi.com/post', {
      method: 'POST',
      body: JSON.stringify({ url: urlOfHerokuEnv }),
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await res.json();
    setsUrl(data.url);
  }, [props, user, mo_domain]);

  useEffect(() => {
    if (guid && user) {
      getUrl();
    }
  }, [guid, user, getUrl]);

  if (!guid) return null;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='qr-coder-modal-title'
      aria-describedby='qr-coder-modal-description'
    >
      <Paper className='list stylizedList codeList' elevation={5}>
        <div className='codeListTableNo'>{props.tableNo}</div>
        {reviewAppUrl && (
          <div>
            <a
              href={`${reviewAppUrl}/?guid=${guid}&account=${user.login}&id=${user.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Review App Link
            </a>
          </div>
        )}
        <div>
          <a href={devUrl} target='_blank' rel='noopener noreferrer'>
            開発用 Link
          </a>
        </div>
        <div>
          <a href={sUrl} target='_blank' rel='noopener noreferrer'>
            デモ用 Link
          </a>
        </div>
        {sUrl ? (
          <div>
            <QRCode value={sUrl} />
          </div>
        ) : null}
      </Paper>
    </Modal>
  );
};

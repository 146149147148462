import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch } from 'react-redux';
import { fetchFromFo, loadFoSetting } from '@order/admin/store/modules/foSetting';
import { FixedQRAgreeModal } from './FixedQRAgreeModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

export const FixedQRCodeSetting = ({ user, foSettings, functionsDomain, saved, sending, submitToFo }) => {
  const [enabled, setEnabled] = useState(!foSettings?.mo_fixed_qr);
  const [fixedQR, setFixedQR] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialStatus, setInitialStatus] = useState(true);
  const dispatch = useDispatch();
  const styles = makeStyles((theme) => ({
    modalLink: {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: 8,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    enabledQRText: {
      fontWeight: 'bold',
      display: 'flex',
      marginBottom: 13,
      color: theme.palette.success.main,
    },
    text: {
      fontSize: 17,
      lineHeight: 1.6,
    },
    textMargin1: { marginBottom: 24 },
    textMargin2: { margin: '24px 0' },
    boxMargin: { marginTop: '38px' },
    icon: {
      width: 19,
      height: 19,
      marginTop: 3,
      marginRight: 4,
    },
  }));
  const classes = styles();

  useEffect(() => {
    if (foSettings) {
      setFixedQR(!!foSettings.mo_fixed_qr);
      setEnabled(!foSettings.mo_fixed_qr);
      setInitialStatus(!foSettings.mo_fixed_qr);
    }
  }, [foSettings]);

  const submit = async (e) => {
    e.preventDefault();
    setEnabled(true);
    const newFixedQR = !fixedQR;
    sending();

    await submitToFo({
      user,
      functionsDomain,
      fixedQR: newFixedQR,
    });
    setFixedQR(newFixedQR);
    saved();
    setEnabled(!newFixedQR);
    setInitialStatus(!newFixedQR);
  };

  const fetchFoSettings = useCallback(async () => {
    const data = await fetchFromFo({ user, functionsDomain });
    dispatch(loadFoSetting(data));
  }, [dispatch, user, functionsDomain]);

  useEffect(() => {
    if (user) fetchFoSettings();
  }, [user, fetchFoSettings]);

  const handleModal = (isOpen) => {
    setIsOpenModal(isOpen);
    !fixedQR && setEnabled(isOpen);
    !isOpen && setInitialStatus(false);
  };

  const handleCanselModal = () => {
    setIsOpenModal(false);
    !initialStatus && setEnabled(false);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Typography className={`${classes.text} ${classes.textMargin1}`}>
        テーブル毎に固定でQRコードを利用する設定をします。
        <br />
        一度発行した固定QRコードは、固定QRコード利用設定の有無にかかわらず利用が可能です。
      </Typography>

      <Typography className={`${classes.text} ${classes.textMargin2}`}>
        ご利用には事前に内容のご確認と全てのユビレジ ハンディご利用端末にてバージョン2.31.0以降が必須となります。
        <br />
        <span className={classes.modalLink} onClick={() => handleModal(true)}>
          こちらから利用上の注意をご確認ください
          <KeyboardArrowRightIcon />
        </span>
      </Typography>

      <FixedQRAgreeModal
        isOpen={isOpenModal}
        handleClose={() => handleModal(false)}
        handleCancelModal={() => handleCanselModal()}
      />

      <div className={classes.boxMargin}>
        {fixedQR && (
          <Typography className={classes.enabledQRText}>
            <CheckCircleIcon className={classes.icon} />
            テーブル固定QRコードを利用中です
            <br />
            固定QRコードの利用をやめる際は、発行済みの固定QRコードを必ず回収してください。
          </Typography>
        )}
        {fixedQR ? (
          <Button variant='contained' color='secondary' disabled={enabled} onClick={submit}>
            利用をやめる
          </Button>
        ) : (
          <Button variant='contained' color='secondary' disabled={enabled} onClick={submit}>
            {initialStatus ? '利用上の注意を確認後に設定できます' : '利用を開始する'}
          </Button>
        )}
      </div>
    </form>
  );
};

import { Session, UbiregiAccount, FirebaseUser } from '../../types';

type Action = {
  type: string;
  account?: UbiregiAccount;
  user?: FirebaseUser;
  foEnabled?: boolean;
};

const LOAD_USER = 'mo-admin/session/load_user';
const LOAD_ACCOUNT = 'mo-admin/session/load_account';
const LOAD_FO_ENABLED = 'mo-admin/session/load_fo_enabled';

export const loadUser = (user: FirebaseUser): Action => {
  return {
    type: LOAD_USER,
    user,
  };
};
export const loadAccount = (account: UbiregiAccount): Action => {
  return {
    type: LOAD_ACCOUNT,
    account,
  };
};

export const loadFOEnabled = (foEnabled: boolean): Action => {
  return {
    type: LOAD_FO_ENABLED,
    foEnabled,
  };
};

export default (state: Session = {}, action: Action): Session => {
  if (action.type === LOAD_USER) {
    return { ...state, user: action.user };
  }
  if (action.type === LOAD_ACCOUNT) {
    return { ...state, account: action.account };
  }
  if (action.type === LOAD_FO_ENABLED) {
    return { ...state, foEnabled: action.foEnabled };
  }
  return state;
};

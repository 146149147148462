import { Button, Icon, Paper, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import { AdminAppBar } from '../admin-appbar';

export const HandyRequired = () => {
  const classes = useStyles();
  return (
    <>
      <AdminAppBar />
      <div className={classes.toolbar} />
      <div className={classes.paperWrapper}>
        <Paper className={classes.paper} style={{ width: '100%', maxWidth: '1000px', padding: 40 }}>
          <img src='/logo.svg' alt='ユビレジ QRオーダー' style={{ maxHeight: 50, margin: '20px auto 60px' }} />
          <Typography variant='h5' style={{ marginBottom: 20 }}>
            ユビレジ ハンディの利用登録が必要です
          </Typography>
          <Typography>
            ユビレジ QRオーダー&決済は、ユビレジハンディと組み合わせて使える店内モバイルオーダー・決済システムです。
            <br />
            ご利用にあたっては、ユビレジ ハンディをお申し込みいただく必要があります。
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            style={{ maxWidth: '480px', margin: '40px auto' }}
            size='large'
            endIcon={<Icon>arrow_forward_ios</Icon>}
            href='https://ubiregi.com/ja/handy'
          >
            ユビレジ ハンディの詳細・お申し込みはこちらから
          </Button>
        </Paper>
      </div>
    </>
  );
};

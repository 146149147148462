import React, { useState, useEffect } from 'react';
import { Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useOfferTypeMutator } from '@order/admin/store/hooks/firestore';
import { useSaveNotify } from '@order/admin/store/hooks/useSaveNotify';

export const ShowAmount = ({ offerType, selfPayment }) => {
  const { saved } = useSaveNotify();
  const { update } = useOfferTypeMutator();
  const [isShowAmount, setIsShowAmount] = useState(false);
  const [hiddenItemPrice, setHiddenItemPrice] = useState(false);

  useEffect(() => {
    setIsShowAmount(!!offerType.is_show_amount);
    setHiddenItemPrice(!!offerType.hiddenItemPrice);
  }, [offerType]);

  const save = async () => {
    await update({
      id: offerType.id,
      payload: { is_show_amount: isShowAmount, hiddenItemPrice, updateDate: new Date() },
    });
    saved();
  };

  return (
    <>
      <Typography variant='h5' style={{ marginTop: 40 }}>
        商品金額の表示
      </Typography>
      <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
        注文確認画面「カート」と注文履歴画面で商品金額を表示することができます。
      </Typography>
      <div className='account-text-field__container'>
        <FormControlLabel
          control={
            <Checkbox
              checked={!hiddenItemPrice}
              onChange={() => setHiddenItemPrice(!hiddenItemPrice)}
              name='darkMode'
              color='primary'
              value={!hiddenItemPrice}
            />
          }
          label='注文確認画面と履歴画面で商品金額を表示する'
        />
      </div>
      <Typography variant='h5' style={{ marginTop: 40 }}>
        合計金額の表示
      </Typography>
      <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
        注文された商品の合計金額を「注文履歴 &gt; お会計」画面に表示することができます。
      </Typography>
      {selfPayment ? (
        <Typography color='primary' variant='subtitle1' style={{ fontWeight: 'bold', marginBottom: 20 }}>
          「決済機能」が有効のため、合計金額は常に表示されます。
        </Typography>
      ) : (
        <div className='account-text-field__container'>
          <FormControlLabel
            control={
              <Checkbox
                checked={isShowAmount}
                onChange={() => setIsShowAmount(!isShowAmount)}
                name='darkMode'
                color='primary'
                value={isShowAmount}
              />
            }
            label='注文済の商品の合計金額を表示する'
          />
        </div>
      )}
      <div className='account-text-field__container' style={{ marginTop: 30 }}>
        <Button size='large' type='submit' variant='contained' color='secondary' onClick={save}>
          保存
        </Button>
      </div>
    </>
  );
};

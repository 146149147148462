import { firebaseFirestore } from '../../firebase';
import { useUser } from './index';

export const useSaveOfferTypeToFlickOrder = () => {
  const user = useUser();
  const db = firebaseFirestore();

  return async () => {
    const accountRef = db.collection('accounts').doc(user.uid);
    await accountRef.update({ sendOfferTypesRequestedDate: new Date() });
  };
};

import { firebaseFirestore } from '../../firebase';
import { OfferType } from '../../types';

type Action = {
  type: string;
  offerTypes?: OfferType[];
};
type State = {
  offerTypes?: OfferType[];
};

const LOAD_OFFER_TYPES = 'mo-admin/offerTypes/load_offer_types';

export const loadOfferTypes = (offerTypes: OfferType[]): Action => ({ type: LOAD_OFFER_TYPES, offerTypes });

const initialState = {};

const Reducer = (state: State = initialState, action: Action): State => {
  if (action.type === LOAD_OFFER_TYPES) {
    if (action.offerTypes) {
      return { ...state, offerTypes: action.offerTypes.slice().sort((a, b) => parseInt(a.order) - parseInt(b.order)) };
    }
  }

  return state;
};

export default Reducer;

const subscriptions = [];

export const subscribeOfferTypes = (accountId: string, callback: (arg0: any) => {}) => {
  subscriptions.push(
    firebaseFirestore()
      .collection('accounts')
      .doc(accountId)
      .collection('offerTypes')
      .onSnapshot((snapshot) =>
        callback(
          snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          }),
        ),
      ),
  );
};

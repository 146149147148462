import Papa from 'papaparse';
import { useMenuItems, useSnackbar } from '../../store/hooks';
import { useFileDownloadUpload, useSaveFile } from './file';
import { useMenuItemMutator } from '../../store/hooks/firestore';
import { useXlsx } from './xlsx';

type Props = {
  itemsToCSV: () => Promise<string>;
  importItems: () => Promise<void>;
};
export const useItemsBackupRestore = (isAllItemsDownload: boolean): Props => {
  const { parseCSV, openFile } = useFileDownloadUpload();
  const { parseXlsx } = useXlsx();
  const { showSnackbar } = useSnackbar();
  const items = useMenuItems();
  const { update, updateAllOfferTypeSnapshot } = useMenuItemMutator();
  const saveFile = useSaveFile();

  const itemsToCSV = async () => {
    const list = isAllItemsDownload
      ? items.filter(({ item }) => item.category_id ?? false)
      : items
          .filter(({ item }) => item.category_id ?? false)
          .filter((i) => i.image || i.description || i.name || i.item.description);
    const array = [['SKU', 'カテゴリ', '商品名', 'QRオーダー表示名', '画像URL', '説明文']];
    list.forEach((item) => {
      array.push([
        item.item.sku,
        item.item.category_name,
        item.item.name,
        item.name || item.item.name,
        item.image,
        item.description || item.item.description,
      ]);
    });
    return Papa.unparse(array);
  };

  const parseCSVWithEncodingAndValidate = async (file, encoding) => {
    const data = await parseCSV(file, encoding);
    if (data[0] && data[0].SKU && !data[0]['QRオーダー表示名']) {
      return null;
    }
    return data;
  };

  const parseCSVWithMultipleEncoding = async (file) => {
    return (
      (await parseCSVWithEncodingAndValidate(file, 'Shift-JIS')) ||
      (await parseCSVWithEncodingAndValidate(file, 'UTF-8')) ||
      (await parseCSVWithEncodingAndValidate(file, 'UTF-16'))
    );
  };
  const importItems = async () => {
    const file = await openFile(
      '.csv, text/csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    const data = file.name.toLocaleLowerCase().endsWith('.csv')
      ? await parseCSVWithMultipleEncoding(file)
      : await parseXlsx(file);

    showSnackbar('復元を開始します、ブラウザーを閉じないでください');
    for (const d of data) {
      const item = items.find((i) => i.item.sku === d.SKU);
      if (item) {
        const payload: { image?: string; description?: string; name?: string } = {};
        payload.image = d['画像URL'] || null;
        payload.description = d['説明文'] || null;
        payload.name = d['QRオーダー表示名'] || null;
        await update({ id: item.item.id, payload });
      }
    }
    await updateAllOfferTypeSnapshot();
    await saveFile(file);
    showSnackbar('復元が完了しました');
  };

  return { itemsToCSV, importItems };
};

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link,
  Typography,
  Icon,
  Chip,
} from '@mui/material';
import arrayMove from 'array-move';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useOfferTypeMutator } from '../../store/hooks/firestore';
import { SortableMenuRow } from './SortableMenuRow';
import { SubHeader } from './SubHeader';
import { useStyles } from './useStyles';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { DragDrop } from '@order/admin/components/DragDrop';

const useToggle = () => {
  const [flag, set] = useState(false);
  const toggle = () => set(!flag);
  return [flag, toggle];
};

const MenuDialog = ({ open, offerType, toggle, menuList }) => {
  const { update } = useOfferTypeMutator();
  const { root } = useStyles();
  const { saved } = useSaveNotify();

  const [selected, select] = useState({});

  useEffect(() => {
    document.title = `${offerType.name}の編集 - メニューブック - ユビレジQRオーダー&決済管理画面`;
    if (offerType) {
      const obj = {};
      (offerType.menuIds || []).forEach((k) => (obj[k] = true));
      select(obj);
    } else {
      select({});
    }
  }, [offerType]);

  const save = async () => {
    const menuIds = Object.keys(selected).filter((k) => selected[k]);
    await update({
      id: offerType.id,
      payload: { menuIds, updateDate: new Date() },
      updateSnapshot: [{ id: offerType.id }],
    });
    saved();
    toggle();
  };

  const onChange = (id) => select({ ...selected, [id]: !selected[id] });

  return (
    <Dialog fullWidth open={open} maxWidth='md' PaperProps={{ style: { padding: '10px 0', margin: 20 } }}>
      <DialogTitle>
        <strong>{offerType.name}</strong> で表示するメニューを選択
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {menuList.map((m, i) => {
            const labelId = `menu-list-item-${m.id}-label`;
            return (
              <React.Fragment key={m.id}>
                <ListItem role='listitem' button onClick={() => onChange(m.id)}>
                  <Checkbox
                    color='primary'
                    checked={!!selected[m.id]}
                    onChange={() => onChange(m.id)}
                    value={m.id}
                    inputProps={{ 'aria-labelledby': labelId }}
                    style={{ width: 50, height: 50, marginRight: 10 }}
                  />
                  <ListItemAvatar>
                    <Avatar variant='square' className={root} src={m.image} alt={m.name}>
                      {m.name.substr(0, 4)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={m.name}
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={m.offerTypes?.map((o) => (
                      <Chip
                        key={o.name}
                        style={{ marginRight: 5 }}
                        size='small'
                        label={
                          <>
                            <Icon
                              style={{
                                fontSize: '1.2em',
                                display: 'inline-flex',
                                verticalAlign: 'middle',
                                marginTop: -3,
                                marginRight: 3,
                                color: '#656565',
                              }}
                            >
                              menu_book
                            </Icon>
                            {o.name}
                          </>
                        }
                      />
                    ))}
                  />
                </ListItem>
                {i + 1 < menuList.length && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions style={{ padding: '20px 20px 10px 0' }}>
        <Button variant='outlined' color='secondary' onClick={toggle}>
          キャンセル
        </Button>
        <Button variant='contained' color='secondary' autoFocus onClick={save}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useSelectMenus = (menuList, menuIds) => {
  const [selectedMenus, setSelectedMenus] = useState([]);
  const initMenuList = useMemo(
    () => (menuIds && menuIds.map((id) => menuList.find((m) => m.id === id)).filter((m) => !!m)) || [],
    [menuIds, menuList],
  );
  useEffect(() => {
    if (menuIds) {
      setSelectedMenus(initMenuList);
    }
  }, [setSelectedMenus, menuIds, initMenuList]);

  const sortMenu = useCallback(
    async ({ source, destination }) => {
      const moved = arrayMove(selectedMenus, source.index, destination.index);
      setSelectedMenus(moved);
    },
    [selectedMenus, setSelectedMenus],
  );

  const sortCancel = useCallback(() => setSelectedMenus(initMenuList), [initMenuList]);

  return [selectedMenus, sortMenu, sortCancel];
};

export const SelectMenu = ({ offerType, menuList }) => {
  const [selectMenuIds, setSelectMenuId] = useState([]);
  const [open, toggle] = useToggle();
  const [sort, toggleSort] = useToggle();
  const [selectedMenus, sortMenu, sortCancel] = useSelectMenus(menuList, offerType.menuIds);
  const { update } = useOfferTypeMutator();

  const SelectButton = () => {
    return (
      <Button variant='outlined' color='secondary' size='large' onClick={toggle}>
        <Typography variant='body1'>メニューを選択</Typography>
      </Button>
    );
  };

  const sortSave = async () => {
    const menuIds = selectedMenus.map((menu) => menu.id);
    await update({
      id: offerType.id,
      payload: { menuIds, updateDate: new Date() },
      updateSnapshot: [{ id: offerType.id }],
    });
    toggleSort();
  };

  if (!menuList.length) {
    return (
      <>
        <Typography variant='body1' gutterBottom>
          まだメニューが作成されていません。
        </Typography>
        <Typography variant='body1'>
          <Link component={RouterLink} to='/c/menu'>
            メニューの設定
          </Link>
          からメニューを作成してください。
        </Typography>
      </>
    );
  }

  return (
    <>
      <MenuDialog open={open} offerType={offerType} toggle={toggle} menuList={menuList} />
      {selectedMenus.length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ marginTop: 20 }}>
              <strong>{offerType.name}</strong> メニューブックに表示中のメニュー
            </Typography>
            {!sort && <SelectButton />}
          </Box>
          <List
            subheader={
              <SubHeader
                offerType={offerType}
                selectedMenus={selectedMenus}
                selectMenuIds={selectMenuIds}
                setSelectMenuId={setSelectMenuId}
                sort={sort}
                sortCancel={() => {
                  sortCancel();
                  toggleSort();
                }}
                sortSave={sortSave}
              />
            }
          >
            <DragDrop onDragEnd={sortMenu} items={selectedMenus} enabled={sort}>
              {(m, i) => (
                <SortableMenuRow
                  id={m.id}
                  menu={m}
                  isNotLast={i + 1 < selectedMenus.length}
                  checked={selectMenuIds.includes(m.id)}
                  handle={selectedMenus.length > 1 && sort}
                  sort={sort}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectMenuId(selectMenuIds.concat(m.id));
                    } else {
                      setSelectMenuId(selectMenuIds.filter((id) => id !== m.id));
                    }
                  }}
                />
              )}
            </DragDrop>
          </List>
        </>
      ) : (
        <>
          <Typography variant='body1' gutterBottom style={{ margin: '40px 0' }}>
            このメニューブック（<strong>{offerType.name}</strong>）に表示するメニューを選択してください。
          </Typography>
          <SelectButton />
        </>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { List as MuiList, Typography, Checkbox, FormControlLabel, Divider, Box, Paper, Icon } from '@mui/material';
import { Row } from './Row';
import { useMergedMenuList } from '@order/admin/store/hooks';
import { useMenuListMutator } from '@order/admin/store/hooks/firestore';
import { MenuNameForm } from './MenuNameForm';
import { openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { HelpPopover } from './HelpPopover';
import { CustomButton } from '../Button';
import { withStyles } from '@mui/styles';
import { AlertInUnavailability } from '../AlertInUnavailability';

const SubHeader = ({ onChange, deleteMenu, deleting, allSelected, itemChecked }) => {
  return (
    <>
      <Typography variant='h6'>作成済みのメニュー</Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={allSelected}
                onChange={onChange}
                indeterminate={!allSelected && itemChecked}
                disabled={deleting}
                data-test-id='all-check'
                style={{ width: 50, height: 50, marginRight: 10 }}
              />
            }
            label={<Typography>すべて選択</Typography>}
          />
        </Box>
        <Box>
          <CustomButton disabled={deleting || !itemChecked} variant='outlined' color='error' onClick={deleteMenu}>
            メニューを削除
          </CustomButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const MenuList = withStyles({ fullWidth: { width: '50%' } })(({ classes }) => {
  const menuList = useMergedMenuList().sort((a, b) => (a.updateDate?.toDate() < b.updateDate?.toDate() ? 1 : -1));
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'メニュー - ユビレジQRオーダー&決済管理画面';
  }, []);
  const savedAction = (name) => {
    dispatch(setMessageToSnackbar(`「${name}」メニューを作成しました。この画面からメニューの情報を編集してください。`));
    dispatch(openSnackbar());
  };
  const deletedAction = () => {
    dispatch(setMessageToSnackbar('削除しました。'));
    dispatch(openSnackbar());
  };
  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '30px 20px', margin: '20px', minHeight: 300 }}>
        <Typography variant='h4' gutterBottom>
          <Icon style={{ marginRight: 5 }}>library_books</Icon>メニュー
          <HelpPopover />
        </Typography>
        <Typography paragraph>メニューを作成して、表示するページを選択してください。</Typography>
        <MenuNameForm classes={classes} savedAction={savedAction} />
        <List menuList={menuList} deletedAction={deletedAction} />
      </Paper>
    </>
  );
});

const List = ({ menuList, deletedAction }) => {
  const { destroy, updateAllOfferTypeSnapshot } = useMenuListMutator();
  const [selectMenuIds, setSelectMenuId] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const deleteMenu = async () => {
    if (window.confirm('選択したメニューを削除しますか')) {
      setDeleting(true);
      await Promise.all(
        selectMenuIds.map(async (id) => {
          await destroy({ id });
        }),
      );
      await updateAllOfferTypeSnapshot();
      deletedAction();
      setDeleting(false);
      setSelectMenuId([]);
    }
  };

  if (!menuList.length)
    return (
      <Paper elevation={0} style={{ textAlign: 'center', padding: 40 }}>
        <Typography variant='h5' gutterBottom style={{ fontWeight: 600, color: '#757575' }}>
          メニューの作成をしましょう
        </Typography>
        <Typography variant='subtitle2'>
          メニューを作成したら、ページを分類してまとめていきます。
          <br />
          メニュー内のページはお好みの順番に並び替えることができます。
          <br />
        </Typography>
        <img src='/tutorial-menu.svg' alt='' style={{ marginTop: 40, width: 250 }} />
      </Paper>
    );

  return (
    <MuiList
      subheader={
        <SubHeader
          deleting={deleting}
          deleteMenu={deleteMenu}
          allSelected={selectMenuIds.length === menuList.length}
          itemChecked={!!selectMenuIds.length}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectMenuId(menuList.map(({ id }) => id));
            } else {
              setSelectMenuId([]);
            }
          }}
        />
      }
    >
      {menuList.map((row, i) => (
        <React.Fragment key={i}>
          <Row
            menu={row}
            pageTotal={row.pageIds?.length || 0}
            key={row.id}
            checked={selectMenuIds.includes(row.id)}
            disabled={deleting}
            onChange={(value) => {
              if (value) {
                setSelectMenuId(selectMenuIds.concat(row.id));
              } else {
                setSelectMenuId(selectMenuIds.filter((id) => id !== row.id));
              }
            }}
          />
          {i + 1 < menuList.length && <Divider />}
        </React.Fragment>
      ))}
    </MuiList>
  );
};

export default MenuList;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, List, ListItem, ListItemAvatar, Avatar, Icon, Typography, Divider, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    color: '#444',
    fontSize: '1em',
    lineHeight: '1em',
    paddingTop: '.1em',
    paddingBottom: '.1em',
  },
}));

export const SetupList = ({ menuItems, pages, menuList, offerTypes, enabledOfferTypes }) => {
  const setupCounter =
    (menuItems.length && 1) +
    (pages.length && 1) +
    (menuList.length && 1) +
    (offerTypes.length && 1) +
    (enabledOfferTypes.length && 1);

  const classes = useStyles();

  const IconItem = () => <Icon className={classes.icon}>restaurant</Icon>;
  const IconPage = () => <Icon className={classes.icon}>description</Icon>;
  const IconMenu = () => <Icon className={classes.icon}>library_books</Icon>;
  const IconMenuBook = () => <Icon className={classes.icon}>menu_book</Icon>;

  const ListItemLink = ({ children, to, condition }) => {
    return !condition.length ? (
      <ListItem button component={RouterLink} to={to}>
        {children}
      </ListItem>
    ) : (
      <ListItem>{children}</ListItem>
    );
  };

  return (
    <Paper style={{ padding: 20, margin: 20, minHeight: 300 }}>
      <Typography variant='h4' gutterBottom>
        <HomeIcon />
        トップページ
      </Typography>
      <Typography variant='h5'>はじめに</Typography>
      <Typography>
        初期設定を完了させることで、「ユビレジ QRオーダー&決済」を使った注文を受けることができます。
      </Typography>
      <Typography>
        設定項目は<strong>①商品</strong>、<strong>②ページ</strong>、<strong>③メニュー</strong>、
        <strong>④メニューブック</strong>、<strong>⑤メニューブックの公開</strong>です。
      </Typography>
      <div style={{ marginBottom: 20 }} />
      <Typography variant='h5' data-test-id='letsinitialize'>
        初期設定をしましょう
      </Typography>
      <Typography variant='subtitle1'>5ステップ中 {setupCounter}ステップを完了</Typography>
      <List>
        <ListItemLink to='/c/items' condition={menuItems}>
          <Box display='flex' style={{ width: '100%' }}>
            <Box>
              {!menuItems.length ? (
                <Avatar>1</Avatar>
              ) : (
                <Avatar className={classes.primary}>
                  <Icon>check</Icon>
                </Avatar>
              )}
            </Box>
            <Box flexDirection='column' justifyContent='center' display='flex' style={{ paddingLeft: 15 }}>
              <Box>
                <Typography>
                  ユビレジで商品を作成し、
                  <strong>
                    <IconItem />
                    商品
                  </strong>
                  ページから同期します
                </Typography>
              </Box>
              {!menuItems.length && (
                <Box style={{ marginTop: 5 }}>
                  <Typography color='primary' variant='body2'>
                    商品ページへ移動
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <ListItemAvatar style={{ padding: '8px 15px', minWidth: 180, textAlign: 'center' }}>
            <img alt='' src='/tutorial-items.svg' height='120' />
          </ListItemAvatar>
        </ListItemLink>
        <Divider component='li' />
        <ListItemLink to='/c/page' condition={pages}>
          <Box display='flex' style={{ width: '100%' }}>
            <Box>
              {!pages.length ? (
                <Avatar>2</Avatar>
              ) : (
                <Avatar className={classes.primary}>
                  <Icon>check</Icon>
                </Avatar>
              )}
            </Box>
            <Box flexDirection='column' justifyContent='center' display='flex' style={{ paddingLeft: 15 }}>
              <Box>
                <Typography>
                  商品を掲載する
                  <strong>
                    <IconPage />
                    ページ
                  </strong>
                  を作成・設定します
                </Typography>
              </Box>
              {!pages.length && (
                <Box style={{ marginTop: 5 }}>
                  <Typography color='primary' variant='body2'>
                    ページの設定へ移動
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <ListItemAvatar style={{ padding: '8px 15px', minWidth: 180, textAlign: 'center' }}>
            <img alt='' src='/tutorial-page-top.svg' height='120' />
          </ListItemAvatar>
        </ListItemLink>
        <Divider component='li' />
        <ListItemLink to='/c/menu' condition={menuList}>
          <Box display='flex' style={{ width: '100%' }}>
            <Box>
              {!menuList.length ? (
                <Avatar>3</Avatar>
              ) : (
                <Avatar className={classes.primary}>
                  <Icon>check</Icon>
                </Avatar>
              )}
            </Box>
            <Box flexDirection='column' justifyContent='center' display='flex' style={{ paddingLeft: 15 }}>
              <Box>
                <Typography>
                  ページをまとめるための
                  <strong>
                    <IconMenu />
                    メニュー
                  </strong>
                  を作成・設定します
                </Typography>
              </Box>
              {!menuList.length && (
                <Box style={{ marginTop: 5 }}>
                  <Typography color='primary' variant='body2'>
                    メニューの設定へ移動
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <ListItemAvatar style={{ padding: '8px 15px', minWidth: 180, textAlign: 'center' }}>
            <img alt='' src='/tutorial-menu.svg' height='120' />
          </ListItemAvatar>
        </ListItemLink>
        <Divider component='li' />
        <ListItemLink to='/c/offertype' condition={offerTypes}>
          <Box display='flex' style={{ width: '100%' }}>
            <Box>
              {!offerTypes.length ? (
                <Avatar>4</Avatar>
              ) : (
                <Avatar className={classes.primary}>
                  <Icon>check</Icon>
                </Avatar>
              )}
            </Box>
            <Box flexDirection='column' justifyContent='center' display='flex' style={{ paddingLeft: 15 }}>
              <Box>
                <Typography>
                  来店されたお客様が注文に使用する
                  <strong>
                    <IconMenuBook />
                    メニューブック
                  </strong>
                  を作成・設定します
                </Typography>
              </Box>
              {!offerTypes.length && (
                <Box style={{ marginTop: 5 }}>
                  <Typography color='primary' variant='body2'>
                    メニューブックの設定へ移動
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <ListItemAvatar style={{ padding: '8px 15px', minWidth: 180, textAlign: 'center' }}>
            <img alt='' src='/tutorial-menubook.svg' height='120' />
          </ListItemAvatar>
        </ListItemLink>
        <Divider component='li' />
        <ListItemLink to='/c/offertype' condition={enabledOfferTypes}>
          <Box display='flex' style={{ width: '100%' }}>
            <Box>
              {!enabledOfferTypes.length ? (
                <Avatar>5</Avatar>
              ) : (
                <Avatar className={classes.primary}>
                  <Icon>check</Icon>
                </Avatar>
              )}
            </Box>
            <Box flexDirection='column' justifyContent='center' display='flex' style={{ paddingLeft: 15 }}>
              <Box>
                <Typography>
                  <strong>
                    <IconMenuBook />
                    メニューブック
                  </strong>
                  を公開します。公開すると「ユビレジ
                  ハンディ」内で使用可能になり、メニューブックへアクセスするためのQRコードが発行できます。
                </Typography>
              </Box>
              {!enabledOfferTypes.length && (
                <Box style={{ marginTop: 5 }}>
                  <Typography color='primary' variant='body2'>
                    メニューブックの設定へ移動
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <ListItemAvatar style={{ padding: '8px 15px', minWidth: 180, textAlign: 'center' }}>
            <img alt='' src='/tutorial-menubook-publish.svg' height='120' />
          </ListItemAvatar>
        </ListItemLink>
        <Divider component='li' />
      </List>
      <div style={{ marginBottom: 20 }} />
    </Paper>
  );
};

import { Box, List, Typography, Button } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AddItemDialog } from './AddItemDialog';
import { SortableRow } from './SortableRow';
import { SubHeader } from './SubHeader';
import { useMenuItems } from '../../store/hooks';
import arrayMove from 'array-move';
import { usePageMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { DragDrop } from '@order/admin/components/DragDrop';

const useToggle = () => {
  const [flag, set] = useState(false);
  const toggle = () => set(!flag);
  return [flag, toggle];
};

const useSelectedItems = (itemIds) => {
  const menuItems = useMenuItems();
  const [selectedItems, setSelectedItems] = useState([]);
  const initMenuItems = useMemo(
    () =>
      (itemIds &&
        itemIds
          .map((id) => {
            const m = menuItems.find((i) => i.item.id === id && i.item.category_id);
            return m ? { id: m.item.id, ...m } : null;
          })
          .filter((i) => !!i)) ||
      [],
    [menuItems, itemIds],
  );
  useEffect(() => {
    if (itemIds) {
      setSelectedItems(initMenuItems);
    }
  }, [setSelectedItems, itemIds, initMenuItems]);

  const sortItem = useCallback(
    async ({ source, destination }) => {
      const moved = arrayMove(selectedItems, source.index, destination.index);
      setSelectedItems(moved);
    },
    [selectedItems, setSelectedItems],
  );

  const sortCancel = useCallback(() => setSelectedItems(initMenuItems), [initMenuItems]);

  return [selectedItems, sortItem, sortCancel];
};

export const SelectItem = ({ page }) => {
  const [selectItemIds, setSelectItemId] = useState([]);
  const [open, toggle] = useToggle();
  const [sort, toggleSort] = useToggle();
  const [selectedItems, sortItem, sortCancel] = useSelectedItems(page.itemIds);
  const { update } = usePageMutator();

  const { saved } = useSaveNotify();

  useEffect(() => {
    document.title = `${page.name}の編集 - ページ - ユビレジQRオーダー&決済管理画面`;
  }, [page]);

  const SelectButton = () => {
    return (
      <Button variant='outlined' color='secondary' size='large' onClick={toggle}>
        <Typography variant='body1'>商品を選択</Typography>
      </Button>
    );
  };

  const saveItems = async (items) => {
    await update({
      id: page.id,
      payload: { itemIds: items.map((item) => item.id), updateDate: new Date() },
      updateSnapshot: page.offerTypes,
    });
    saved();
  };

  const sortSave = async () => {
    const itemIds = selectedItems.map((item) => item.id);
    await update({ id: page.id, payload: { itemIds, updateDate: new Date() }, updateSnapshot: page.offerTypes });
    toggleSort();
  };

  return (
    <>
      <AddItemDialog
        open={open}
        handleClose={toggle}
        ok={(items) => {
          saveItems(items);
        }}
        page={page}
        selectedItems={selectedItems}
      />
      {selectedItems.length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ marginTop: 20 }}>
              <strong>{page.name}</strong> ページに表示中の商品
            </Typography>
            {!sort && <SelectButton />}
          </Box>
          <List
            component='div'
            disablePadding
            subheader={
              <SubHeader
                page={page}
                selectedItems={selectedItems}
                selectItemIds={selectItemIds}
                setSelectItemId={setSelectItemId}
                sort={sort}
                sortSave={sortSave}
                sortCancel={() => {
                  sortCancel();
                  toggleSort();
                }}
              />
            }
          >
            <DragDrop onDragEnd={sortItem} items={selectedItems} enabled={sort}>
              {(item, i) => (
                <SortableRow
                  id={item.id}
                  item={item}
                  checked={selectItemIds.includes(item.id)}
                  handle={selectedItems.length > 1 && sort}
                  sort={sort}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectItemId(selectItemIds.concat(item.id));
                    } else {
                      setSelectItemId(selectItemIds.filter((id) => id !== item.id));
                    }
                  }}
                  isNotLast={i + 1 < selectedItems.length}
                />
              )}
            </DragDrop>
          </List>
        </>
      ) : (
        <>
          <Typography data-testid='empty-item-message' variant='body1' gutterBottom style={{ margin: '40px 0' }}>
            このページ（<strong>{page.name}</strong>）に表示する商品を選択してください。
          </Typography>
          <SelectButton />
        </>
      )}
    </>
  );
};

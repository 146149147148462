import React from 'react';
import { useStyles } from './useStyles';
import { ListItem, Avatar, Typography, ListItemText, Divider } from '@mui/material';
import { FormattedPrice } from '../Price';

export const PageDetailDialogContentRow = ({ item, isNotLast }) => {
  const { root } = useStyles();

  return (
    <div>
      <ListItem dense>
        <Avatar className={root} variant='square' src={item.image} alt={item.name}>
          {item.name.substr(0, 5)}
        </Avatar>
        <ListItemText>
          <Typography variant='body1'>{item.name}</Typography>
          <Typography variant='body1'>
            <FormattedPrice price={item.price} />
          </Typography>
        </ListItemText>
      </ListItem>
      {isNotLast && <Divider />}
    </div>
  );
};

import { Icon, IconButton, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';

export const HelpPopover = ({ name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} color='primary' onClick={handleClick} size='large'>
        <Icon>help</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {name === 'top' && (
          <div style={{ maxWidth: 450, padding: 24 }}>
            <Typography paragraph>
              <strong>メニューブック</strong>は<strong>メニュー</strong>をひとまとめにしたものです。
            </Typography>
            <Typography paragraph>
              設定して公開すると、来店されたお客様がモバイル端末を使い、<strong>メニューブック</strong>
              の内容を見て注文できるようになります。
            </Typography>
            <Typography paragraph>
              また、飲み放題付き・カフェメニューなど複数の<strong>メニューブック</strong>
              を設定することで、テーブル毎に異なる<strong>メニューブック</strong>が提示できます。
            </Typography>
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <img src='/tutorial-menubook.svg' alt='' style={{ height: 120 }} />
            </div>
          </div>
        )}

        {name === 'disabledOfferType' && (
          <div style={{ maxWidth: 400, padding: 24 }}>
            <Typography paragraph>
              非公開のメニューブックは「ユビレジ ハンディ」側に<strong>表示されない状態</strong>です。
            </Typography>
            <Typography paragraph style={{ marginBottom: 0 }}>
              公開する場合はメニューブックを選択し<strong>「メニューブックを公開」</strong>を押してください。 <br />
              「ユビレジ ハンディ」側に表示され、<strong>メニューブックの選択・QRコードの発行が可能</strong>となります。
            </Typography>
          </div>
        )}

        {name === 'enabledOfferType' && (
          <div style={{ maxWidth: 400, padding: 24 }}>
            <Typography paragraph style={{ marginBottom: 0 }}>
              複数のメニューブックが公開されている場合は、<strong>一番上のものが着席時のデフォルト</strong>
              として使用されます。
            </Typography>
          </div>
        )}
      </Popover>
    </>
  );
};

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState, useEffect, useMemo } from 'react';
import { FormattedPrice } from '../Price';
import { useStyles } from './useStyles';
import { useCategories, useMenuItems } from '../../store/hooks';

const CategoryRow = ({ category, selectedItems, selectItem, nameOnly }) => {
  const { nested, root } = useStyles();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const onChange = (item) => {
    if (!selectedItems.find((selected) => selected.id === item.id)) {
      selectItem(selectedItems.concat(item));
    } else {
      selectItem(selectedItems.filter((selected) => selected.id !== item.id));
    }
  };
  const allSelected = category.items.every((item) => !!selectedItems.find((selected) => selected.id === item.id));
  const partialSelected = !!(
    !allSelected && category.items.find((item) => !!selectedItems.find((selected) => selected.id === item.id))
  );

  return (
    <>
      <ListItem dense button onClick={toggle}>
        <Checkbox
          inputProps={{ 'data-testid': category.name }}
          indeterminate={partialSelected}
          checked={allSelected}
          color='primary'
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            if (e.target.checked) {
              selectItem(
                selectedItems.concat(
                  category.items.filter((item) => !selectedItems.find((selectedItem) => selectedItem.id === item.id)),
                ),
              );
              return;
            }
            const filtered = selectedItems.filter(
              (selected) => !category.items.find((item) => selected.id === item.id),
            );
            selectItem(filtered);
          }}
          style={{ width: 50, height: 50, marginRight: 10 }}
        />
        <ListItemText>
          <Typography variant='subtitle1'>{category.name}</Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}>
        <List component='div' disablePadding>
          {open &&
            category.items.map((item, i) => (
              <React.Fragment key={i}>
                <ListItem button dense className={nested} onClick={() => onChange(item)}>
                  <Checkbox
                    checked={!!selectedItems.find((selected) => selected.id === item.id)}
                    color='primary'
                    onChange={() => onChange(item)}
                    style={{ width: 50, height: 50, marginRight: 10 }}
                  />
                  {!nameOnly && (
                    <Avatar
                      className={root}
                      variant='square'
                      src={item.image}
                      alt={item.qro_name || item.name}
                      style={{ height: 50 }}
                    >
                      {(item.qro_name || item.name).substr(0, 5)}
                    </Avatar>
                  )}
                  <ListItemText
                    primary={<Typography style={{ fontSize: '1rem' }}>{item.qro_name || item.name}</Typography>}
                    secondary={
                      !nameOnly && (
                        <>
                          <Typography variant='subtitle2' style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '.75rem' }}>
                            {item.name}
                          </Typography>
                          <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                            SKU: {item.sku}
                          </Typography>
                          <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                            カテゴリ: {item.category_name}
                          </Typography>
                          <Typography variant='caption' style={{ fontSize: '.875rem' }}>
                            <FormattedPrice price={item.price} />
                          </Typography>
                        </>
                      )
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
                {i + 1 < category.items.length && <Divider />}
              </React.Fragment>
            ))}
        </List>
      </Collapse>
    </>
  );
};
export const useCategoryMappedItem = () => {
  const categories = useCategories();
  const menuItems = useMenuItems();

  const categoryMappedItem = useMemo(() => {
    return categories
      .map(({ category }) => {
        const items = menuItems
          .filter(({ item }) => {
            return item.category_id === category.id;
          })
          .map(({ item, image, name }) => {
            return { ...item, image: image || '', qro_name: name };
          });
        return { ...category, items: items };
      })
      .filter((category) => category.items.length);
  }, [categories, menuItems]);

  return categoryMappedItem;
};

export const AddItemDialog = ({ selectedItems: initialSelectedItems, open, handleClose, page = [], ok }) => {
  const [selectedItems, selectItem] = useState([]);
  useEffect(() => {
    selectItem(initialSelectedItems);
  }, [initialSelectedItems]);
  return (
    <Dialog fullWidth open={open} maxWidth='md' PaperProps={{ style: { padding: '10px 0 20px' } }}>
      <DialogTitle>
        <strong>{page.name}</strong> で表示する商品を選択
      </DialogTitle>
      <DialogContent dividers>
        <CategoryAndItemContent selectItem={selectItem} selectedItems={selectedItems} />
      </DialogContent>
      <DialogActions style={{ padding: '20px 20px 0 0' }}>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          キャンセル
        </Button>
        <Button
          variant='contained'
          color='secondary'
          autoFocus
          onClick={() => {
            ok(selectedItems);
            handleClose();
          }}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function CategoryAndItemContent({ selectItem, selectedItems = [], nameOnly = false }) {
  const { itemList } = useStyles();
  const categories = useCategoryMappedItem();
  return (
    <Box display='flex' flexDirection='column' className={itemList}>
      <List>
        {categories.map((category, i) => {
          return (
            <React.Fragment key={i}>
              <CategoryRow
                nameOnly={nameOnly}
                category={category}
                selectItem={selectItem}
                selectedItems={selectedItems}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

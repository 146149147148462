import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Typography } from '@mui/material';
import { AddPhotoAlternate } from '@mui/icons-material';

export const DropZone = (props) => {
  const { image, setRawImage } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const reader = new FileReader(file);
      reader.readAsArrayBuffer(file);
      reader.onload = async ({ target }) => {
        setRawImage(target.result);
      };
    },
    [setRawImage],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const styleBackground = image
    ? {
        backgroundImage: `url(${image})`,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
      }
    : {};

  return (
    <>
      <Typography style={{ marginTop: 20 }}>①店舗ロゴ画像</Typography>
      <Paper
        elevation={3}
        {...getRootProps()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0',
          padding: '30px',
          width: '300px',
          height: '300px',
        }}
      >
        <div style={styleBackground}>
          <input data-testid={'dropzone'} {...getInputProps()} accept='image/png,image/jpeg' />
          {!image && (
            <div style={{ color: '#757575', textAlign: 'center' }}>
              <AddPhotoAlternate style={{ fontSize: 100, color: '#ccc' }} />
              <Typography style={{ fontSize: '0.9rem', paddingTop: 20 }}>①店舗ロゴ画像をアップロード</Typography>
              <Typography variant='caption'>対応ファイル形式：JPG, PNG</Typography>
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

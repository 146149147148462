// AppBar を実装したもの
// ログイン時と非ログイン時で別のものを出す
import React from 'react';
import clsx from 'clsx';
import { firebaseAuth } from '../../firebase';
import { useDispatch } from 'react-redux';
import { KeyboardArrowDown, Menu as MenuIcon, Storefront } from '@mui/icons-material';
import { AppBar, Button, Toolbar, Typography, IconButton, Hidden, Menu, MenuItem } from '@mui/material';
import { drawerWidth } from '../AppDrawer';
import { makeStyles } from '@mui/styles';
import { switchDrawer } from '@order/admin/store/modules/drawer';
import { useUser, useAccount, useDrawerIsOpen, useFoEnabled } from '@order/admin/store/hooks';
import { AccountSwitcher } from '../AccountSwitcher';

import { ToUbiregiLink } from './ToUbiregiLink';
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0px 4px 8px rgba(200,205,206,.8)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarClose: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - calc(${theme.spacing(9)} + 1px))`,
    },
  },
  appBarOpen: {
    marginLeft: drawerWidth,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarAccountName: {
    textAlign: 'right',
    color: theme.palette.text.primary,
  },
  blank: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));

const signOut = async () => {
  await firebaseAuth().signOut();
  window.location.href = '/';
};

const SignOutButton = React.forwardRef((props, ref) => (
  <MenuItem id='logout' ref={ref} color='inherit' onClick={() => signOut()}>
    ログアウト
  </MenuItem>
));

export const AdminAppBar = (props) => {
  const user = useUser();
  const account = useAccount();
  const open = useDrawerIsOpen();
  const foEnabled = useFoEnabled();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fullWidth = props.fullWidth;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) return null;

  return (
    <AppBar
      position='fixed'
      className={
        !fullWidth &&
        clsx(classes.appBar, {
          [classes.appBarOpen]: open && foEnabled,
          [classes.appBarClose]: !open && foEnabled,
        })
      }
    >
      <Toolbar>
        <Hidden mdUp>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => dispatch(switchDrawer())}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
            size='large'
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography className={classes.blank} />
        <Button
          id='account-name'
          className={classes.appBarAccountName}
          aria-controls='account-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <Storefront />
          &nbsp;
          {account && account.name}
          <KeyboardArrowDown />
        </Button>
        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getcontentanchorel={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ToUbiregiLink />
          <Divider />
          <AccountSwitcher />
          <SignOutButton />
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

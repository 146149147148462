import React from 'react';
import { Typography, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetApp } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  card: {
    width: 345,
    margin: 20,
  },
  media: {
    height: 350,
  },
}));

export const DownloadCard = ({ title, mediaPath, detail, thumbnail }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea href={mediaPath} target='_blank' rel='noopener noreferrer'>
        <CardMedia className={classes.media} image={thumbnail} title={title} />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {detail}
          </Typography>
        </CardContent>
        <Typography variant='body1' color='secondary' style={{ padding: 16, display: 'flex' }}>
          <GetApp />
          ダウンロード
        </Typography>
      </CardActionArea>
    </Card>
  );
};

import React, { useEffect, useState } from 'react';
import { useTopCategories, useCategories } from '../../store/hooks';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Icon,
} from '@mui/material';
import { useMigration } from './useMigration';

const Category = ({ category }) => {
  const topCategory = useTopCategories().find((t) => t.id === category.top_category_id);
  if (!topCategory) return null;
  return (
    <ListItem divider dense>
      <ListItemAvatar>
        <Avatar
          variant='square'
          src={category.image}
          alt={category.category.name}
          style={{ width: 120, marginRight: 12 }}
        >
          {category.category.name.substr(0, 5)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={category.category.name} secondary={topCategory?.name} />
    </ListItem>
  );
};

const TopCategory = ({ topCategory }) => {
  return (
    <ListItem key={topCategory.id} divider dense>
      <ListItemAvatar>
        <Avatar variant='square' src={topCategory.image} alt={topCategory.name} style={{ width: 120, marginRight: 12 }}>
          {topCategory.name.substr(0, 5)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={topCategory.name} />
    </ListItem>
  );
};

const Migrator = () => {
  const topCategories = useTopCategories();
  const categories = (useCategories() || []).filter((c) => c.top_category_id);
  const [disabled, setDisabled] = useState(false);
  const migration = useMigration();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    document.title = '旧管理画面からのデータ移行 - ユビレジQRオーダー&決済管理画面';
  }, []);

  return (
    <Paper style={{ padding: 20, margin: 20 }}>
      <Typography variant='h4' gutterBottom>
        旧管理画面からのデータ移行
      </Typography>
      <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
        旧管理画面で作成したこれらのデータを「ページ」「メニュー」に移行します。
      </Typography>
      <Typography variant='subtitle1' style={{ marginBottom: 20, display: 'inline-flex' }}>
        <Link
          href={`https://umo-admin-jp.firebaseapp.com/`}
          target='_blank'
          rel='noopener'
          style={{ fontWeight: 'bold', display: 'inline-flex' }}
        >
          <Icon>exit_to_app</Icon>
          <span>旧管理画面にもどる</span>
        </Link>
        （旧管理画面は将来的に廃止されますので、お早めにデータ移行をお願いします）
      </Typography>
      <Paper variant='outlined' style={{ borderColor: '#FE8A17', padding: 20 }}>
        <Typography variant='h5' gutterBottom>
          注意事項
        </Typography>
        <Typography variant='subtitle2' gutterBottom>
          この作業は一度のみで完了します。移行作業中はブラウザウインドウを閉じないようにしてください。
          また、営業中に行うと不具合が生じる場合がありますので、必ず営業時間外に実行してください。
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  setChecked(true);
                } else {
                  setChecked(false);
                }
              }}
            />
          }
          label='注意事項を確認しました'
        />
        <Button
          disabled={!checked || disabled}
          onClick={() => {
            setDisabled(true);
            migration();
          }}
          variant='contained'
          color='primary'
        >
          データ移行する
        </Button>
      </Paper>
      <div style={{ height: 20 }} />
      <Typography variant='h5'>大カテゴリ</Typography>
      <Typography variant='subtitle1'>「メニュー」に移行します</Typography>
      <List>
        {topCategories.map((t) => (
          <TopCategory topCategory={t} key={t.id} />
        ))}
      </List>
      <div style={{ height: 20 }} />
      <Typography variant='h5'>カテゴリ</Typography>
      <Typography variant='subtitle1'>「ページ」に移行します</Typography>
      <List>
        {categories.map((c) => (
          <Category category={c} key={c.category.id} />
        ))}
      </List>
    </Paper>
  );
};

export default Migrator;

import { Button, Checkbox, Typography, Divider, Box, FormControlLabel } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { useMenuListMutator } from '../../store/hooks/firestore';
import { useOfferTypes } from '../../store/hooks';

const useSubHeader = ({ menu, selectedPages, checkedPageIds, checkPageId }) => {
  const { update } = useMenuListMutator();
  const rowOfferTypes = useOfferTypes();
  const offerTypes = useMemo(() => rowOfferTypes || [], [rowOfferTypes]);
  const allSelected = useMemo(() => checkedPageIds.length === selectedPages.length, [selectedPages, checkedPageIds]);
  const itemChecked = useMemo(() => !!checkedPageIds.length, [checkedPageIds]);
  const sortDisabled = useMemo(() => selectedPages.length <= 1, [selectedPages]);
  const onChange = useCallback(
    (e) => {
      if (e.target.checked) {
        checkPageId(selectedPages.map(({ id }) => id));
      } else {
        checkPageId([]);
      }
    },
    [checkPageId, selectedPages],
  );
  const withoutPage = useCallback(async () => {
    if (window.confirm('選択したページを、このメニューから削除しますか？')) {
      await update({
        id: menu.id,
        payload: {
          updateDate: new Date(),
          pageIds: selectedPages.filter((page) => !checkedPageIds.includes(page.id)).map((page) => page.id),
        },
        updateSnapshot: offerTypes,
      });
      checkPageId([]);
    }
  }, [update, selectedPages, checkedPageIds, checkPageId, menu, offerTypes]);

  return [allSelected, itemChecked, sortDisabled, onChange, withoutPage];
};

export const SubHeader = ({ menu, selectedPages, checkedPageIds, checkPageId, sort, sortCancel, sortSave }) => {
  const [allSelected, itemChecked, sortDisabled, onChange, withoutPage] = useSubHeader({
    menu,
    selectedPages,
    checkedPageIds,
    checkPageId,
  });

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: 50, marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          {!sort && (
            <FormControlLabel
              control={
                <Checkbox
                  data-test-id='all-selected-page'
                  color='primary'
                  onChange={onChange}
                  checked={allSelected}
                  indeterminate={!allSelected && itemChecked}
                  style={{ width: 50, marginRight: 10 }}
                />
              }
              label={<Typography>すべて選択</Typography>}
            />
          )}
        </Box>
        <Box>
          {!sort && (
            <Button
              data-test-id='delete-page-id'
              disabled={!itemChecked}
              variant='contained'
              color='secondary'
              onClick={withoutPage}
            >
              このメニューから外す
            </Button>
          )}
          {sort && (
            <Button variant='contained' style={{ marginLeft: 10 }} color='secondary' onClick={sortSave}>
              並び替え完了
            </Button>
          )}
          <Button
            disabled={sortDisabled}
            variant='outlined'
            style={{ marginLeft: 10 }}
            color='secondary'
            onClick={sortCancel}
          >
            {sort ? '並び替えキャンセル' : '並び替え'}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

import React from 'react';
import { WarningAmber } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useValidSubscription } from '../store/hooks';
import dayjs from 'dayjs';

export const AlertInUnavailability = () => {
  const subscription = useValidSubscription();
  const styles = makeStyles(() => ({
    alert: {
      // TODO: 既存の画面に合わせているが、できればmarginは親要素で決まってて欲しい。
      marginLeft: 20,
      marginRight: 20,
    },
  }))();

  if (!subscription?.start_at) {
    return null;
  }
  const startAt = dayjs(subscription.start_at);

  if (dayjs().isAfter(startAt)) {
    return null;
  }
  return (
    <Alert className={styles.alert} icon={<WarningAmber />} color='warning' variant='filled'>
      <Typography>注文機能のご利用開始日: {startAt.format('YYYY/MM/DD')}〜</Typography>
      <Typography>設定準備期間中です。注文機能はご利用いただけません。</Typography>
    </Alert>
  );
};

import React from 'react';
import { Paper, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CheckoutItemList } from './CheckoutItemList';

export const CheckoutList = ({ filteredItems, search, handleChange, searchItems, add }) => {
  return (
    <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexWrap: 'wrap' }}>
      {filteredItems.length > 0 ? (
        <>
          <div style={{ width: '500px' }}>
            <TextField
              variant='standard'
              value={search}
              fullWidth
              onChange={handleChange}
              label='お会計呼び出し用商品の検索（価格が0円の商品）'
              placeholder='バーコードや商品名、SKUで商品を検索してください。'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <CheckoutItemList items={searchItems} add={add} />
        </>
      ) : (
        <Typography variant='body1'>設定可能な商品がありません。ユビレジで0円の商品を作成してください。</Typography>
      )}
    </Paper>
  );
};

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Slider from '@mui/material/Slider';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { FormControlLabel } from '@mui/material';
import { fetchFromFo, loadFoSetting } from '@order/admin/store/modules/foSetting';
import Jimp from 'jimp';
import { uploadLogoOnQRCode } from '../../components/ImageForm';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DropZone } from './Dropzone';

export const QRCodePrintContentSetting = ({ user, foSettings, functionsDomain, saved, sending, submitToFo }) => {
  const [topMessage, setTopMessage] = useState('');
  const [bottomMessage, setBottomMessage] = useState('');
  const [image, setImage] = useState('');
  const [rawImage, setRawImage] = useState(null);
  const [invert, setInvert] = useState(false);
  const [brightness, setBrightness] = useState(0);
  const [printQR, setPrintQR] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const submit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    sending();

    let url = image;
    if (rawImage && image) {
      url = await uploadLogoOnQRCode({ image }, false);
      setImage(url);
    }

    await submitToFo({
      image: url,
      topMessage,
      bottomMessage,
      printQR,
      user,
      functionsDomain,
      fixedQR: foSettings.mo_fixed_qr,
    });
    saved();
    setDisabled(false);
  };

  const fetchFoSettings = useCallback(async () => {
    const data = await fetchFromFo({ user, functionsDomain });
    dispatch(loadFoSetting(data));
  }, [dispatch, user, functionsDomain]);

  useEffect(() => {
    if (user) fetchFoSettings();
  }, [user, fetchFoSettings]);

  useEffect(() => {
    if (foSettings) {
      setTopMessage(foSettings.mo_top_message);
      setBottomMessage(foSettings.mo_bottom_message);
      setImage(foSettings.mo_image);
      setPrintQR(!!foSettings.mo_print_qr);
    }
  }, [foSettings]);

  useEffect(() => {
    if (rawImage) {
      const handleJimp = async () => {
        try {
          const img = await Jimp.read(rawImage);
          if (invert) img.invert();
          img
            .brightness(brightness)
            .grayscale()
            .contrast(1)
            .getBase64(img.getMIME(), (err, data) => {
              setImage(data);
            });
        } catch (error) {
          throw error;
        }
      };
      handleJimp();
    }
  }, [rawImage, brightness, invert]);

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      position: 'relative',
    },
    div: {
      width: 280,
      position: 'absolute',
      top: '5%',
      right: '10%',
      left: 'auto',
      border: '1px solid #757575',
      padding: theme.spacing(1),
      backgroundColor: '#fff',
      zIndex: '1000',
    },
    button: {
      marginTop: '20px',
    },
    sample: {
      display: 'block',
      width: '100%',
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Typography>
        来店客が着席する毎に発行するQRコードの設定をします。
        <br />
        印刷にロゴやメッセージの記載が可能です。
      </Typography>

      <form onSubmit={(e) => e.preventDefault()}>
        <Typography style={{ marginTop: '20px', fontSize: '20px' }} variant='h5'>
          印刷設定
        </Typography>
        <Typography style={{ marginTop: '10px' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid='print_qr_checkbox'
                color='primary'
                checked={printQR}
                onChange={(e) => setPrintQR(e.target.checked)}
              />
            }
            label='QRコードを自動的に印刷する'
          />
        </Typography>

        <div className={classes.wrapper}>
          <Typography style={{ marginTop: '20px', fontSize: '20px' }} variant='h5'>
            印刷表示設定
          </Typography>
          <Button variant='outlined' size='small' onClick={handleClick} className={classes.button}>
            QRコード伝票のサンプル表示
          </Button>
          {open && (
            <div className={classes.div}>
              <img src='/qrcode-sample.png' alt='QRコード伝票のサンプルを見る' className={classes.sample} />
            </div>
          )}
        </div>

        <DropZone image={image} setRawImage={setRawImage} />
        <div style={{ width: '300px' }}>
          {rawImage && (
            <>
              <Typography gutterBottom>色の調整</Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <Brightness3Icon />
                </Grid>
                <Grid item xs>
                  <Slider
                    valueLabelDisplay='auto'
                    value={brightness}
                    data-testid='color-slider'
                    onChange={(_, val) => {
                      setBrightness(val);
                    }}
                    max={1}
                    min={-1}
                    step={0.1}
                  />
                </Grid>
                <Grid item>
                  <Brightness7Icon />
                </Grid>
              </Grid>
            </>
          )}
        </div>

        {rawImage && (
          <Button
            style={{ marginTop: 10, marginBottom: 20, marginRight: 8 }}
            type='button'
            variant='contained'
            color={!invert ? 'secondary' : 'primary'}
            onClick={() => setInvert(!invert)}
          >
            {!invert ? (
              <>
                <Icon style={{ display: 'inline-block', marginRight: '5px' }}>invert_colors</Icon>
                色を反転
              </>
            ) : (
              <>
                <Icon style={{ display: 'inline-block', marginRight: '5px' }}>undo</Icon>
                元に戻す
              </>
            )}
          </Button>
        )}
        {image && (
          <Button
            style={{ marginTop: 10, marginBottom: 20 }}
            type='button'
            variant='outlined'
            onClick={() => {
              setImage(null);
              setRawImage(null);
            }}
          >
            <Icon style={{ display: 'inline-block', marginRight: '5px' }}>clear</Icon>画像をクリア
          </Button>
        )}
        <div style={{ maxWidth: '600px', margin: '40px 0 20px' }}>
          <TextField
            variant='standard'
            elevation={1}
            label='②画像の下に表示するメッセージ'
            value={topMessage}
            onChange={(e) => {
              setTopMessage(e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ maxWidth: '600px', marginBottom: '20px' }}>
          <TextField
            variant='standard'
            elevation={1}
            label='③QRコードの下に表示するメッセージ'
            value={bottomMessage}
            onChange={(e) => {
              setBottomMessage(e.target.value);
            }}
            multiline={true}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ marginTop: '40px' }}>
          <Button
            data-testid='print_qr_save'
            variant='contained'
            color='secondary'
            disabled={disabled}
            onClick={submit}
          >
            保存
          </Button>
        </div>
      </form>
    </>
  );
};

import { List as MuiList, Typography, Paper } from '@mui/material';
import { firebaseFirestore } from '../../firebase';
import arrayMove from 'array-move';
import React, { useCallback, useEffect, useState } from 'react';
import { orderBy, last } from 'lodash';
import { useOfferTypeMutator } from '../../store/hooks/firestore';
import { OfferTypeRow } from './OfferTypeRow';
import { SubHeaderDisabledOfferType, SubHeaderEnabledOfferType } from './SubHeader';
import { DragDrop } from '@order/admin/components/DragDrop';

const useToggle = () => {
  const [flag, set] = useState(false);
  const toggle = () => set(!flag);
  return [flag, toggle];
};

const useSelectedOfferTypes = (offerTypes) => {
  const [selectedOfferTypes, setSelectedOfferTypes] = useState([]);
  useEffect(() => {
    if (offerTypes) {
      setSelectedOfferTypes(offerTypes);
    }
  }, [setSelectedOfferTypes, offerTypes]);

  const sortOfferType = useCallback(
    async ({ source, destination }) => {
      const moved = arrayMove(selectedOfferTypes, source.index, destination.index).map((o, i) => {
        o.order = i + 1;
        return o;
      });
      setSelectedOfferTypes(moved);
    },
    [selectedOfferTypes, setSelectedOfferTypes],
  );

  const sortCancel = useCallback(() => setSelectedOfferTypes(offerTypes), [setSelectedOfferTypes, offerTypes]);

  return [selectedOfferTypes, sortOfferType, sortCancel];
};

export const List = ({
  enabledOfferTypes,
  disabledOfferTypes,
  deletedAction,
  updateFOAction,
  updating,
  setUpdating,
}) => {
  const [sortedEnabledOfferTypes, sortEnabledRows, sortEnabledCancel] = useSelectedOfferTypes(
    enabledOfferTypes,
    updateFOAction,
  );
  const [sortedDisabledOfferTypes, sortDisabledRows, sortDisabledCancel] = useSelectedOfferTypes(disabledOfferTypes);
  const [selectEnabledOfferTypeIds, setSelectEnabledOfferTypeId] = useState([]);
  const [selectDisabledOfferTypeIds, setSelectDisabledOfferTypeId] = useState([]);
  const [enabledOfferTypeSort, enabledOfferTypeToggleSort] = useToggle();
  const [disabledOfferTypeSort, disabledOfferTypeToggleSort] = useToggle();

  const { update, destroy } = useOfferTypeMutator();

  const sortSave = async ({ type }) => {
    const targetOfferTypes = type === 'enabledOfferType' ? sortedEnabledOfferTypes : sortedDisabledOfferTypes;
    const batch = firebaseFirestore().batch();
    await Promise.all(
      targetOfferTypes.map((offerType) =>
        update({ id: offerType.id, payload: { order: offerType.order, updateDate: new Date() } }),
      ),
    );
    if (type === 'enabledOfferType') {
      await updateFOAction('メニューブックの並び替えを行いました');
      enabledOfferTypeToggleSort();
    } else {
      disabledOfferTypeToggleSort();
    }
    await batch.commit();
  };

  const deleteOfferType = async () => {
    if (window.confirm('選択したメニューブックを削除しますか')) {
      setUpdating(true);
      await Promise.all(
        selectDisabledOfferTypeIds.map(async (id) => {
          await destroy({ id });
        }),
      );
      deletedAction();
      setUpdating(false);
      setSelectDisabledOfferTypeId([]);
    }
  };

  const enableOfferType = async () => {
    if (window.confirm('選択したメニューブックを公開しますか')) {
      setUpdating(true);
      const lastOrder = last(orderBy(enabledOfferTypes, ['order'], 'asc'))?.order || 0;
      await Promise.all(
        selectDisabledOfferTypeIds.map(async (id, index) => {
          await update({ id, payload: { enable: true, order: lastOrder + index + 1 } });
        }),
      );
      updateFOAction('選択されたメニューブックを公開状態にしました');
      setUpdating(false);
      setSelectDisabledOfferTypeId([]);
    }
  };

  const requestingCopy = async (id) => {
    if (
      window.confirm(
        'このメニューブックをコピーしますか？（この処理は時間がかかることがあります。画面を閉じても処理は継続されます）',
      )
    ) {
      await update({ id, payload: { copy: 'request' } });
    }
  };

  const disableOfferType = async () => {
    if (selectEnabledOfferTypeIds.length === enabledOfferTypes.length) {
      alert('公開中のメニューブックをすべて非公開にはできません');
      return;
    }

    if (window.confirm('選択したメニューブックを非公開にしますか')) {
      setUpdating(true);
      const lastOrder = last(orderBy(disabledOfferTypes, ['order'], 'asc'))?.order || 0;
      await Promise.all(
        selectEnabledOfferTypeIds.map(async (id, index) => {
          await update({ id, payload: { enable: false, order: lastOrder + index + 1 } });
        }),
      );
      updateFOAction('選択されたメニューブックを非公開状態にしました');
      setUpdating(false);
      setSelectEnabledOfferTypeId([]);
    }
  };

  if (!enabledOfferTypes.length && !disabledOfferTypes.length)
    return (
      <Paper elevation={0} style={{ textAlign: 'center', padding: 40 }}>
        <Typography variant='h5' gutterBottom style={{ fontWeight: 600, color: '#757575' }}>
          メニューブックを作成しましょう
        </Typography>
        <Typography variant='subtitle2'>
          複数のメニューをひとつにまとめたものがメニューブックです。
          <br />
          メニューブックを複数作成すると、お客様ごとにユビレジ
          ハンディから表示するメニューブックを切り替えることができます。
          <br />
          切り替えの必要がない場合は、1つ作成するだけで大丈夫です。
          <br />
        </Typography>
        <img src='/tutorial-menubook.svg' alt='' style={{ marginTop: 40, width: 140 }} />
      </Paper>
    );

  return (
    <>
      {!!sortedEnabledOfferTypes.length && (
        <MuiList
          subheader={
            <SubHeaderEnabledOfferType
              offerTypes={sortedEnabledOfferTypes}
              selectedOfferTypeIds={selectEnabledOfferTypeIds}
              updating={updating}
              disableOfferType={disableOfferType}
              sort={enabledOfferTypeSort}
              sortCancel={() => {
                sortEnabledCancel();
                enabledOfferTypeToggleSort();
              }}
              sortSave={sortSave}
            />
          }
        >
          <DragDrop
            testId='enable-offerType-row'
            onDragEnd={sortEnabledRows}
            items={sortedEnabledOfferTypes}
            enabled={enabledOfferTypeSort}
          >
            {(offerType, i) => (
              <OfferTypeRow
                id={offerType.id}
                offerType={offerType}
                isFirst={i === 0}
                checked={selectEnabledOfferTypeIds.includes(offerType.id)}
                requestingCopy={requestingCopy}
                disabled={updating}
                handle={sortedEnabledOfferTypes.length > 1 && enabledOfferTypeSort}
                sort={enabledOfferTypeSort}
                onChange={(value) => {
                  if (value) {
                    setSelectEnabledOfferTypeId(selectEnabledOfferTypeIds.concat(offerType.id));
                  } else {
                    setSelectEnabledOfferTypeId(selectEnabledOfferTypeIds.filter((id) => id !== offerType.id));
                  }
                }}
              />
            )}
          </DragDrop>
        </MuiList>
      )}

      {!!sortedDisabledOfferTypes.length && (
        <MuiList
          subheader={
            <SubHeaderDisabledOfferType
              offerTypes={sortedDisabledOfferTypes}
              selectedOfferTypeIds={selectDisabledOfferTypeIds}
              setSelectedOfferTypeId={setSelectDisabledOfferTypeId}
              updating={updating}
              deleteOfferType={deleteOfferType}
              enableOfferType={enableOfferType}
              sort={disabledOfferTypeSort}
              sortCancel={() => {
                sortDisabledCancel();
                disabledOfferTypeToggleSort();
              }}
              sortSave={sortSave}
            />
          }
        >
          <DragDrop
            testId='disable-offerType-row'
            onDragEnd={sortDisabledRows}
            items={sortedDisabledOfferTypes}
            enabled={disabledOfferTypeSort}
          >
            {(offerType, i) => (
              <OfferTypeRow
                id={offerType.id}
                offerType={offerType}
                checked={selectDisabledOfferTypeIds.includes(offerType.id)}
                requestingCopy={requestingCopy}
                disabled={updating}
                handle={sortedDisabledOfferTypes.length > 1 && disabledOfferTypeSort}
                sort={disabledOfferTypeSort}
                onChange={(value) => {
                  if (value) {
                    setSelectDisabledOfferTypeId(selectDisabledOfferTypeIds.concat(offerType.id));
                  } else {
                    setSelectDisabledOfferTypeId(selectDisabledOfferTypeIds.filter((id) => id !== offerType.id));
                  }
                }}
              />
            )}
          </DragDrop>
        </MuiList>
      )}
    </>
  );
};

import { useFoEnabled, useFunctionsDomain, useUser } from '@order/admin/store/hooks';
import { fetchFromFo, loadFoSetting, submitToFo } from '@order/admin/store/modules/foSetting';
import { loadFOEnabled } from '@order/admin/store/modules/session';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useFoChecker = () => {
  const user = useUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const foEnabled = useFoEnabled();
  const functionsDomain = useFunctionsDomain();

  const fromFO = useCallback(async () => {
    const data = await fetchFromFo({ user, functionsDomain });
    dispatch(loadFoSetting(data));
    if (data?.mo_print_qr === undefined) {
      await submitToFo({
        printQR: false,
        user,
        functionsDomain,
        image: null,
        topMessage: null,
        bottomMessage: null,
        fixedQR: false,
      });
    }
  }, [dispatch, user, functionsDomain]);

  const check = useCallback(async () => {
    try {
      setLoading(true);
      const { idToken } = user;
      const options = { headers: { Authorization: `Bearer ${idToken}` } };
      await axios.get(`${functionsDomain}/refresh`, { headers: { Authorization: idToken } });
      await axios.get(`${functionsDomain}/foProxy?url=${encodeURIComponent('/api/authorize')}`, options);
      setLoading(false);
      dispatch(loadFOEnabled(true));
    } catch (e) {
      setLoading(false);
      dispatch(loadFOEnabled(false));
      throw e;
    }
  }, [dispatch, user, functionsDomain]);

  useEffect(() => {
    if (user) {
      check().then(() => fromFO());
    }

    if (user === null) {
      setLoading(false);
    }
  }, [user, check, fromFO]);

  return { user, loading, foEnabled };
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 75,
    height: 75,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  itemList: {
    height: '550px',
    overflow: 'scroll',
  },
}));

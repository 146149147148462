// 開発環境で 404 を出すためだけのもの
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useEnvironment } from '../../store/hooks';

const NotFound = () => {
  useEffect(() => {
    (async () => {
      const res = await fetch('/404.html');
      const html = await res.text();
      window.document.querySelector('html').innerHTML = html;
    })();
  }, []);
  return <></>;
};

export const NotFoundOnDev: React.FC = () => {
  const env = useEnvironment();
  if (env === 'development') {
    return <Route path='/test404' component={NotFound} />;
  } else {
    return null;
  }
};

import React, { useState, useEffect, useRef } from 'react';
import Jimp from 'jimp';
import { ListItem, Button, Icon } from '@mui/material';
import { Cropper } from './Cropper';
import { Dropzone } from './Dropzone';

import { v4 as uuid } from 'uuid';
import { firebaseStorage, isFirebaseStorageEmulator, firebaseStorageUrlBase } from '../../firebase';

const handleJimp = async ({ image, imageWidth, imageHeight }) => {
  let img = await Jimp.read(image);
  if (imageHeight && !imageWidth) {
    // 幅が指定されていなければ、アスペクト比を素にいい感じに幅を設定する
    imageWidth = img.getWidth() * (imageHeight / img.getHeight());
  }
  if (imageHeight && imageWidth) {
    img = img.contain(imageWidth, imageHeight);
  }

  return img.background(0xffffffff);
};

// データURIからバッファに変換
const dataURIToBuffer = (uri) => {
  const base64Data = uri.split(',')[1];
  return Buffer.from(base64Data, 'base64');
};

export const uploadLogoOnQRCode = async ({ image, imageWidth, imageHeight }) => {
  const uriBuffer = dataURIToBuffer(image);
  const croppedImage = await handleJimp({ image: uriBuffer, imageWidth, imageHeight });
  const buffer = await croppedImage.getBufferAsync(Jimp.MIME_PNG);
  const filename = `${uuid()}.png`;

  return await uploadToStorage(filename, buffer, 'image/png');
};

export const imageUpload = async ({ image, imageWidth, imageHeight }, compress = true) => {
  const uriBuffer = dataURIToBuffer(image);
  const croppedImage = await handleJimp({ image: uriBuffer, imageWidth, imageHeight });
  const buffer = await croppedImage.quality(30).getBufferAsync(Jimp.MIME_JPEG);
  const filename = `${uuid()}.jpg`;

  return await uploadToStorage(filename, buffer);
};

const uploadToStorage = async (filename, buffer, contentType = 'image/jpeg') => {
  // 旧 storageにアップロード
  const storage = firebaseStorage();
  const imageRef = storage.ref().child(filename);
  const snapshot = await imageRef.put(buffer, { contentType });
  const url = `${firebaseStorageUrlBase()}/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`;
  if (isFirebaseStorageEmulator()) {
    return url;
  }

  const customStorage =
    storage.ref().bucket === 'ubiregi-mobile-order-staging.appspot.com'
      ? 'gs://gcs-qr-staging.ubiregi.jp'
      : 'gs://gcs-qr.ubiregi.jp';
  const newStorage = storage.app.storage(customStorage);
  // 新 storageにアップロード
  const newImageRef = newStorage.ref().child(filename);
  await newImageRef.put(buffer, { contentType });

  return url;
};

export const ImageForm = ({
  imageWidth,
  imageHeight,
  setImage,
  image,
  setRawImage,
  rawImage,
  fixAspect = true,
  setIsImageEdited = null,
  setTempImage = null,
}) => {
  const [cropped, setCropped] = useState(false);
  const cropperRef = useRef(null);

  const crop = () => {
    const croppedImage = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
    setCropped(true);
    setImage(croppedImage);
    if (!!setIsImageEdited) setIsImageEdited(true);
  };

  useEffect(() => {
    if (rawImage) {
      setImage(rawImage);
    }
    setCropped(false);
    if (!!setIsImageEdited) setIsImageEdited(false);
  }, [rawImage, setImage, setIsImageEdited]);

  return (
    <>
      <ListItem>
        {rawImage && !cropped ? (
          <Cropper
            rawImage={rawImage}
            cropperRef={cropperRef}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            fixAspect={fixAspect}
            setTempImage={setTempImage}
          />
        ) : (
          <Dropzone setRawImage={setRawImage} image={image} imageWidth={imageWidth} imageHeight={imageHeight} />
        )}
      </ListItem>
      <ListItem style={rawImage && { marginBottom: 20 }}>
        {rawImage && !cropped && (
          <Button
            data-testid='crop'
            type='button'
            variant='contained'
            color='secondary'
            onClick={crop}
            style={{ marginRight: '10px' }}
          >
            <Icon style={{ display: 'inline-block', marginRight: '5px' }}>crop</Icon>切り取る
          </Button>
        )}
        {rawImage && cropped && (
          <Button
            data-testid='redo'
            type='button'
            variant='contained'
            onClick={() => setCropped(false)}
            style={{ marginRight: '10px' }}
          >
            <Icon style={{ display: 'inline-block', marginRight: '5px' }}>undo</Icon>やりなおす
          </Button>
        )}
        {image && (
          <Button
            data-testid='clear'
            type='button'
            variant='outlined'
            onClick={() => {
              setImage(null);
              setRawImage(null);
            }}
          >
            <Icon style={{ display: 'inline-block', marginRight: '5px' }}>clear</Icon>画像をクリア
          </Button>
        )}
      </ListItem>
    </>
  );
};

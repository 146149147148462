import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import { firebaseAuth, firebaseFirestore } from '../firebase';
import React, { useEffect, useState } from 'react';
import { FormattedPrice } from '../components/Price';
import { useSelector } from 'react-redux';
import { Loader } from '../components/Loader';
import { useFunctionsDomain, useUbiregiHostName } from '../store/hooks';
import { AlertInUnavailability } from '../components/AlertInUnavailability';

const ItemsTable = (props) => {
  const body = props.body;
  const style = props.style;
  return (
    <Table size='small' style={style}>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>カテゴリ</TableCell>
          <TableCell>バーコード</TableCell>
          <TableCell align='right'>価格</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      {body}
    </Table>
  );
};

const SearchItem = (props) => {
  const item = props.item;
  const add = props.add;
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{item.category_name}</TableCell>
      <TableCell>{item.barcode}</TableCell>
      <TableCell align='right'>
        <FormattedPrice price={item.price} />
      </TableCell>
      <TableCell>
        <IconButton aria-label='追加' onClick={() => add(item)} size='large'>
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const SearchItems = (props) => {
  const { items, add } = props;
  return (
    <TableBody>
      {items.map((item) => (
        <SearchItem key={item.id} item={item} add={add} />
      ))}
    </TableBody>
  );
};

const Item = (props) => {
  const item = props.item;
  const remove = props.remove;
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{item.category_name}</TableCell>
      <TableCell>{item.barcode}</TableCell>
      <TableCell align='right'>
        <FormattedPrice price={item.price} />
      </TableCell>
      <TableCell>
        <IconButton aria-label='削除' onClick={() => remove(item)} size='large'>
          <RemoveIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Items = (props) => {
  const { items, remove } = props;
  return (
    <TableBody>
      {items.map((item) => (
        <Item key={item.id} item={item} remove={remove} />
      ))}
    </TableBody>
  );
};

export const TemplateScreen = (props) => {
  const ubiregiDomain = useUbiregiHostName();
  const functionsDomain = useFunctionsDomain();
  const user = useSelector((state) => state.session.user);
  const menuId = useSelector((state) => state.session.account && state.session.account.menus[0]);
  const [items, setItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [query, setQuery] = useState('');
  const [headers, setHeaders] = useState(null);
  const db = firebaseFirestore();

  const ubiregiInfo = React.useCallback(async () => {
    const idToken = await firebaseAuth().currentUser.getIdToken();
    const options = { headers: { Authorization: idToken } };
    const { credential } = await (await fetch(`${functionsDomain}/apiUbiregiAccountInfo`, options)).json();
    setHeaders({ Authorization: `Bearer ${credential.access_token}` });
  }, [functionsDomain]);
  useEffect(() => {
    if (user) ubiregiInfo();
  }, [user, ubiregiInfo]);

  const searchFromUbiregi = React.useCallback(async () => {
    const url = `https://${ubiregiDomain}/api/a/menus/${menuId}/items?query=${encodeURIComponent(query)}`;
    const response = await fetch(url, { headers });
    const data = await response.json();
    setSearchItems(data.items.filter((i) => i.category_name !== null));
  }, [headers, menuId, query, ubiregiDomain]);
  useEffect(() => {
    if (query) {
      searchFromUbiregi();
    } else {
      setSearchItems([]);
    }
  }, [query, searchFromUbiregi]);

  const fetchItems = React.useCallback(async () => {
    const ref = db.collection('accounts').doc(user.uid).collection('templates');
    const docs = await ref.get();
    setItems(docs.docs.map((e) => e.data().item));
  }, [db, user]);
  useEffect(() => {
    if (user) fetchItems();
  }, [user, fetchItems]);

  const add = async (item) => {
    const ref = db.collection('accounts').doc(user.uid).collection('templates').doc(String(item.id));
    const doc = await ref.get();
    if (!doc.exists) {
      await ref.set({ item });
    } else {
      await ref.update({ item });
    }
    fetchItems();
  };
  const remove = async (item) => {
    const ref = db.collection('accounts').doc(user.uid).collection('templates').doc(String(item.id));
    await ref.delete();
    fetchItems();
  };

  if (!user) return <Loader />;
  if (!menuId) return <Loader />;

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexWrap: 'wrap' }}>
        <Typography variant='h4' gutterBottom style={{ width: '100%' }}>
          テンプレート
        </Typography>
        <Typography variant='subtitle1' style={{ width: '100%', marginBottom: 20 }}>
          設定した商品をファーストオーダー時にテーブルの人数分、自動で注文します
        </Typography>
        <Typography variant='overline' style={{ width: '100%' }}>
          設定した商品
        </Typography>
        {items && items[0] ? (
          <ItemsTable body={<Items items={items} remove={remove} />} />
        ) : (
          <Typography variant='subtitle2'>自動で注文する商品はありません</Typography>
        )}
      </Paper>
      <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexWrap: 'wrap' }}>
        <Typography variant='overline'>検索して設定</Typography>
        <div style={{ width: '100%' }}>
          <TextField
            variant='standard'
            value={query}
            fullWidth
            onChange={(e) => setQuery(e.target.value)}
            placeholder='バーコードや商品名、SKUで商品を検索できます'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {query && (
          <ItemsTable
            style={{ marginTop: 20 }}
            body={<SearchItems items={searchItems} add={add} style={{ width: '100%' }} />}
          />
        )}
      </Paper>
    </>
  );
};

import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link,
  Checkbox,
  Typography,
  Divider,
  Box,
  Icon,
  Chip,
} from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { SortablePageRow } from './SortablePageRow';
import { SubHeader } from './SubHeader';
import arrayMove from 'array-move';
import { Link as RouterLink } from 'react-router-dom';
import { useMenuListMutator } from '../../store/hooks/firestore';
import { useSaveNotify } from '../../store/hooks/useSaveNotify';
import { DragDrop } from '@order/admin/components/DragDrop';
import { makeStyles } from '@mui/styles';
import { useOfferTypes } from '../../store/hooks';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 150,
    height: 'auto',
    marginRight: theme.spacing(1),
  },
  fallback: {
    width: 150,
    height: 25,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
  },
}));

const useToggle = () => {
  const [flag, set] = useState(false);
  const toggle = () => set(!flag);
  return [flag, toggle];
};

const PageDialog = ({ open, menu, toggle, pages }) => {
  const offerTypes = useOfferTypes() || [];
  const { avatar, fallback } = useStyles();
  const [selected, select] = useState({});
  const { update } = useMenuListMutator();
  const { saved } = useSaveNotify();

  useEffect(() => {
    if (menu) {
      const obj = {};
      (menu.pageIds || []).forEach((k) => (obj[k] = true));
      select(obj);
    } else {
      select({});
    }
  }, [menu]);

  const save = async () => {
    const pageIds = Object.keys(selected).filter((k) => selected[k]);
    await update({ id: menu.id, payload: { pageIds, updateDate: new Date() }, updateSnapshot: offerTypes });
    saved();
    toggle();
  };

  const onChange = (id) => select({ ...selected, [id]: !selected[id] });

  return (
    <Dialog fullWidth open={open} maxWidth='md' PaperProps={{ style: { padding: '10px 0', margin: 20 } }}>
      <DialogTitle>
        <strong>{menu.name}</strong> で表示するページを選択
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {pages.map((p, i) => {
            const labelId = `page-list-item-${p.id}-label`;
            return (
              <React.Fragment key={p.id}>
                <ListItem role='listitem' button onClick={() => onChange(p.id)}>
                  <Checkbox
                    color='primary'
                    checked={!!selected[p.id]}
                    onChange={() => onChange(p.id)}
                    value={p.id}
                    inputProps={{ 'aria-labelledby': labelId }}
                    style={{ width: 50, height: 50, marginRight: 10 }}
                  />
                  <ListItemAvatar>
                    <Avatar variant='square' className={p.image ? avatar : fallback} src={p.image} alt={p.name}>
                      {p.name.substr(0, 5)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={p.name}
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={p.offerTypes?.map((o) => (
                      <Chip
                        key={o.name}
                        style={{ marginRight: 5 }}
                        size='small'
                        label={
                          <>
                            <Icon
                              style={{
                                fontSize: '1.2em',
                                display: 'inline-flex',
                                verticalAlign: 'middle',
                                marginTop: -3,
                                marginRight: 3,
                                color: '#656565',
                              }}
                            >
                              menu_book
                            </Icon>
                            {o.name}
                          </>
                        }
                      />
                    ))}
                  />
                </ListItem>
                {i + 1 < pages.length && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions style={{ padding: '20px 20px 10px 0' }}>
        <Button variant='outlined' color='secondary' onClick={toggle}>
          キャンセル
        </Button>
        <Button variant='contained' color='secondary' autoFocus onClick={save}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useSelectedPages = (pages, pageIds) => {
  const [selectedPages, setSelectedPages] = useState([]);
  const initPages = useMemo(
    () => (pageIds && pageIds.map((id) => pages.find((p) => p.id === id)).filter((p) => !!p)) || [],
    [pageIds, pages],
  );
  useEffect(() => {
    if (pageIds) {
      setSelectedPages(initPages);
    }
  }, [setSelectedPages, pageIds, initPages]);

  const sortPage = useCallback(
    async ({ source, destination }) => {
      const moved = arrayMove(selectedPages, source.index, destination.index);
      setSelectedPages(moved);
    },
    [selectedPages, setSelectedPages],
  );

  const sortCancel = useCallback(() => setSelectedPages(initPages), [initPages]);

  return [selectedPages, sortPage, sortCancel];
};

export const SelectPage = ({ menu, pages }) => {
  const [open, toggle] = useToggle();
  const [sort, toggleSort] = useToggle();
  const [selectedPages, sortPage, sortCancel] = useSelectedPages(pages, menu.pageIds);
  const [checkedPageIds, checkPageId] = useState([]);
  const { update } = useMenuListMutator();
  const offerTypes = useOfferTypes() || [];

  useEffect(() => {
    document.title = `${menu.name}の編集 - メニュー - ユビレジQRオーダー&決済管理画面`;
  }, [menu]);

  const SelectButton = () => {
    return (
      <Button variant='outlined' color='secondary' size='large' onClick={toggle}>
        <Typography variant='body1'>ページを選択</Typography>
      </Button>
    );
  };

  const sortSave = async () => {
    const pageIds = selectedPages.map((page) => page.id);
    await update({ id: menu.id, payload: { pageIds, updateDate: new Date() }, updateSnapshot: offerTypes });
    toggleSort();
  };

  if (!pages.length) {
    return (
      <>
        <Typography variant='body1' gutterBottom>
          まだページが作成されていません。
        </Typography>
        <Typography variant='body1'>
          <Link component={RouterLink} to='/c/page'>
            ページの設定
          </Link>
          からページを作成してください。
        </Typography>
      </>
    );
  }

  return (
    <>
      <PageDialog open={open} menu={menu} toggle={toggle} pages={pages} />
      {selectedPages.length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ marginTop: 20 }}>
              <strong>{menu.name}</strong> メニューに表示中のページ
            </Typography>
            {!sort && <SelectButton />}
          </Box>
          <List
            subheader={
              <SubHeader
                menu={menu}
                selectedPages={selectedPages}
                checkedPageIds={checkedPageIds}
                checkPageId={checkPageId}
                sort={sort}
                sortSave={sortSave}
                sortCancel={() => {
                  sortCancel();
                  toggleSort();
                }}
              />
            }
          >
            <DragDrop onDragEnd={sortPage} items={selectedPages} enabled={sort}>
              {(p, i) => (
                <SortablePageRow
                  id={p.id}
                  page={p}
                  checked={checkedPageIds.includes(p.id)}
                  handle={selectedPages.length > 1 && sort}
                  sort={sort}
                  onChange={(e) => {
                    if (e.target.checked) {
                      checkPageId(checkedPageIds.concat(p.id));
                    } else {
                      checkPageId(checkedPageIds.filter((id) => id !== p.id));
                    }
                  }}
                  isNotLast={i + 1 < selectedPages.length}
                />
              )}
            </DragDrop>
          </List>
        </>
      ) : (
        <>
          <Typography variant='body1' gutterBottom style={{ margin: '40px 0' }}>
            このメニュー（<strong>{menu.name}</strong>）に表示するページを選択してください。
          </Typography>
          <SelectButton />
        </>
      )}
    </>
  );
};

import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal } from '@mui/material';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

export const FixedQRAgreeModal = ({ isOpen, handleClose, handleCancelModal }) => {
  const styles = makeStyles((theme) => ({
    modalInner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      maxWidth: 664,
      backgroundColor: theme.palette.common.white,
      border: 0,
      borderRadius: 10,
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitle: {
      width: '100%',
      marginBottom: 38,
      fontSize: 17,
      fontWeight: 'bold',
      lineHeight: 1.17,
      letterSpacing: '0.34px',
    },
    modalButton: {
      padding: 10,
      fontSize: 17,
      lineHeight: 1,
    },
    warningText: { margin: '20px 0 45px' },
  }));

  const classes = styles();

  return (
    <Modal open={isOpen} onClose={handleCancelModal}>
      <div className={classes.modalInner}>
        <Typography variant='h2' align='center' className={classes.modalTitle}>
          テーブル固定QRコードをご利用前にご確認ください
        </Typography>
        <Typography>
          テーブル固定QRコードを利用する場合、QRコードが外部へ流出すると不正注文の入る可能性があります。
          <br />
          その際には「
          <Link
            color='#fb8c00'
            rel='noreferrer noopener'
            target='_blank'
            underline='hover'
            href={`https://support.ubiregi.jp/archives/31331`}
          >
            テーブル固定QRコード ご利用の流れ
          </Link>
          」 を参照しご対応ください。
        </Typography>
        <Typography className={classes.warningText}>
          なお、不正注文により生じた損害については、当社の利用規約に基づき一切の責任を負いかねますのでご了承ください。
        </Typography>

        <Button className={classes.modalButton} variant='contained' color='secondary' onClick={handleClose}>
          確認しました
        </Button>
      </div>
    </Modal>
  );
};

import { Button, Paper, Typography } from '@mui/material';
import { useFunctionsDomain } from '@order/admin/store/hooks';
import React, { useState } from 'react';
import { useStyles } from './useStyles';

export const AccountNotFound = () => {
  const classes = useStyles();
  const functionsDomain = useFunctionsDomain();
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <div className={classes.toolbar} />
      <div className={classes.paperWrapper} style={{ marginBottom: 12 }}>
        <img src='/logo.svg' alt='ユビレジ QRオーダー' style={{ maxHeight: 50 }} />
      </div>
      <div className={classes.paperWrapper}>
        <Paper className={classes.paper} style={{ marginRight: 0 }}>
          <div>
            <Typography variant='h4' style={{ marginBottom: 20 }}>
              1.
            </Typography>
            <Typography>
              ユビレジ QRオーダー&決済を使用するにはユビレジのアカウントと、ユビレジ ハンディの利用登録が必要です。
            </Typography>
            <Typography style={{ marginBottom: 20 }}>お持ちでない場合はアカウントの作成をお願いします。</Typography>
          </div>
          <Button
            className={classes.button}
            variant='outlined'
            size='large'
            color='secondary'
            href='https://ubiregi.com/signup'
          >
            ユビレジのアカウントを作成
          </Button>
        </Paper>
        <Paper className={classes.paper} style={{ marginRight: 0 }}>
          <div>
            <Typography variant='h4' style={{ marginBottom: 20 }}>
              2.
            </Typography>
            <Typography>ユビレジ QRオーダー&決済はユビレジ ハンディと連携することで動作するシステムです。</Typography>
            <Typography style={{ marginBottom: 20 }}>
              ユビレジのアカウントを使ってユビレジ ハンディの利用登録をします。
            </Typography>
          </div>
          <Button
            className={classes.button}
            variant='outlined'
            size='large'
            color='secondary'
            href='https://ubiregi.com/ja/handy/'
          >
            ユビレジ ハンディの利用登録
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <div>
            <Typography variant='h4' style={{ marginBottom: 20 }}>
              3.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              「ユビレジアカウントでログイン」を押してユビレジ QRオーダー&決済管理画面にログインします。
            </Typography>
          </div>
          <Button
            className={classes.button}
            href={`${functionsDomain}/authStart?domain=${window.location.origin}`}
            variant='contained'
            size='large'
            color='secondary'
            disabled={disabled}
            onClick={() => setDisabled(true)}
          >
            ユビレジアカウントでログイン
          </Button>
        </Paper>
      </div>
    </>
  );
};

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { firebaseFirestore } from '../../firebase';
import { setMessageToSnackbar, openSnackbar } from '../modules/snackbar';
import { flatten, uniq } from 'lodash';
import { getEnvironment } from '../../environment';
export { useSaveOfferTypeToFlickOrder } from './useSaveOfferTypeToFlickOrder.js';

export const useEnvironment = () => {
  return getEnvironment();
};

export const useUbiregiHostName = () => {
  const env = useEnvironment();
  if (env === 'production') return 'ubiregi.com';
  return 'staging.ubiregi.com';
};

export const useFirebaseConfig = () => {
  const env = useEnvironment();
  if (env === 'production')
    return {
      apiKey: 'AIzaSyDA8MLCwdl0caMhTqKrJy_FH1Shd6B5oQE',
      databaseURL: 'https://ubiregi-mobile-order-jp.firebaseio.com',
      storageBucket: 'ubiregi-mobile-order-jp.appspot.com',
      authDomain: 'ubiregi-mobile-order-jp.firebaseapp.com',
      messagingSenderId: '820248425339',
      projectId: 'ubiregi-mobile-order-jp',
    };
  if (env === 'test')
    return {
      apiKey: 'AIzaSyAPAglbg7TBUkz71Zr5_Lmofctu9cYFnfQ',
      authDomain: 'ubiregi-mobile-order-testing.firebaseapp.com',
      databaseURL: 'https://ubiregi-mobile-order-testing.firebaseio.com',
      projectId: 'ubiregi-mobile-order-testing',
      storageBucket: 'ubiregi-mobile-order-testing.appspot.com',
      messagingSenderId: '826330208932',
      appId: '1:826330208932:web:317742dcd14655c2e7e431',
    };
  return {
    apiKey: 'AIzaSyDqOuPd3BlFkRw-Ghncq-gum5uwFdS6IFQ',
    databaseURL: 'https://ubiregi-mobile-order-staging.firebaseio.com',
    storageBucket: 'ubiregi-mobile-order-staging.appspot.com',
    authDomain: 'ubiregi-mobile-order-staging.firebaseapp.com',
    messagingSenderId: '572307352894',
    projectId: 'ubiregi-mobile-order-staging',
  };
};

export const useClientHostName = () => {
  const env = useEnvironment();
  if (env === 'production') return 'qr.ubiregi.com';
  return 'staging-mobile-order.ubiregi.com';
};

export const useFunctionsDomain = () => {
  const env = useEnvironment();
  if (env === 'production') return 'https://us-central1-ubiregi-mobile-order-jp.cloudfunctions.net';
  if (env === 'test') return 'https://us-central1-ubiregi-mobile-order-testing.cloudfunctions.net';
  if (process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_URL_BASE) {
    return process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_URL_BASE;
  }
  return 'https://us-central1-ubiregi-mobile-order-staging.cloudfunctions.net';
};

export const useTopCategories = () => {
  return useSelector((state) => state.menu.topCategories);
};

export const useCategories = () => {
  return useSelector((state) => state.menu.categories || []);
};
/**
 * Firebaseユーザー情報を返す（基本使わない）
 */
export const useUser = () => {
  return useSelector((state) => state.session.user);
};

/**
 * ユビレジアカウント情報を返す
 */
export const useAccount = () => {
  return useSelector((state) => state.session.account);
};

/**
 * QRオーダー&セルフ決済関連のアカウント情報を返す
 */
export const useAccountInfo = () => useSelector((state) => state.accountInfo.accountInfo);

export const useValidSubscription = () => useSelector((state) => state.subscription);

export const useFoEnabled = () => {
  return useSelector((state) => state.session.foEnabled);
};

export const useDrawerIsOpen = () => {
  return useSelector((state) => state.drawer.open);
};

export const useQuery = (initialQuery = '') => {
  const [query, setQuery] = useState(initialQuery);
  const handleChange = useCallback(
    (e) => {
      setQuery(e.target.value);
    },
    [setQuery],
  );
  return [query, handleChange];
};

export const useOfferTypes = () => useSelector((state) => state.offerType.offerTypes || []);

export const useLoadedOfferTypes = () => useSelector((state) => !!state.offerType.offerTypes);

export const useMenuList = () => useSelector((state) => state.menuList.menuList);

export const usePages = () => useSelector((state) => state.menu.pages);

export const useRootRef = () => {
  const user = useUser();
  return firebaseFirestore().collection('accounts').doc(user.uid);
};

export const useTemplate = (user) => {
  const [template, setTemplate] = useState(null);
  const db = firebaseFirestore();
  useEffect(() => {
    const templateSnapshot = async () => {
      const ref = db.collection('accounts').doc(user.uid).collection('templates');
      setTemplate(await ref.get());
    };
    templateSnapshot();
  }, [db, user.uid]);
  return template;
};

export const useCheckoutItem = (user) => {
  const db = firebaseFirestore();
  const add = useCallback(
    async (item) => {
      const ref = db.collection('accounts').doc(user.uid);
      ref.update({ checkItem: item });
    },
    [user.uid, db],
  );

  const remove = useCallback(async () => {
    const ref = db.collection('accounts').doc(user.uid);
    ref.update({ checkItem: null });
  }, [user.uid, db]);

  return [add, remove];
};

export const useMenuItems = () =>
  useSelector((state) => {
    return (state.menu.menuItems || []).sort((a, b) => {
      return parseInt(a.item.position - b.item.position);
    });
  });

export const useFOSetting = () => useSelector((state) => state.foSetting.setting);
export const useSnackbar = () => {
  const dispatch = useDispatch();

  const showSnackbar = (message) => {
    dispatch(setMessageToSnackbar(message));
    dispatch(openSnackbar());
  };

  return { showSnackbar };
};

export const useMergedMenuList = () => {
  const menuList = useMenuList() || [];
  const offerTypes = useOfferTypes() || [];
  return menuList.map((m) => {
    return { ...m, offerTypes: offerTypes.filter((o) => o.menuIds?.includes(m.id)) };
  });
};

export const useMergedPages = () => {
  const pages = usePages() || [];
  const menuList = useMergedMenuList();
  return pages.map((p) => {
    return {
      ...p,
      offerTypes: uniq(flatten(menuList.filter((m) => m.pageIds?.includes(p.id)).map((m) => m.offerTypes))),
    };
  });
};

export const useUbiPayIntegrated = () => {
  const { enabled_integration_modules } = useAccount();
  return enabled_integration_modules ? enabled_integration_modules.some((module) => module === 'ubipay') : false;
};

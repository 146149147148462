import { MenuItem, Link, Typography } from '@mui/material';
import React from 'react';
import { useUbiregiHostName } from '../../store/hooks';

export function ToUbiregiLink() {
  const host = useUbiregiHostName();
  return (
    <>
      <Typography pl={2} color='GrayText' variant='caption'>
        管理画面
      </Typography>
      <Link rel='noreferrer noopener' target='_blank' color='ButtonText' underline='none' href={`https://${host}/a/`}>
        <MenuItem>ユビレジ</MenuItem>
      </Link>
    </>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openPreview } from '@order/admin/store/modules/preview';
import { createPreviewUrl } from '@order/admin/store/modules/preview';
import {
  Button,
  Box,
  Divider,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Icon,
  IconButton,
  Tooltip,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';
import {
  usePages,
  useMenuList,
  useOfferTypes,
  useMenuItems,
  useTopCategories,
  useLoadedOfferTypes,
} from '@order/admin/store/hooks';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { SetupList } from './SetupList';
import { QRCodeIcon } from '../Icon';
import { Loader } from '../Loader';
import { AlertInUnavailability } from '../AlertInUnavailability';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    height: '75px',
    marginRight: theme.spacing(1),
  },
  listSubText: {
    color: '#757575',
    fontSize: '0.9rem',
  },
}));

export const Home = () => {
  const classes = useStyles();
  const menuItems = useMenuItems() || [];
  const pages = usePages() || [];
  const menuList = useMenuList() || [];
  const offerTypes = useOfferTypes();
  const loadedOfferType = useLoadedOfferTypes();
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.session.user?.uid);
  const topCategories = useTopCategories() || [];

  useEffect(() => {
    document.title = 'トップページ - ユビレジQRオーダー&決済管理画面';
  }, []);

  if (!loadedOfferType) {
    return <Loader />;
  }

  if (loadedOfferType && !offerTypes.length && topCategories.length) {
    return <Redirect to='/c/migrator' />;
  }

  const openWindow = (offerTypeId) => {
    const url = createPreviewUrl(sessionId, offerTypeId);
    window.open(url, '', 'width=360,height=640');
  };

  const enabledOfferTypes = offerTypes.filter((o) => o.enable);

  const PreviewModeLink = ({ offerTypeId }) => {
    return (
      <>
        <ListItemIcon
          onClick={(e) => {
            e.preventDefault();
            dispatch(openPreview(offerTypeId));
          }}
        >
          <Tooltip title='実機確認用のQRコード表示'>
            <IconButton size='large'>
              <QRCodeIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
        <ListItemIcon
          onClick={(e) => {
            e.preventDefault();
            openWindow(offerTypeId);
          }}
          style={{ marginLeft: 4 }}
        >
          <Tooltip title='新規ウィンドウで表示確認'>
            <IconButton size='large'>
              <Icon style={{ fontSize: 30 }}>open_in_new</Icon>
            </IconButton>
          </Tooltip>
        </ListItemIcon>
      </>
    );
  };

  if (!menuItems.length || !pages.length || !menuList.length || !offerTypes.length || !enabledOfferTypes.length) {
    return (
      <SetupList
        classes={classes}
        menuItems={menuItems}
        pages={pages}
        menuList={menuList}
        offerTypes={offerTypes}
        enabledOfferTypes={enabledOfferTypes}
      />
    );
  }

  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: 20, margin: 20, minHeight: 300 }}>
        <Typography variant='h4' gutterBottom>
          <HomeIcon />
          トップページ
        </Typography>
        {enabledOfferTypes.length > 0 && (
          <>
            <Box display='flex' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant='h5' style={{ marginRight: 10 }}>
                公開中のメニューブック
              </Typography>
              <Button color='secondary' variant='outlined' component={RouterLink} to='/c/offertype'>
                設定へ移動
              </Button>
            </Box>
            <List>
              <Divider />
              {enabledOfferTypes.map((offerType) => (
                <ListItem
                  divider
                  disablePadding
                  key={offerType.id}
                  secondaryAction={<PreviewModeLink offerTypeId={offerType.id} />}
                >
                  <ListItemButton to={`/c/offertype/${offerType.id}`} component={RouterLink}>
                    <Avatar className={classes.root} variant='square' src={offerType.logo} alt={offerType.name}>
                      {offerType.name.substr(0, 5)}
                    </Avatar>
                    <ListItemText>
                      <Typography variant='body1'>{offerType.name}</Typography>
                      <Typography variant='body1' className={classes.listSubText}>
                        {offerType.menuIds?.length || 0}メニュー
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Paper>
    </>
  );
};

import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { FixedSizeList } from 'react-window';

export const CategoryList = ({ categories, categoryId, setCategoryId }) => {
  const height = window.innerHeight - 330;

  const Row = ({ index, style }) => {
    const category = categories[index];
    const { id, name } = category.category;

    return (
      <ListItem
        button
        ContainerProps={{ style: style }}
        style={style}
        ContainerComponent='div'
        key={id}
        onClick={() => setCategoryId(id)}
        selected={id === categoryId}
      >
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  return (
    <div style={{ maxWidth: 700 }}>
      <FixedSizeList height={height} itemCount={categories.length} itemSize={50}>
        {Row}
      </FixedSizeList>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar, setMessageToSnackbar } from '../modules/snackbar';
import { useOfferTypes } from './index';

export const useCopyOfferTypeStatusReporter = () => {
  const offerTypes = useOfferTypes();
  const [copyingOfferType, setCopyingOfferType] = useState(null);
  const dispatch = useDispatch();

  const offerType = offerTypes.find((o) => o.copy === 'request');

  useEffect(() => {
    if (copyingOfferType && offerTypes.find((o) => o.id === copyingOfferType.id && o.copy === 'done')) {
      const copiedOfferType = offerTypes.reduce((r, o) => (o.updateDate.seconds > r.updateDate.seconds ? o : r));
      setCopyingOfferType(null);
      dispatch(
        setMessageToSnackbar(`${copyingOfferType.name}のコピーが完了し「${copiedOfferType.name}」が追加されました。`),
      );
      dispatch(openSnackbar());
    }

    setCopyingOfferType(offerType);
  }, [offerType, copyingOfferType, offerTypes, dispatch]);

  return !!copyingOfferType;
};

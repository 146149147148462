import React from 'react';
import Button from '@mui/material/Button';
import { useTheme, withStyles } from '@mui/styles';

export const CustomButton = ({ color, variant, children, ...props }) => {
  const theme = useTheme();

  if (color === 'error') {
    const color = theme.palette.error.main;
    const dark = theme.palette.error.dark;
    if (variant === 'outlined') {
      const Component = withStyles(() => ({
        root: {
          color: color,
          borderColor: color,
          '&:hover': {
            color: theme.palette.getContrastText(color),
            backgroundColor: color,
            borderColor: color,
          },
        },
      }))(Button);
      return (
        <Component {...props} variant='outlined'>
          {children}
        </Component>
      );
    }
    if (variant === 'contained') {
      const Component = withStyles((theme) => ({
        root: {
          color: theme.palette.getContrastText(color),
          borderColor: color,
          backgroundColor: color,
          '&:hover': {
            color: theme.palette.getContrastText(dark),
            backgroundColor: dark,
            borderColor: dark,
          },
        },
      }))(Button);
      return (
        <Component {...props} variant='contained'>
          {children}
        </Component>
      );
    }
  } else {
    return (
      <Button {...props} color={color} variant={variant}>
        {children}
      </Button>
    );
  }
};

import encoding from 'encoding-japanese';
import Papa from 'papaparse';
import { firebaseStorage } from '../../firebase';
import { useAccount } from '../../store/hooks';
import { useCallback } from 'react';
import dayjs from 'dayjs';

type DownloadProps = {
  content: string | Blob;
  filename: string;
  mimetype: string;
};
type Props = {
  download: (arg0: DownloadProps) => void;
  parseCSV: (arg0: any, arg1: string) => Promise<any>;
  parseJSON: () => Promise<any>;
  openFile: (arg0: string) => Promise<any>;
};

export const useSaveFile = () => {
  const user = useAccount();
  return useCallback(
    async (file) => {
      if (!user?.login) return;
      const uploadDate = dayjs().format('YYYY-MM-DD/HH:mm:ss');
      const storageRef = firebaseStorage().ref().child(`0_upload_items/${user.login}/${uploadDate}-${file.name}`);
      await storageRef.put(file);
    },
    [user?.login],
  );
};

export const useFileDownloadUpload = (): Props => {
  const download = (props: DownloadProps) => {
    const { content, filename, mimetype } = props;
    if (mimetype === 'text/csv') {
      const strArray = encoding.stringToCode(content);
      const UintArray = new Uint8Array(encoding.convert(strArray, 'SJIS', 'UNICODE'));
      const blob = new Blob([UintArray], { type: mimetype });
      const a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    } else {
      const blob = new Blob([content], { type: mimetype });
      const a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    }
  };

  const openFile = (accept: string) => {
    return new Promise((resolve, error) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = accept;
      input.onchange = async (event: any) => {
        resolve(event.target.files[0]);
      };
      input.click();
    });
  };

  const parseCSV = (csv, encoding = 'Shift-JIS') => {
    return new Promise((resolve, error) => {
      Papa.parse(csv, {
        encoding,
        header: true,
        complete: (result, file) => {
          resolve(result.data);
        },
      });
    });
  };

  const parseJSON = () => {
    return new Promise(async (resolve) => {
      const json: any = await openFile('.json, application/json');
      const reader = new FileReader();
      reader.onload = () => {
        const text: any = reader.result;
        resolve(JSON.parse(text));
      };
      reader.readAsText(json);
    });
  };

  return { download, parseCSV, parseJSON, openFile };
};

import { useEffect, useState } from 'react';
import { firebaseFirestore } from '../../firebase';
import dayjs from 'dayjs';
import { useClientHostName } from '../../store/hooks';

export const useValidSubscriptions = (id) => {
  const [valid, setValid] = useState(false);
  // useEnvironmentを使うべきなのかもしれないが、イマイチ信用ならないので...
  const env = useClientHostName();
  useEffect(() => {
    if (!id) return;
    const subscriptionsRef = firebaseFirestore().collection('accounts').doc(id).collection('subscriptions');
    const unsub = subscriptionsRef.onSnapshot((snapshot) => {
      if (snapshot.empty) {
        setValid(false);
        return;
      }
      setValid(
        !!snapshot.docs
          .map((doc) => doc.data())
          .find((data) => {
            if (!data.start_at) return false;
            const isStarted = dayjs().isAfter(dayjs(data.start_at.toDate()).subtract(1, 'M'));
            const isExpired = dayjs().isAfter(dayjs(data.expire_at?.toDate()));
            return isStarted && !isExpired;
          }),
      );
    });
    if (valid) unsub();
  }, [id, valid, env]);
  return valid;
};

import React from 'react';
import { Modal } from '@mui/material';
import QRCode from 'react-qr-code';
import './styles.scss';

const QRCodeModal = ({ open, onClose, qrCodeValue, tableName }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='qr-code-modal-title'
      aria-describedby='qr-code-modal-description'
    >
      <div className='modalStyle'>
        <h2 className='modalTitle'>{tableName}</h2>
        <QRCode value={qrCodeValue} size={220} />
      </div>
    </Modal>
  );
};

export default QRCodeModal;

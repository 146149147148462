import { MenuItem } from '@mui/material';
import { firebaseAuth } from '../../firebase';
import React from 'react';
import { useEnvironment, useUbiregiHostName } from '../../store/hooks';

export const AccountSwitcher = React.forwardRef((props, ref) => {
  const env = useEnvironment();
  const host = useUbiregiHostName();

  const switchAccount = async () => {
    const path = ((env: string) => {
      if (env === 'development') {
        return '/oauth2/authorize?response_type=code&client_id=mobile-order&redirect_uri=https%3A%2F%2Fus-central1-ubiregi-mobile-order-staging.cloudfunctions.net%2FauthCallback';
      } else {
        return '/oauth2/authorize?response_type=code&client_id=mobile-order&redirect_uri=https%3A%2F%2Fus-central1-ubiregi-mobile-order-jp.cloudfunctions.net%2FauthCallback';
      }
    })(env);
    const to = `https://${host}/logout?to=${encodeURIComponent(path)}`;
    await firebaseAuth().signOut();
    window.location.href = to;
  };

  return <MenuItem onClick={() => switchAccount()}>アカウント切り替え</MenuItem>;
});

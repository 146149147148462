import { useOfferTypes, usePages, useMenuList, useSnackbar, useMenuItems } from '../../store/hooks';
import { useFileDownloadUpload } from './file';
import { usePageMutator, useMenuListMutator, useOfferTypeMutator } from '../../store/hooks/firestore';
import { useSaveOfferTypeToFlickOrder } from '../../store/hooks';

type Props = {
  toJSON: () => string;
  importJSON: () => Promise<any>;
};
export const useJSONBackup = (): Props => {
  const offerTypes = useOfferTypes();
  const pages = usePages();
  const menuLists = useMenuList();
  const items = useMenuItems();
  const { parseJSON } = useFileDownloadUpload();
  const { upsert: upsertPage } = usePageMutator();
  const { upsert: upsertMenuList } = useMenuListMutator();
  const { upsert: upsertOfferType, updateAllOfferTypeSnapshot } = useOfferTypeMutator();
  const { showSnackbar } = useSnackbar();
  const saveToFO = useSaveOfferTypeToFlickOrder();

  const toJSON = () => {
    for (const page of pages) {
      page.itemSKUs = page.itemIds?.map((id) => items.find((i) => i.item.id === id).item.sku);
      delete page.itemIds;
    }
    return JSON.stringify({ pages, menuLists, offerTypes }, null, '  ');
  };

  const importJSON = async () => {
    const { pages, offerTypes, menuLists } = await parseJSON();
    showSnackbar('復元を開始します、ブラウザーを閉じないでください');
    for (const page of pages) {
      const id = page.id;
      delete page.id;
      page.itemIds =
        page.itemSKUs
          ?.map((sku) => items.find((i) => i.item.sku === sku))
          .filter((i) => i)
          .map((i) => i.item.id) || [];
      page.updateDate = new Date();
      await upsertPage({ id, payload: page });
    }
    for (const menuList of menuLists) {
      const id = menuList.id;
      delete menuList.id;
      menuList.updateDate = new Date();
      await upsertMenuList({ id, payload: menuList });
    }
    for (const offerType of offerTypes) {
      const id = offerType.id;
      delete offerType.id;
      offerType.snapshotUpdateRequestedDate = new Date();
      offerType.updateDate = new Date();
      await upsertOfferType({ id, payload: offerType });
    }

    await updateAllOfferTypeSnapshot();
    await saveToFO();
    showSnackbar('復元が完了しました');
  };

  return { toJSON, importJSON };
};

import { EditorDialog, MenuItem, Category, TopCategory } from '../../types';

type Action = {
  type: string;
  item?: MenuItem;
  category?: Category;
  topCategory?: TopCategory;
};

const SET = 'mo-admin/editorDialog/set';

export const setEditorsItem = (item: MenuItem): Action => {
  return { type: SET, item };
};

export const setEditorsCategory = (category: Category): Action => {
  return { type: SET, category };
};

export const setEditorsTopCategory = (topCategory: TopCategory): Action => {
  return { type: SET, topCategory };
};

const Reducer = (state: EditorDialog = {}, action: Action): EditorDialog => {
  if (action.type === SET) {
    return { item: action.item, category: action.category, topCategory: action.topCategory };
  }
  return state;
};

export default Reducer;

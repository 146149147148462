import { Typography, ListItem, ListItemText, List, Divider, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMenuItems } from '../../store/hooks';

const Item = ({ subitem }) => {
  const master = useMenuItems();
  const secondary = `${subitem.allow_none ? '無選択を許可' : '必須選択'} ${subitem.topping ? '/ トッピング ' : ''}`;
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  const items = (subitem.items || []).map((id) => master.find((m) => m.item.id === parseInt(id))).filter((i) => i);
  return (
    <>
      <List>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={subitem.name} secondary={secondary} />
          {!open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <Collapse in={!open} timeout='auto' unmountOnExit>
        <List component='div' style={{ paddingLeft: 10 }}>
          {items.map((i) => (
            <ListItem key={i.item.id}>
              <ListItemText
                primary={i.name || i.item.name}
                secondary={`商品名:${i.item.name} / SKU:${i.item.sku} / カテゴリ:${i.item.category_name}`}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export const Submitems = () => {
  const item = useSelector((state) => state.editorDialog.item);

  if (!item || !item.subitems || item.subitems.length === 0) {
    return null;
  }
  return (
    <>
      <Divider style={{ margin: '20px 0' }} />
      <Typography variant='h6'>サブメニュー</Typography>
      <Typography variant='caption'>設定はユビレジ ハンディから行ってください</Typography>
      {item.subitems.map((s) => (
        <Item subitem={s} key={s.guid} />
      ))}
    </>
  );
};

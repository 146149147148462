import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { setEditorsItem } from '@order/admin/store/modules/editorDialog';
import { FixedSizeList } from 'react-window';
import React from 'react';
import { FormattedPrice } from '../Price';
import { useDispatch } from 'react-redux';

export const ItemList = ({ items }) => {
  const dispatch = useDispatch();
  const edit = (item) => dispatch(setEditorsItem(item));

  const Row = ({ index, style }) => {
    const item = items[index];
    const { sku, name, category_name, price } = item.item;
    const image = item.image;

    return (
      // see https://github.com/mui-org/material-ui/issues/16026
      <ListItem ContainerProps={{ style: style }} ContainerComponent='div' key={sku} divider dense>
        <ListItemAvatar>
          <Avatar variant='square' src={image} style={{ width: 80, height: 60, marginRight: 12 }}>
            {(item.name || name).substr(0, 1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          style={{ paddingRight: 40 }}
          primary={<Typography noWrap>{item.name || name}</Typography>}
          secondary={
            <>
              <Typography style={{ fontSize: '.75rem' }} noWrap>
                {name}
              </Typography>
              <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                SKU: {sku}
              </Typography>
              <Typography variant='caption' style={{ marginRight: 10, fontSize: '.875rem' }}>
                カテゴリ: {category_name}
              </Typography>
              <Typography variant='caption' style={{ fontSize: '.875rem' }}>
                <FormattedPrice price={price} />
              </Typography>
            </>
          }
        />
        <ListItemSecondaryAction>
          <Button onClick={() => edit(item)}>編集</Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  const height = window.innerHeight - 330;

  return (
    <div style={{ marginTop: 20 }}>
      {items.length > 0 ? (
        <FixedSizeList height={height} itemCount={items.length} itemSize={86}>
          {Row}
        </FixedSizeList>
      ) : (
        <Typography align='center' variant='subtitle1'>
          商品がありません
        </Typography>
      )}
    </div>
  );
};

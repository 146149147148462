import { Button, Checkbox, FormControlLabel, Box, Typography, Divider } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { HelpPopover } from './HelpPopover';
import { CustomButton } from '../Button';

const useSubHeader = ({ offerTypes, selectedOfferTypeIds, setSelectedOfferTypeId }) => {
  const itemChecked = useMemo(() => !!selectedOfferTypeIds.length, [selectedOfferTypeIds]);
  const sortDisabled = useMemo(() => offerTypes.length <= 1, [offerTypes]);
  const allSelected = useMemo(
    () => offerTypes.length === selectedOfferTypeIds.length,
    [offerTypes, selectedOfferTypeIds],
  );
  const onChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedOfferTypeId(offerTypes.map(({ id }) => id));
      } else {
        setSelectedOfferTypeId([]);
      }
    },
    [offerTypes, setSelectedOfferTypeId],
  );

  return [itemChecked, sortDisabled, allSelected, onChange];
};

export const SubHeaderDisabledOfferType = ({
  offerTypes,
  selectedOfferTypeIds,
  setSelectedOfferTypeId,
  enableOfferType,
  deleteOfferType,
  updating,
  sort,
  sortSave,
  sortCancel,
}) => {
  const [itemChecked, sortDisabled, allSelected, onChange] = useSubHeader({
    offerTypes,
    selectedOfferTypeIds,
    setSelectedOfferTypeId,
  });

  return (
    <>
      <Typography variant='h6' style={{ marginTop: 50 }}>
        非公開のメニューブック
        <HelpPopover name='disabledOfferType' />
      </Typography>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: 50, marginTop: 10, marginBottom: 10 }}
      >
        <Box>
          {!sort && (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={allSelected}
                  indeterminate={!allSelected && itemChecked}
                  onChange={onChange}
                  disabled={updating}
                  data-testid='all-check'
                  data-test-id='all-check'
                  style={{ width: 50, marginRight: 10 }}
                />
              }
              label={<Typography>すべて選択</Typography>}
            />
          )}
        </Box>
        <Box>
          {!sort && (
            <>
              <CustomButton
                disabled={updating || !itemChecked}
                variant='outlined'
                color='error'
                onClick={deleteOfferType}
                style={{ marginRight: 10 }}
              >
                メニューブックを削除
              </CustomButton>
              <Button
                disabled={updating || !itemChecked}
                variant='contained'
                color='secondary'
                onClick={enableOfferType}
              >
                メニューブックを公開
              </Button>
            </>
          )}
          {sort && (
            <Button
              variant='contained'
              style={{ marginLeft: 10 }}
              color='secondary'
              onClick={() => sortSave({ type: 'disabledOfferType' })}
            >
              並び替え完了
            </Button>
          )}
          <Button
            disabled={sortDisabled}
            variant='outlined'
            style={{ marginLeft: 10 }}
            color='secondary'
            onClick={sortCancel}
          >
            {sort ? '並び替えキャンセル' : '並び替え'}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export const SubHeaderEnabledOfferType = ({
  offerTypes,
  selectedOfferTypeIds,
  disableOfferType,
  updating,
  sort,
  sortCancel,
  sortSave,
}) => {
  const [itemChecked, sortDisabled] = useSubHeader({ offerTypes, selectedOfferTypeIds });

  return (
    <>
      <Box
        flexDirection='row'
        display='flex'
        justifyContent='space-between'
        alignItems='right'
        style={{ marginTop: 56, marginBottom: 20 }}
      >
        <Box>
          <Typography variant='h6'>
            公開中のメニューブック
            <HelpPopover name='enabledOfferType' />
          </Typography>
        </Box>
        <Box>
          {!sort && (
            <Button
              disabled={updating || !itemChecked}
              variant='contained'
              color='secondary'
              onClick={disableOfferType}
            >
              メニューブックを非公開にする
            </Button>
          )}
          {sort && (
            <Button
              variant='contained'
              style={{ marginLeft: 10 }}
              color='secondary'
              onClick={() => sortSave({ type: 'enabledOfferType' })}
            >
              並び替え完了
            </Button>
          )}
          <Button
            disabled={sortDisabled}
            variant='outlined'
            style={{ marginLeft: 10 }}
            color='secondary'
            onClick={sortCancel}
          >
            {sort ? '並び替えキャンセル' : '並び替え'}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

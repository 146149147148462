// /c 配下で表示する404ページ
import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const NotFound = () => {
  useEffect(() => {
    document.title = 'ページがみつかりません - ユビレジQRオーダー&決済管理画面';
  }, []);
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100,
      }}
    >
      <Typography variant='h4' style={{ color: '#757575' }}>
        ページがみつかりません
      </Typography>
      <Button
        size='large'
        color='secondary'
        variant='contained'
        component={RouterLink}
        to='/'
        style={{ marginTop: 40 }}
      >
        トップページに戻る
      </Button>
    </Box>
  );
};

import React, { useState } from 'react';
import HandleIcon from '@mui/icons-material/Menu';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Checkbox,
  Avatar,
  Divider,
  Tooltip,
  Icon,
  Chip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MenuDetailDialog } from './MenuDetailDialog';
import { useStyles } from './useStyles';

export const SortableMenuRow = ({ checked, onChange, menu, isNotLast, handle, sort }) => {
  const [open, toggle] = useState(false);
  const { root } = useStyles();
  const history = useHistory();

  return (
    <>
      {open && <MenuDetailDialog menu={menu} open={open} onClose={() => toggle(false)} />}
      <ListItem button onClick={() => toggle(true)}>
        {!sort && (
          <Checkbox
            color='primary'
            checked={checked}
            style={{ width: 50, height: 50, marginRight: 10 }}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <ListItemAvatar>
          <Avatar variant='square' className={root} src={menu.image} alt={menu.name}>
            {menu.name.substr(0, 5)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          style={{ marginLeft: 5 }}
          primary={menu.name}
          secondaryTypographyProps={{ component: 'div' }}
          secondary={menu.offerTypes?.map((o) => (
            <Chip
              key={o.name}
              size='small'
              style={{ marginRight: 5 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/c/offerType/${o.id}`);
              }}
              label={
                <>
                  <Icon
                    style={{
                      fontSize: '1.2em',
                      display: 'inline-flex',
                      verticalAlign: 'middle',
                      marginTop: -3,
                      marginRight: 3,
                      color: '#656565',
                    }}
                  >
                    menu_book
                  </Icon>
                  {o.name}
                </>
              }
            />
          ))}
        />
        {handle && (
          <ListItemIcon
            style={{ cursor: 'grab' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {sort && (
              <Tooltip title='ドラッグして並び替え'>
                <HandleIcon />
              </Tooltip>
            )}
          </ListItemIcon>
        )}
      </ListItem>
      {isNotLast && <Divider />}
    </>
  );
};

import { Icon, IconButton, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';

export const HelpPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} color='primary' onClick={handleClick} size='large'>
        <Icon>help</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ maxWidth: 400, padding: 16 }}>
          <Typography paragraph>
            <strong>ページ</strong>とは<strong>商品</strong>が並ぶ場所のことです。<strong>ページ</strong>
            を作成すると、その中に<strong>商品</strong>を複数掲載できます。
          </Typography>
          <Typography paragraph>
            焼き鳥、赤ワインなど、<strong>ページ</strong>
            に短くわかりやすい名前をつけると、お客様が商品を探しやすくなります。
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <img src='/tutorial-page.svg' alt='' style={{ height: 120 }} />
          </div>
        </div>
      </Popover>
    </>
  );
};

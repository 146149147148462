import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '30%',
    padding: 20,
    margin: 20,
    [theme.breakpoints.down('lg')]: {
      margin: '20px 0 0',
      minWidth: '100%',
    },
  },
  paperWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px',
      flexDirection: 'column',
    },
  },
  button: {
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      margin: '0 auto',
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

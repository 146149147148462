import dayjs from 'dayjs';
import { firebaseFirestore } from '../../firebase';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Subscription = {
  start_at?: string;
  expire_at?: string;
};

const subscriptionSlice = createSlice({
  name: 'Subscription',
  initialState: {},
  reducers: {
    loadSubscriptions: (_state, action: PayloadAction<Subscription>) => {
      return { ...action.payload };
    },
  },
});

export const { loadSubscriptions } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

export const subscribeAccountSubscription = (id, callback: (subscription?: Subscription) => void) => {
  firebaseFirestore()
    .collection('accounts')
    .doc(id)
    .collection('subscriptions')
    .onSnapshot((snapshot) => {
      const validSubscription = snapshot.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data())
        .filter((data) => !!data.start_at)
        .find((data) => {
          const isStarted = dayjs().isAfter(dayjs(data.start_at.toDate()).subtract(1, 'M'));
          const isExpired = dayjs().isAfter(dayjs(data.expire_at?.toDate()));
          return isStarted && !isExpired;
        });
      if (!validSubscription?.start_at) {
        return;
      }
      callback({
        start_at: (validSubscription.start_at.toDate() as Date).toDateString(),
        expire_at: (validSubscription.expire_at?.toDate() as Date)?.toDateString(),
      });
    });
};

import { Button, CircularProgress } from '@mui/material';
import IconSync from '@mui/icons-material/Sync';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useRootRef } from '../../store/hooks';
import { useSyncStatusReporter } from '../../store/hooks/useSyncStatusReporter';

export const useSync = () => {
  const rootRef = useRootRef();
  const syncing = useSyncStatusReporter();
  const sync = useCallback(async () => {
    await rootRef.update({ sync: 'request', last_sync_at: dayjs().unix() });
  }, [rootRef]);

  return [sync, syncing];
};

export const SyncButton = (props) => {
  const [syncMenusAndCats, sync] = useSync();

  const handleClick = async (e) => {
    e.preventDefault();
    syncMenusAndCats();
  };

  return (
    <Button variant='contained' color='secondary' disabled={sync} onClick={(ev) => handleClick(ev)}>
      {sync ? <CircularProgress color='inherit' size={20} style={{ marginRight: 6 }} /> : <IconSync />}
      ユビレジの商品設定と同期
    </Button>
  );
};

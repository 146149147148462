import { Icon, IconButton, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';

export const HelpPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} color='primary' onClick={handleClick} size='large'>
        <Icon>help</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ maxWidth: 600, padding: 20 }}>
          <Typography paragraph>ここで設定した画像は、メニューの一覧で表示されます。</Typography>
          <div style={{ textAlign: 'center', padding: 20 }}>
            <img src='/menu_img_sample.svg' alt='' style={{ height: 320 }} />
          </div>
        </div>
      </Popover>
    </>
  );
};

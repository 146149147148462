import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { openSnackbar, setMessageToSnackbar } from '@order/admin/store/modules/snackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAccountInfo, useRootRef, useUbiPayIntegrated } from '../../store/hooks';

export const SelfPayment = ({ updating }) => {
  const rootRef = useRootRef();
  const accountInfo = useAccountInfo();
  const isUbiPayIntegrated = useUbiPayIntegrated();
  const dispatch = useDispatch();
  const [selfPayment, setSelfPayment] = useState(false);

  useEffect(() => {
    if (!accountInfo) return;
    setSelfPayment(!!accountInfo.self_payment);
  }, [accountInfo]);

  const toggleSelfPayment = async () => {
    await rootRef.update({ self_payment: selfPayment });
    dispatch(setMessageToSnackbar('保存しました'));
    dispatch(openSnackbar());
  };

  return (
    <Box flexDirection='row' display='flex' justifyContent='space-between' style={{ marginTop: 5, marginLeft: 1 }}>
      <Box>
        {isUbiPayIntegrated ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <Box style={{ marginBottom: 20 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={selfPayment} onChange={() => setSelfPayment(!selfPayment)} color='primary' />
                }
                label='決済機能を有効にする'
              />
              <Box marginLeft={4}>
                <Typography variant='body1'>
                  QRオーダーを操作しているお客様の端末にてそのまま決済まで行えるようになります。
                </Typography>
              </Box>
            </Box>
            <Box marginTop={5}>
              <Button
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
                disabled={updating}
                onClick={toggleSelfPayment}
              >
                保存
              </Button>
            </Box>
          </form>
        ) : (
          <Box>
            <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
              決済機能を有効にすると、QRオーダーを操作しているお客様の端末にてそのまま決済まで行えるようになります。
            </Typography>
            <Typography color='primary' variant='subtitle1' style={{ fontWeight: 'bold', marginBottom: 20 }}>
              この機能は「ユビレジ QRオーダー&決済」の「決済機能」をご契約いただきますと、使用できます。
            </Typography>
            <a
              href='https://ubiregi.jp/qrorder'
              style={{ textDecoration: 'none' }}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button variant='outlined' color='secondary'>
                ご利用イメージの確認・お問い合わせはこちら
                <ChevronRightIcon />
              </Button>
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
};

import crypto from 'crypto';
import { Preview } from '../../types';

type Action = {
  type: string;
  id?: any;
};

const OPEN = 'mo-admin/preview/open';
const CLOSE = 'mo-admin/preview/close';

export const openPreview = (offerTypeId: string): Action => ({ type: OPEN, id: offerTypeId });
export const closePreview = (): Action => ({ type: CLOSE });

const initialState = { open: false, id: null };

const Reducer = (state: Preview = initialState, action: Action): Preview => {
  if (action.type === OPEN) {
    return { open: true, id: action.id };
  }
  if (action.type === CLOSE) {
    return { open: false };
  }
  return state;
};

export default Reducer;

export const createPreviewUrl = (id: any, offerTypeId: string): string => {
  const menuQueryString = offerTypeId ? `&menu=${offerTypeId}` : '';
  const mo_domain =
    window.location.hostname === 'qrorder-admin.ubiregi.com'
      ? 'qr.ubiregi.com'
      : 'staging-mobile-order.ubiregi.com';
  let hex = String(id);
  for (let step = 0; step < 9989; step++) {
    hex = crypto.createHash('md5').update(hex, 'utf8').digest('hex');
  }
  return `https://${mo_domain}/?${menuQueryString}&id=${id}&previewkey=${hex}&mode=preview`;
};

import { Button, Input, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOfferTypeMutator } from '../../store/hooks/firestore';
import { useStyles } from './useStyles';

export const OfferTypeNameForm = ({ classes, savedAction, offerTypes, lastOrder }) => {
  const { create } = useOfferTypeMutator();
  const [name, setName] = useState('');
  const { saveButton } = useStyles();
  const history = useHistory();

  const handleNameChanges = useCallback(
    (name) => {
      setName(name);
    },
    [setName],
  );

  const saveName = async () => {
    const names = offerTypes.map((o) => o.name);
    if (names.find((n) => name === n)) {
      window.alert('入力したメニューブック名は既に存在します。異なる名前を入力してください');
      return;
    }

    const result = await create({ name, order: lastOrder + 1, updateDate: new Date(), is_small_menu_image: true });
    savedAction(name);
    history.push(`/c/offertype/${result.id}`);
  };

  return (
    <div>
      <Typography variant='h6'>新規作成</Typography>
      <Input
        classes={classes}
        value={name}
        fullWidth
        placeholder={'メニューブック名'}
        onChange={(e) => handleNameChanges(e.target.value)}
      />
      <Button
        data-testid='save-button'
        className={saveButton}
        onClick={saveName}
        variant='contained'
        color='secondary'
        size='large'
        disabled={!name}
      >
        <Typography variant='body1'>メニューブックを作成</Typography>
      </Button>
    </div>
  );
};

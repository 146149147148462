import { Snackbar } from '../../types';

type Action = {
  type: string;
  message?: string;
};

const OPEN = 'mo-admin/snackbar/open';
const CLOSE = 'mo-admin/snackbar/close';
const MESSAGE = 'mo-admin/snackbar/message';

export const openSnackbar = (): Action => ({ type: OPEN });
export const closeSnackbar = (): Action => ({ type: CLOSE });
export const setMessageToSnackbar = (message: string): Action => ({ type: MESSAGE, message });

const initialState = { open: false, message: '' };

const Reducer = (state: Snackbar = initialState, action: Action): Snackbar => {
  if (action.type === OPEN) {
    return Object.assign({}, state, { open: true });
  }
  if (action.type === CLOSE) {
    return Object.assign({}, state, { open: false });
  }
  if (action.type === MESSAGE) {
    return Object.assign({}, state, { message: action.message });
  }
  return state;
};

export default Reducer;

// デフォルトカラー
export const colors = {
  primary: '#6D4C41',
  primaryDark: '#40241A',
  secondary: '#FF5252',
};

const toHex = (i) => {
  const s = i.toString(16);
  return s.length === 2 ? s : `0${s}`;
};

export const darker = (color) => {
  if (color.match(/^#/)) color = color.slice(1); // # があったら除去

  const rgb = [];
  for (let i = 0; i < 3; i++) {
    rgb.push(parseInt(color.slice(i * 2, (i + 1) * 2), 16));
  }
  let red = rgb[0] - 45;
  let green = rgb[1] - 40;
  let blue = rgb[2] - 39;
  if (red < 0) red = 0;
  if (green < 0) green = 0;
  if (blue < 0) blue = 0;

  return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
};

export const getFontColor = (colorcode) => {
  if (colorcode.match(/^#/)) colorcode = colorcode.slice(1); // # があったら除去

  const rgb = [];
  for (let i = 0; i < 3; i++) {
    rgb.push(parseInt(colorcode.slice(i * 2, (i + 1) * 2), 16));
  }

  // 色による重みをつけながら色を取得
  // 重みをつけてるのは、人間の目が色を受け取る感度は色によるから
  const mod = { r: 0.5, g: 0.8, b: 0.4 }; // @ssig33 の目ではこんなもんがよさそうだった
  const red = rgb[0] * mod.r;
  const green = rgb[1] * mod.g;
  const blue = rgb[2] * mod.b;

  const bright = Math.max(red, green, blue) / 255;
  return bright > 0.5 ? 'black' : 'white';
};

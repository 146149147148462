import React, { useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import { DownloadCard } from './DownloadCard';
import { AlertInUnavailability } from '../AlertInUnavailability';

export const DownloadPdf = () => {
  useEffect(() => {
    document.title = 'マニュアル・POP - ユビレジQRオーダー&決済管理画面';
  }, []);
  return (
    <>
      <AlertInUnavailability />
      <Paper style={{ padding: 20, margin: 20, minHeight: 300 }}>
        <Typography variant='h4' style={{ marginBottom: 40 }}>
          <BookIcon />
          マニュアル・POP
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          QRオーダー&決済のマニュアルや店内掲示用のPOPをダウンロードしてご利用いただけます。
        </Typography>
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          <DownloadCard
            title='マニュアル(ver.10)'
            detail='詳しい設定・利用方法はこちらをご覧ください。(PDF 全36ページ/4.3MB)'
            thumbnail='/resources/QR_manual_title.png'
            mediaPath='/resources/QR_manual.pdf'
          />
          <DownloadCard
            title='逆引き設定ガイド(ver.4)'
            detail='シーン別の設定方法などやりたいことからの設定方法ガイドです。(PDF 全19ページ/3.8MB)'
            thumbnail='/resources/QROP_R_manual_title.png'
            mediaPath='/resources/QROP_R_manual.pdf'
          />
          <DownloadCard
            title='店内用POP（決済あり）'
            detail='QRオーダー&決済利用方法の客席用POPです。(PDF 全1ページ/481KB)'
            thumbnail='/resources/pop_title.png'
            mediaPath='/resources/pop.pdf'
          />
          <DownloadCard
            title='店内用POP（決済なし）'
            detail='QRオーダー利用方法の客席用POPです。(PDF 全1ページ/623KB)'
            thumbnail='/resources/pop_order_title.png'
            mediaPath='/resources/pop_order.pdf'
          />
        </Box>
      </Paper>
    </>
  );
};

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const authEmulator = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL_BASE;
const firestoreEmulator = process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_URL_BASE;
const storageEmulator = process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_URL_BASE;
const emulatorHostname = (emulator) => ( new URL(emulator).hostname);
const emulatorPort = (emulator) => ( Number(new URL(emulator).port));

export const firebaseAuth = () => {
  const auth = firebase.auth();
  if (authEmulator) {
    auth.useEmulator(authEmulator);
  }
  return auth;
}

let firestoreEmulatorEnabled = false;

export const firebaseFirestore = () => {
  const firestore = firebase.firestore();
  if (firestoreEmulator && !firestoreEmulatorEnabled) {
    firestore.useEmulator(emulatorHostname(firestoreEmulator), emulatorPort(firestoreEmulator));
    firestoreEmulatorEnabled = true;
  }
  return firestore;
}

export const firebaseStorage = () => {
  const storage = firebase.storage();
  if (storageEmulator) {
    storage.useEmulator(emulatorHostname(storageEmulator), emulatorPort(storageEmulator));
  }
  return storage;
}

export const isFirebaseStorageEmulator = () => {
  return !!storageEmulator;
}

export const firebaseStorageUrlBase = () => {
  return storageEmulator || 'https://storage.googleapis.com';
}
